import { Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import TextInput from '../../shared/components/Inputs/TextInput'
import { Button } from '../../shared/components/Button'
import LoadingIndicator from '../../shared/components/Spinner'
import { Flex } from '../../shared/components/Flex'
import { verifyEmailMutation } from '../../api/components/Auth'
import { ApolloClientContext } from '../../api'
import { PageTitle } from './shared/PageTitle'
import { NormalText } from './shared/NormalText'
import EmailIcon from './assets/verify-icon.svg'
import { VERIFIED_MESSAGE, MessageTypes } from './Login'
import AuthLayout from './shared/AuthLayout'

const defaultProps = {}

const initialValues = {
  code: '',
  editUsername: false,
  email: '',
}

export type Values = typeof initialValues & {
  formError?: { [key: string]: any }
}

const validationSchema = () => {
  const shape = {
    code: Yup.string()
      .min(6, 'Code is not valid!')
      .max(6, 'Code is not valid!')
      .required('Code is required!'),
    editUsername: Yup.boolean(),
    username: Yup.mixed().when('editUsername', {
      is: true,
      then: Yup.string().required('Email is required!'),
    }),
  }
  return Yup.object().shape(shape)
}

const Icon = styled('img')`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

type Props = typeof defaultProps &
  RouteComponentProps<{}, {}, { email: string; type: MessageTypes; message?: string }> & {}

const VerifyCode: React.FC<Props> = ({ history, location }) => {
  if (!location.state) {
    history.push('/auth')
  }
  const { client } = useContext(ApolloClientContext)
  const onSubmit = async (
    values: Values,
    { setSubmitting, setFieldError }: FormikHelpers<Values>,
  ) => {
    try {
      await verifyEmailMutation(client, {
        args: {
          code: values.code.trim(),
          email: values.email.toLowerCase(),
        },
      })
      setSubmitting(false)
      // Notify.primary('Your account got verified!')
      history.push('/auth', {
        email: values.email.toLowerCase(),
        type: 'success',
        message: VERIFIED_MESSAGE,
      })
    } catch (error) {
      setSubmitting(false)
      if (error.code === 'CodeMismatchException') {
        setFieldError('code', 'Code is not valid!')
        return
      }
      setFieldError('code', error.message.slice(15))
      // Notify.danger(error.message || "Can't verify account! Try again later!")
    }
  }
  const initValues = location.state
    ? { ...initialValues, email: location.state.email.toLowerCase() }
    : initialValues
  return (
    <AuthLayout>
      <Flex fullWidth column>
        <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            // setFieldTouched,
            isSubmitting,
          }) => (
            // const handleResendCode = async () => {
            //   if (!values.email && values.editUsername) {
            //     setFieldTouched('email', true)
            //     return
            //   }
            //   const email = values.editUsername ? values.email : location.state.email
            //   try {
            //     await Cognito.resendConfirmationCode(email)
            //     Notify.primary('A code was sent!')
            //   } catch (error) {
            //     if (error.code === 'UserNotFoundException') {
            //       Notify.danger('User not found!')
            //       return
            //     }
            //     Notify.danger(error.message || "Can't resend code! Try again later!")
            //   }
            // }
            <form
              style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
              onSubmit={handleSubmit}
            >
              <PageTitle>Verify Account</PageTitle>
              <NormalText center>
                Thanks for signing up for Curvo Lookup! Please verify your account
              </NormalText>
              <Icon src={EmailIcon} style={{ alignSelf: 'center' }} alt="Verify Account" />
              <NormalText center marginBottom="1rem">
                A verification code has been sent to your email:{' '}
                {location.state.email.toLowerCase()}
              </NormalText>
              {values.editUsername && (
                <TextInput
                  inputProps={{
                    value: values.email.toLowerCase(),
                    placeholder: 'Email',
                    onChange: handleChange,
                    name: 'email',
                  }}
                  style={{ marginBottom: '2rem' }}
                  error={touched.email ? errors.email : undefined}
                />
              )}
              <TextInput
                inputProps={{
                  name: 'code',
                  value: values.code.trim(),
                  placeholder: 'Verify Code',
                  onChange: handleChange,
                }}
                error={touched.code ? errors.code : undefined}
              />
              <Button btnType="primary" fullWidth type="submit" marginTop="2rem">
                {isSubmitting ? <LoadingIndicator /> : 'Verify Account'}
              </Button>
              {/* <NormalText center marginTop="1rem">
                  Haven't received any code?{' '}
                  <Button btnType="link" onClick={handleResendCode}>
                    Resend code
                  </Button>
                </NormalText> */}
              <NormalText center marginTop="1rem">
                Not your account?{' '}
                <Button
                  btnType="link"
                  onClick={() => setFieldValue('editUsername', !values.editUsername)}
                >
                  Change Username
                </Button>
              </NormalText>
            </form>
          )}
        </Formik>
      </Flex>
    </AuthLayout>
  )
}

VerifyCode.defaultProps = defaultProps

export default VerifyCode
