import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './Login'
import SignUp from './SignUp'
import VerifyCode from './VerifyCode'
import ForgotPassword from './ForgotPassword'
// import VerifyCode from './VerifyCode'

type Props = {}

const Auth: React.FC<Props> = () => (
  <Switch>
    <Route path="/auth/forgot-password" component={ForgotPassword} />
    <Route path="/auth/signup" component={SignUp} />
    <Route path="/auth/verify-account" component={VerifyCode} />
    <Route path="/auth" exact component={Login} />
    <Redirect to="/auth" />
  </Switch>
)

export default Auth
