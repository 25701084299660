import React from 'react'
import { FormikProps } from 'formik'
import styled, { css } from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import { Button } from '../../shared/components/Button'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { Values, MessageTypes, VERIFIED_MESSAGE } from './Login'
import { PageTitle } from './shared/PageTitle'

const defaultProps = {}
type Props = typeof defaultProps & FormikProps<Values>

export const InfoText = styled(Flex)<{ type?: MessageTypes }>`
  height: 24px;
  width: 100%;
  justify-content: center;
  ${props => {
    switch (props.type) {
      case 'success':
        return css`
          color: ${Colors.success};
        `
      case 'info':
        return css`
          color: ${Colors.info};
        `
      case 'warning':
        return css`
          color: ${Colors.warning};
        `
      case 'error':
        return css`
          color: ${Colors.danger};
        `
      default:
        return css`
          color: ${Colors.danger};
        `
    }
  }};
`
const StyledTextInput = styled(TextInput)`
  margin-bottom: 32px;
`
const Form = styled('form')`
  width: 100%;
`

const LoginForm: React.FC<Props> = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
}) => (
  <Form onSubmit={handleSubmit}>
    <PageTitle>Login</PageTitle>
    <InfoText type={values.messageType} marginBottom="1rem">
      {values.formMessage && values.formMessage}
    </InfoText>
    <StyledTextInput
      inputProps={{
        placeholder: 'Email',
        name: 'email',
        value: values.email.toLowerCase(),
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      error={(touched.email && errors.email) || undefined}
    />
    <StyledTextInput
      inputProps={{
        autoFocus: values.formMessage === VERIFIED_MESSAGE,
        type: 'password',
        placeholder: 'Enter your Password',
        name: 'password',
        value: values.password,
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      error={(touched.password && errors.password) || undefined}
    />
    {values.newPasswordRequired && (
      <>
        <StyledTextInput
          inputProps={{
            type: 'password',
            placeholder: 'Enter New Password',
            name: 'newPassword',
            value: values.newPassword,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={(touched.newPassword && errors.newPassword) || undefined}
        />
        <StyledTextInput
          inputProps={{
            type: 'password',
            placeholder: 'Confirm Your New Password',
            name: 'confirmNewPassword',
            value: values.confirmNewPassword,
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          error={(touched.confirmNewPassword && errors.confirmNewPassword) || undefined}
        />
      </>
    )}
    <Button fullWidth type="submit" marginBottom="1rem">
      {isSubmitting ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : 'Login'}
    </Button>
  </Form>
)

export default LoginForm
