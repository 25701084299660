import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useContext, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ApolloClientContext, File, FileCollection, MeData } from '../../api'
import { genDownloadUrlMutation, removeRecentFileMutation } from '../../api/components/File'
import { requestBulkAccessMutation } from '../../api/components/Search'
import { ReactComponent as DeleteIcon } from '../../assets/img/delete.svg'
import { ReactComponent as DownloadArrow } from '../../assets/img/down-arrow.svg'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import { Modal } from '../../shared/components/Modal'
import Notify from '../../shared/components/Notify'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { ItemDesc, ItemTitle, RecentItem } from './shared/RecentItem'
import { RecentTitle } from './shared/RecentTitle'

const Wrapper = styled(Flex)`
  flex: 1;
  max-width: 100%;
  flex-direction: column;
  position: relative;
`

const StyledDownloadArrow = styled(DownloadArrow)`
  position: absolute;
  right: 5px;
  top: 28px;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
`
const StyledDeleteIcon = styled(DeleteIcon)`
  width: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  &:hover {
    color: ${Colors.danger};
  }
`
const highlight = keyframes`
  from {
    background-color: ${Colors.info};
  }
  to {
    background-color: none;
  }
`
const StyledRecentItem = styled(RecentItem)<{ isNew?: boolean }>`
  width: 220px;
  &:hover {
    ${StyledDownloadArrow} {
      opacity: 1;
    }
  }
  ${props =>
    props.isNew &&
    css`
      animation: ${highlight} 1s ease-in-out 10;
    `};
`
const ModalContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 240px;
  padding: 24px;
  border-radius: 2px;
  background-color: white;

  & ${Button} {
    margin: 18px 12px 0px 12px;
    width: 80px;
  }
`

const getNumLines = (numLines: number): string => {
  if (numLines === 1) {
    return `${numLines} line`
  }
  return `${numLines} lines`
}

const getMetaData = (
  numLines: number | null | undefined,
  numMatchedLines: number | null | undefined,
): string => {
  if (!numLines || !numMatchedLines) {
    return 'No data'
  }
  const matchedLinesStr = `${((numMatchedLines / numLines) * 100).toFixed(0)}% matched`
  return `${getNumLines(numLines)} • ${matchedLinesStr} `
}

type Props = {}

const RecentFiles: React.FC<Props> = () => {
  return (
    <MeData>
      {({ data, loading }) => {
        if (loading) {
          return <LoadingIndicator />
        }
        if (!data) {
          return null
        }
        const { files, bulkAccess } = data.me

        return <RecentFilesComponent files={files} bulkAccess={bulkAccess} />
      }}
    </MeData>
  )
}
const RecentFilesComponent: React.FC<{ files: FileCollection; bulkAccess: boolean }> = ({
  files,
  bulkAccess,
}) => {
  const { client } = useContext(ApolloClientContext)
  const [{ oldFiles, newFiles }, setFiles] = useState<{
    oldFiles: Maybe<File>[]
    newFiles: Maybe<File>[]
  }>({
    oldFiles: [],
    newFiles: [],
  })
  useEffect(() => {
    if (files && files.data && files.data.length) {
      const data = files.data.slice(0, 10)
      setFiles(current => {
        const newOlds =
          current.oldFiles.length > 0
            ? data.filter(
                f =>
                  f &&
                  (current.newFiles.find(f2 => f2 && f2.id === f.id) ||
                    current.oldFiles.find(f2 => f2 && f2.id === f.id)),
              )
            : data

        return {
          oldFiles: newOlds,
          newFiles: data.filter(f => f && !newOlds.find(f2 => f2 && f2.id === f.id)),
        }
      })
    }
  }, [files])
  return (
    <Wrapper>
      {files.data.length > 0 && <RecentTitle>Recent Files</RecentTitle>}
      <div style={{ width: '100%' }}>
        {newFiles.map(file => (
          <RecentFile
            client={client}
            file={file}
            isNew={true}
            bulkAccess={bulkAccess}
            key={(file && file.id) || undefined}
          />
        ))}
        {oldFiles.map(file => (
          <RecentFile
            client={client}
            file={file}
            bulkAccess={bulkAccess}
            key={(file && file.id) || undefined}
          />
        ))}
      </div>
    </Wrapper>
  )
}

const RecentFile: React.FC<{
  client: DefaultClient<NormalizedCacheObject>
  file: Maybe<File>
  bulkAccess: boolean
  isNew?: boolean
}> = ({ file, client, isNew, bulkAccess }) => {
  const [isRemoved, setIsRemoved] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  if (!file || isRemoved) {
    return null
  }
  return (
    <StyledRecentItem
      isNew={isNew}
      style={{ paddingRight: 36, position: 'relative' }}
      key={file.id}
      onClick={() => {
        if (!bulkAccess) {
          requestBulkAccessMutation(client, { fileId: file.id })
          Notify.info(
            'File upload processing is a premium feature. Our team will contact you to upgrade your account and access your processed file',
          )
          return
        }
        if (file.id) {
          genDownloadUrlMutation(client, { fileId: file.id })
            .then(res => {
              if (res.data) {
                window.open(res.data.generateDownloadUrl.downloadUrl)
              }
            })
            .catch(err => {
              if (
                err.graphQLErrors &&
                err.graphQLErrors.length > 0 &&
                err.graphQLErrors[0].extensions.code !== 'INTERNAL_SERVER_ERROR'
              ) {
                Notify.danger(err.graphQLErrors[0].message)
                return
              }
              Notify.danger(
                'Something went wrong. Please try again or contact our support to help you fix it.',
              )
            })
        }
      }}
    >
      <ItemTitle>{file.name}</ItemTitle>
      <ItemDesc>{getMetaData(file.numLines, file.numMatchedLines)}</ItemDesc>

      <StyledDeleteIcon
        onClick={e => {
          e.stopPropagation()
          setOpenRemoveModal(true)
        }}
      />
      <StyledDownloadArrow />
      <Modal isOpen={openRemoveModal} blackOverlay onRequestClose={() => setOpenRemoveModal(false)}>
        <ModalContainer>
          Delete the file permanently?
          <Flex>
            <Button
              onClick={async () => {
                await removeRecentFileMutation(client, { fileId: file.id })
                Notify.success('File has been successfully removed!')
                setIsRemoved(true)
              }}
            >
              Yes
            </Button>
            <Button onClick={() => setOpenRemoveModal(false)}>Cancel</Button>
          </Flex>
        </ModalContainer>
      </Modal>
    </StyledRecentItem>
  )
}

export default RecentFiles
