import React from 'react'
import { Formik } from 'formik'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { SearchTypeEnum } from '../../api'
import AdvanceSearchForm from './AdvanceSearchForm'

const initialValues = {
  supplier: null,
  uom: null,
  partial: null,
  segment: null,
  subSegment: null,
  gic: null,
  gicType1: null,
  gicType2: null,
  productLine: null,
  productCategory: null,
  clinicalCategory: null,
  description: null,
  gmdn: null,
  gudid: null,
}

export type Values = typeof initialValues

type Props = {} & RouteComponentProps

const AdvanceSearch: React.FC<Props> = ({ history }) => {
  const handleSubmit = (values: Values) => {
    history.push('/search', { ...values, type: SearchTypeEnum.Advanced })
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={props => <AdvanceSearchForm {...props} />}
    />
  )
}

export default withRouter(AdvanceSearch)
