import { Formik, FormikHelpers } from 'formik'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { ApolloClientContext, MeData, updateMeMutation, CognitoContext } from '../../api'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import { Modal } from '../../shared/components/Modal'
import PageLayout from '../../shared/components/PageLayout'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { convertDateTime } from '../../shared/ultis'
import Notify from '../../shared/components/Notify'
import { initials } from '../../shared/components/Menu/Profile'
import { ChangePasswordForm } from './ChangePasswordForm'
import { InfoField } from './InfoField'

const Container = styled(Flex)`
  flex-direction: column;
  margin: 32px auto 0 auto;
  width: 856px;
`

const Avatar = styled(Flex)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #7d8eb3;
  margin-right: 8px;
  color: #ffffff;
  font-size: 1.5em;
`

const Title = styled(Flex)`
  margin-top: 32px;
  margin-bottom: 18px;
  margin-left: 24px;

  font-weight: bold;
  font-size: 1.5em;
`

const InfoContainer = styled(Flex)`
  width: 100%;
  padding: 24px;
  border: 1px #d8e1e8 solid;
`

const InfoTitle = styled(Flex)`
  color: ${Colors.secondary};
`
const InfoValueText = styled(Flex)`
  font-size: 1.2em;
`

const ModalContainer = styled(Flex)`
  flex-direction: column;

  width: 544px;
  padding: 24px;
  border-radius: 2px;
  background-color: white;
`

const initialChangePasswordValue = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}
export type ChangePasswordValues = typeof initialChangePasswordValue

type UserProfileProps = {} & React.HTMLProps<HTMLButtonElement> &
  React.HTMLAttributes<HTMLButtonElement>

const UserProfile: React.FC<UserProfileProps> = () => {
  const { client } = useContext(ApolloClientContext)
  const [openModal, setOpenModal] = useState(false)

  const { changePassword } = useContext(CognitoContext)

  const handleSubmitFirstName = async (value?: string | number) => {
    try {
      await updateMeMutation(client, {
        model: {
          firstName: `${value}`,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmitLastName = async (value?: string | number) => {
    try {
      await updateMeMutation(client, {
        model: {
          lastName: `${value}`,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const changePasswordValidateSchema = () => {
    const shape = {
      currentPassword: Yup.string().required('Password is required!'),
      newPassword: Yup.string()
        .min(6, 'Minimum length of 6')
        .max(256, ' Maximum length of 256')
        .required('New Password is required!'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords do not match.')
        .min(6, 'Minimum length of 6')
        .max(256, ' Maximum length of 256')
        .required('Confirm Password is required'),
    }
    return Yup.object().shape(shape)
  }

  const handleChangePassword =
    (email: string) =>
    async (
      values: ChangePasswordValues,
      { setSubmitting }: FormikHelpers<ChangePasswordValues>,
    ) => {
      try {
        await changePassword(email, values.currentPassword, values.newPassword)
        setSubmitting(false)
        handleCloseModal()
        Notify.success('Password is successfully changed!')
      } catch (error) {
        setSubmitting(false)
        Notify.danger(`Error: ${error.message}`)
      }
    }

  return (
    <PageLayout showSideBar={false}>
      <MeData>
        {({ loading, data }) => {
          if (loading) {
            return (
              <Flex margin="32px auto">
                <LoadingIndicator color={Colors.primary} />
              </Flex>
            )
          }
          if (!data) {
            return null
          }

          const { firstName, lastName, email, billing } = data.me
          return (
            <Container>
              <Flex marginLeft="24px" align="center">
                <Avatar align="center" justify="center">
                  {initials(firstName, lastName)}
                </Avatar>

                <Flex marginLeft="24px">{`${firstName} ${lastName}`}</Flex>
              </Flex>

              <Title>Account</Title>
              <InfoContainer column>
                <Flex fullWidth>
                  <InfoField
                    flex={1}
                    title="First Name"
                    value={firstName || ''}
                    editable
                    onSubmit={handleSubmitFirstName}
                  />
                  <InfoField
                    flex={1}
                    title="Last Name"
                    value={lastName || ''}
                    editable
                    onSubmit={handleSubmitLastName}
                  />
                </Flex>
                <Flex fullWidth marginTop={32}>
                  <InfoField flex={1} title="Email" value={email} />
                  <InfoField
                    flex={1}
                    title="Password"
                    value="********"
                    editable
                    editText="Change password"
                    onOpenEdit={handleOpenModal}
                  />
                </Flex>
              </InfoContainer>

              <Title>Credit</Title>
              <InfoContainer column>
                <Flex justify="space-between" fullWidth>
                  <InfoField
                    title="Remain Search"
                    value={billing ? billing.searchLimit - billing.searchCount : 0}
                  />
                  <InfoField
                    title="Expires"
                    value={billing ? convertDateTime(billing.expireTime) : ''}
                  />

                  <Flex align="center">
                    <Flex column>
                      <InfoValueText marginBottom={6}>Want More Search?</InfoValueText>
                      <InfoTitle>Get in touch to find the best plan for you.</InfoTitle>
                    </Flex>
                    <Button marginLeft="34px">
                      <a href="mailto:lookup@curvolabs.com">Contact Us</a>
                    </Button>
                  </Flex>
                </Flex>
              </InfoContainer>

              <Modal isOpen={openModal} blackOverlay onRequestClose={handleCloseModal}>
                <ModalContainer>
                  <Flex marginBottom={20}>Change Password</Flex>

                  <Formik
                    initialValues={initialChangePasswordValue}
                    onSubmit={handleChangePassword(email)}
                    validationSchema={changePasswordValidateSchema}
                    render={props => <ChangePasswordForm {...props} onCancel={handleCloseModal} />}
                  />
                </ModalContainer>
              </Modal>
            </Container>
          )
        }}
      </MeData>
    </PageLayout>
  )
}

export default UserProfile
