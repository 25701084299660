import React from 'react'
import styled from 'styled-components/macro'
import { Flex } from './Flex'

const Container = styled(Flex)<{ isToggle: boolean }>`
  width: 30px;
  background-color: #92abbb;
  border-radius: 12px;
  height: 16px;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${props => props.isToggle && 'background: #1E93F5'};
  transition: all 0.1s linear;
`

const Slider = styled(Flex)<{ isToggle: boolean }>`
  position: absolute;
  left: ${props => (!props.isToggle ? '1px' : 'calc(100% - 15px)')};
  width: 14px;
  height: 14px;
  border-radius: 50%;

  border: 1px solid transparent;
  background-color: #fff;
  transition: all 0.1s linear;
`

type Props = {
  isToggle?: boolean
  onToggle?: () => void
  disabled?: boolean
  children?: React.ReactNode
}

const ToggleButton: React.FC<Props> = ({ isToggle, onToggle, disabled, children }) => (
  <Flex
    flex={1}
    style={{ cursor: 'pointer' }}
    align="center"
    onClick={() => !disabled && onToggle && onToggle()}
  >
    <Container isToggle={!!isToggle}>
      <Slider isToggle={!!isToggle} />
    </Container>
    {children}
  </Flex>
)

ToggleButton.defaultProps = {
  isToggle: false,
}

export default ToggleButton
