import numeral from 'numeral'
import Plotly, { PlotData } from 'plotly.js'
import React, { useEffect, useState } from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Benchmark, Gudid, SearchItem, SearchQueryInput, Transaction } from '../../api'
import aiIcon from '../../assets/img/ai_icon.svg'
import { Flex } from '../../shared/components/Flex'
import Tooltip from '../../shared/components/Tooltip'
import { Colors, FontWeight } from '../../shared/constants'
import { Separator } from '../Home/shared/Separator'
import { ClinicalStudyDetail } from './ClinicalStudyDetail'
import FdaDetails from './FdaDetails'
import { SimilarProductsGrid } from './SimilarProducts'
import SummaryItem, { ItemDetails, SummaryTitle, SummaryWrapperItem } from './SummaryItem'

const Plot = createPlotlyComponent(Plotly)

const isNil = (value?: any): boolean => {
  return value === null || value === undefined
}

const GMDNListItem = styled('li')`
  :hover {
    cursor: pointer;
    color: #f86b4f;
  }
  text-decoration: underline;
  color: black;
  span {
    color: black;
  }
`

type GraphData = {
  x: number[]
  y: number[]
}

type Props = {
  graphData: GraphData
  isHospitalCountGTE: (amount: number) => boolean
  gudid: Gudid | undefined | null
  data: SearchItem
  searchQuery: SearchQueryInput
  benchmark: Benchmark
  transactionsList?: Transaction[]
}

const ExpandedDetails: React.FC<Props> = ({
  graphData,
  isHospitalCountGTE,
  gudid,
  benchmark,
  data,
  searchQuery,
}) => {
  const [tab, setTab] = useState<DetailTabType>('clinical')
  const [clinicalStudyAvailable, setClinicalStudyAvailable] = useState<boolean>()

  useEffect(() => {
    if (clinicalStudyAvailable === false) {
      setTab('similarProducts')
    }
  }, [clinicalStudyAvailable])
  const xMax = Math.max(...graphData.x)
  const xRange = [benchmark.iqo90 ? benchmark.iqo90 - xMax * 0.1 : 0, xMax + xMax * 0.1]

  const yMax = Math.max(...graphData.y)
  const yRange = [0 - yMax * 0.05, yMax + yMax * 0.2]

  const hiddenAreaCenter = [
    xRange[0] + ((benchmark.iqo90 ? benchmark.iqo90 : xRange[1]) - xRange[0]) / 2,
    yRange[0] + (yRange[1] - yRange[0]) / 2,
  ]

  const pricingData: Partial<PlotData> = {
    mode: 'markers',
    type: 'scatter',
    x: graphData.x,
    y: graphData.y,
    hoverinfo: 'text',
    text: graphData.x.map(
      (price, i) =>
        // prettier-ignore
        `$${numeral(price).format('0,0.00')} - ${graphData.y[i]} unit${graphData.y[i] > 1 ? 's' : ''} <br> Volume: $${numeral(price * graphData.y[i]).format('0,0.00')}`,
    ),
    marker: {
      color: Colors.primary,
      opacity: 0.5,
      sizemin: 5,
      size: graphData.y.map(item => {
        const max = Math.max(...graphData.y)
        const ratio = 50 / max
        return item * ratio
      }),
    },
  }

  const hoverData: Partial<PlotData> = {
    mode: 'markers',
    type: 'scatter',
    x: [hiddenAreaCenter[0]],
    y: [hiddenAreaCenter[1]],
    hoverinfo: 'text',
    text: ['Hidden for privacy protection'],

    marker: {
      color: Colors.primary,
      opacity: 0,
      sizemin: 5,
      size: 60,
    },
  }

  const history = useHistory()
  const handleGMDNClick = (gmdn: string | undefined | null) => {
    history.push('/search', { name: undefined, gmdn })
  }

  const medline = data.med
  const stan = data.stan

  return (
    <Wrapper column>
      <Flex column={false} fullWidth>
        <SummaryWrapper column>
          {data && data.curvo?.productCategory && (
            <SummaryItem title="Curvo Category" value={data.curvo?.productCategory} />
          )}
          {stan && !isNil(stan.gicId) && !!stan.gicName && (
            <SummaryWrapperItem>
              <SummaryTitle>
                <Tooltip
                  placement="top"
                  trigger="hover"
                  tooltip="GIC ID | GIC Name"
                  hideArrow="test"
                >
                  <>GIC</>
                </Tooltip>
              </SummaryTitle>
              <ItemDetails>{`${stan.gicId} | ${stan.gicName}`}</ItemDetails>
            </SummaryWrapperItem>
          )}
          {stan && !!stan.typeOne && (
            <SummaryWrapperItem>
              <SummaryTitle>Type One</SummaryTitle>
              <ItemDetails>{stan.typeOne}</ItemDetails>
            </SummaryWrapperItem>
          )}
          {stan && !!stan.typeTwo && (
            <SummaryWrapperItem>
              <SummaryTitle>Type Two</SummaryTitle>
              <ItemDetails>{stan.typeTwo}</ItemDetails>
            </SummaryWrapperItem>
          )}
          {stan && !!stan.segment && (
            <SummaryWrapperItem>
              <SummaryTitle>Segment / Subsegment</SummaryTitle>
              <ItemDetails>{`${stan.segment}${
                stan.subSegment ? ` / ${stan.subSegment}` : ''
              }`}</ItemDetails>
            </SummaryWrapperItem>
          )}
          {stan && !!stan.material && (
            <SummaryWrapperItem>
              <SummaryTitle>Material</SummaryTitle>
              <ItemDetails>{stan.material}</ItemDetails>
            </SummaryWrapperItem>
          )}
          {gudid && gudid.gudid && (
            <SummaryItem
              title="GTIN"
              value={gudid.gudid}
              link={`https://accessgudid.nlm.nih.gov/devices/${gudid.gudid}`}
              data={data}
              searchQuery={searchQuery}
            />
          )}
          {gudid && gudid.gmdnName && (
            <SummaryWrapperItem>
              <SummaryTitle>
                <Tooltip
                  placement="top"
                  trigger="hover"
                  tooltip="Global Medical Device Nomenclature category"
                  hideArrow="test"
                >
                  <>GMDN</>
                </Tooltip>
              </SummaryTitle>
              <ItemDetails>
                <ul style={{ paddingLeft: '16px' }}>
                  {gudid.gmdnName.map((name, i) => (
                    <GMDNListItem key={i} onClick={() => handleGMDNClick(name)}>
                      <span>{name}</span>
                    </GMDNListItem>
                  ))}
                </ul>
              </ItemDetails>
            </SummaryWrapperItem>
          )}
          <FdaDetails gudid={gudid} />
          {medline && medline.unspsc && medline.unspsc.length > 0 && medline.unspscInfo && (
            <SummaryWrapperItem>
              <SummaryTitle>UNSPSC</SummaryTitle>
              <ItemDetails style={{ fontSize: 12 }}>
                {medline.unspscInfo.classTitle} ({medline.unspsc[0]})
              </ItemDetails>
            </SummaryWrapperItem>
          )}
        </SummaryWrapper>
        <PlotWrapper>
          {isHospitalCountGTE(3) ? (
            <Plot
              style={{ width: '100%', height: '100%' }}
              data={[pricingData, hoverData]}
              layout={{
                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
                // @ts-ignore
                selectdirection: 'h',
                hovermode: 'closest',
                hoverlabel: { bgcolor: '#FFF' },
                showlegend: false,
                legend: {
                  y: -0.2,
                  x: 1,
                  xanchor: 'right',
                  yanchor: 'bottom',
                  orientation: 'h',
                  font: {
                    color: Colors.textSecondary,
                    size: 14,
                  },
                },
                margin: { t: 10 },
                xaxis: {
                  zeroline: true,
                  showgrid: true,
                  showline: true,
                  tickfont: { color: Colors.textSecondary },
                  tickcolor: 'transparent',
                  ticks: 'outside',
                  tickprefix: '$',
                  range: xRange,
                  automargin: true,
                  autorange: false,
                  title: 'Unit Price',
                  titlefont: {
                    color: Colors.textSecondary,
                    size: 14,
                  },
                  separatethousands: true,
                },
                yaxis: {
                  autorange: false,
                  range: yRange,
                  showgrid: true,
                  showline: true,
                  zeroline: true,
                  tickfont: { color: Colors.textSecondary },
                  tickcolor: 'transparent',
                  ticks: 'outside',
                  automargin: true,
                  ticklen: 16,
                  title: 'Total quantity purchased',
                  titlefont: {
                    color: Colors.textSecondary,
                    size: 14,
                  },
                },
                shapes: [
                  {
                    type: 'rect',
                    // x-reference is assigned to the x-values
                    xref: 'x',
                    // y-reference is assigned to the plot paper [0,1]
                    yref: 'y',
                    x0: xRange[0],
                    y0: yRange[0],
                    x1: benchmark.iqo90,
                    y1: yRange[1],
                    fillcolor: '#dfdfdf',
                    opacity: 0.5,
                    line: {
                      width: 0,
                    },
                  },
                ],
              }}
              config={{
                displayModeBar: false,
                responsive: true,
                displaylogo: false,
                showTips: true,
              }}
            />
          ) : (
            <p>No price spray available for small samples</p>
          )}
        </PlotWrapper>
      </Flex>
      {gudid?.gudid && (
        <DetailTab
          clinicalStudyAvailable={clinicalStudyAvailable}
          similarProductsAvailable={data.hasSimilarProducts}
          type={tab}
          setType={setTab}
        />
      )}
      {gudid?.gudid && tab === 'clinical' && (
        <ClinicalStudyDetail
          onClinicalStudiesAvailable={v => setClinicalStudyAvailable(v)}
          gudid={gudid?.gudid}
        />
      )}
      {gudid?.gudid && tab === 'similarProducts' && (
        <SimilarProductsGrid skip={!data.hasSimilarProducts} gudid={gudid?.gudid} />
      )}
    </Wrapper>
  )
}
export default ExpandedDetails

const Wrapper = styled(Flex)`
  width: 100%;
  padding: 24px;
  background: #f2f4f7;
  gap: 16px;
  position: relative;
`
const PlotWrapper = styled(Flex)`
  width: 60%;
  height: 280px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding-left: 14px;
  padding-top: 10px;

  & p {
    align-self: center;
    padding: 24px;
    font-weight: ${FontWeight.regular};
    color: #6c8494;
  }
`

const SummaryWrapper = styled(Flex)`
  padding-top: 18px;
  width: 40%;
  min-height: 280px;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`

const Tab = styled(Flex)`
  margin-bottom: 0;
`
// prettier-ignore
const TabItem = styled(SummaryTitle) <{ active: boolean; isFirst?: boolean }>`
  padding: ${props => props.isFirst ? '0px 0.5rem 0px 0.25rem' : '0px 0.5rem'};
  font-weight: ${FontWeight.bold};
  margin-bottom: 0px;
  cursor: pointer;
  ${props =>
  (props.active
    ? css`
          color: ${Colors.primary};
        `
    : '')}
`

type DetailTabType = 'clinical' | 'similarProducts'

type DetailTabProps = {
  type: DetailTabType
  setType: (type: DetailTabType) => void
  clinicalStudyAvailable?: boolean | null
  similarProductsAvailable?: boolean | null
}

const DetailTab: React.FC<DetailTabProps> = ({
  type,
  setType,
  clinicalStudyAvailable,
  similarProductsAvailable,
}) => (
  <Tab align="center">
    {clinicalStudyAvailable && (
      <>
        <TabItem isFirst active={type === 'clinical'} onClick={() => setType('clinical')}>
          Clinical Trials
        </TabItem>
        {similarProductsAvailable && <Separator />}
      </>
    )}
    {clinicalStudyAvailable !== undefined && similarProductsAvailable && (
      <TabItem
        isFirst={clinicalStudyAvailable === false}
        active={type === 'similarProducts'}
        onClick={() => setType('similarProducts')}
      >
        Similar Products
        <Tooltip tooltip="Enhanced by AI" placement="top" trigger="hover" hideArrow="true">
          <img style={{ height: 16, marginLeft: '0.5em' }} src={aiIcon} alt="AI feature" />
        </Tooltip>
      </TabItem>
    )}
  </Tab>
)
