import React, { useEffect } from 'react'
import styled from 'styled-components'
import logoIcon from '../../../assets/img/logo-horizontal-by-curvo.svg'
import { Flex } from '../../../shared/components/Flex'

const Wrapper = styled(Flex)`
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
`

const FormWrapper = styled(Flex)`
  border-radius: 3px;
  padding: 3rem;
  padding-top: 1rem;
  box-sizing: border-box;
  min-width: 300px;
`

const TagHeader = styled(Flex)`
  padding: 1rem;
  padding-top: 2rem;
  width: 100%;
  color: white;
  background-color: #4aa4ba;
  text-align: center;
  font-size: 2rem;

  h2 {
    font-size: 4rem;
  }

  em {
    margin: 0 0.5rem;
  }
`

const SignUpInfoWrapper = styled(Flex)`
  /*padding: 1rem;*/
  padding-top: 0;
  /*width: 98%;*/
  flex-wrap: wrap;
`

const SignUpInfo = styled(Flex)`
  color: #4aa4ba;
  font-size: 3rem;
  div {
    width: 55%;
    text-align: center;
  }
`

const Logo = styled('img')`
  width: 45%;
  margin-top: 9rem;
  margin-bottom: 1rem;
`

const AlternativeUserInfo = styled('div')`
  color: #4aa4ba;
  font-size: 1.5rem;
  font-style: italic;
  text-align: center;

  a {
    text-decoration: underline;
  }
`

type Props = { formEnabled: boolean; children?: React.ReactNode }

const SignUpLayout: React.FC<Props> = ({ children, formEnabled }) => {
  // Hide/Show JSD widget
  useEffect(() => {
    const jsdWidget = document.getElementById('jsd-widget')
    jsdWidget && (jsdWidget.style.display = 'none')
    return () => {
      jsdWidget && (jsdWidget.style.display = '')
    }
  })

  return (
    <Wrapper column>
      <TagHeader column align="center">
        <h2>Device + Price</h2>
        <div>
          Are you ready for
          <em>FREAKISHLY</em>
          good insights?
        </div>
      </TagHeader>
      <SignUpInfoWrapper>
        <SignUpInfo flex={1} column align="center">
          <Logo src={logoIcon} alt="Lookup by Curvo" />
          <Flex align="center" marginTop="1rem" flex={0.25}>
            2 week free trial available for healthcare providers!
          </Flex>
          <Flex
            flex={1}
            column
            align="center"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
              color: '#E67F45',
              textDecoration: 'underline',
            }}
          >
            {!formEnabled && <a href="mailto:lookup@curvolabs.com">Contact us to get access!</a>}
          </Flex>
        </SignUpInfo>
        {formEnabled && (
          <Flex flex={1} padding="1rem">
            <FormWrapper column>{children}</FormWrapper>
          </Flex>
        )}
      </SignUpInfoWrapper>
      <AlternativeUserInfo>
        Not a healthcare provider? That’s ok! &nbsp;
        <a href="mailto:lookup@curvolabs.com" style={{ color: '#E67F45' }}>
          Contact us
        </a>{' '}
        &nbsp; to discuss access options.
      </AlternativeUserInfo>
    </Wrapper>
  )
}
export default SignUpLayout
