import { Empty, Table, TableProps } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SimilarProduct } from '../../api'
import { formatCurrency } from '../../api/common/Utils'
import { FetchSimilarProducts } from '../../api/components/Search'
import aiIconNeg from '../../assets/img/ai_icon_neg.svg'
import { Button } from '../../shared/components/Button'
import Tooltip from '../../shared/components/Tooltip'

type Props = {
  gudid?: string | null
}

const NoWrap = styled.div`
  white-space: nowrap;
`

const columns: TableProps<SimilarProduct>['columns'] = [
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    render: value => <Link to={{ state: { supplier: value } }}>{value}</Link>,
  },
  {
    title: 'Item #',
    dataIndex: 'manufacturerItem',
    render: (value, data) => (
      <Link to={{ state: { supplier: data.manufacturer, partial: data.manufacturerItem } }}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'UoM',
    dataIndex: 'uom',
  },
  {
    title: <NoWrap>Bottom Quartile</NoWrap>,
    dataIndex: ['benchmarks', 'iqo25'],
    render: v => formatCurrency('-')(v),
  },
  {
    title: <NoWrap>Median</NoWrap>,
    dataIndex: ['benchmarks', 'iqo50'],
    render: v => formatCurrency('-')(v),
  },
  {
    title: <NoWrap>Top Quartile</NoWrap>,
    dataIndex: ['benchmarks', 'iqo75'],
    render: v => formatCurrency('-')(v),
  },
  {
    title: <NoWrap>Top Decile</NoWrap>,
    dataIndex: ['benchmarks', 'iqo90'],
    render: v => formatCurrency('-')(v),
  },
]

export const SimilarProductsGrid: React.FC<Props & { skip?: boolean }> = ({ gudid, skip }) => {
  if (skip) {
    return <></>
  }
  return (
    <FetchSimilarProducts variables={{ input: { gudid } }} skip={!gudid || skip}>
      {({ data, loading }) => {
        return (
          <SimilarProductsWrapper>
            <Table
              loading={loading}
              size="small"
              columns={columns}
              dataSource={data?.similarProducts?.data || []}
              locale={{
                emptyText: <Empty description={skip ? 'No Similar Product' : 'Similar Products'} />,
              }}
            />
          </SimilarProductsWrapper>
        )
      }}
    </FetchSimilarProducts>
  )
}

export const SimilarProducts: React.FC<Props> = ({ gudid }) => {
  const [showSimilarProducts, setShowSimilarProducts] = useState(false)
  return (
    <>
      <Tooltip tooltip="Enhanced by AI" placement="top" trigger="hover" hideArrow="true">
        {!showSimilarProducts ? (
          <Button
            onClick={() => {
              setShowSimilarProducts(true)
            }}
          >
            Similar Products
            <img style={{ height: 16, marginLeft: '0.5em' }} src={aiIconNeg} alt="AI feature" />
          </Button>
        ) : (
          <></>
        )}
      </Tooltip>
      <SimilarProductsGrid gudid={gudid} skip={!showSimilarProducts} />
    </>
  )
}

const SimilarProductsWrapper = styled.div`
  width: 100%;
  padding: 0 4px 4px 4px;
`
