import React, { useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import ResultList from '../SearchResult/ResultList'
import { ApolloClientContext, SharedSearchData, SharedResultQuery } from '../../api'
import { Sidebar } from '../SearchResult/Sidebar'
import logoIcon from '../../assets/img/logo-horizontal-icon.svg'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'

const Logo = styled(Flex)`
  cursor: pointer;
`
const Content = styled(Flex)`
  width: 100%;
  background: #fafafc;
  padding-bottom: 5vh;
`

const InfoModal = styled(Flex)`
  width: 70vw;
  position: fixed;
  bottom: 50px;
  height: 60px;
  align-self: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  align-items: center;
  padding: 0 12px;
  background: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    bottom: 52px;
  }
`

type Props = {} & RouteComponentProps<{ id: string }, {}, {}>

const SharedResult: React.FC<Props> = ({ match, history }) => {
  const { id } = match.params
  const { client } = useContext(ApolloClientContext)
  const { loading, data } = useQuery<{ searchBySharedSearch: SharedSearchData }>(
    SharedResultQuery,
    {
      client,
      variables: {
        searchId: id,
      },
    },
  )

  if (!data && loading) {
    return <LoadingIndicator style={{ margin: '10px' }} color={Colors.primary} />
  }

  if (!data) {
    return <div>No Data</div>
  }

  const filtersData = data.searchBySharedSearch.filters
  const resultsData = data.searchBySharedSearch.results

  return (
    <Flex column fullWidth style={{ height: '100vh' }}>
      <Flex flex="0 0 64px" align="center" paddingLeft="20px">
        <Logo flex="0 0 20%" onClick={() => history.push('/')}>
          <img src={logoIcon} height="40" alt="Lookup" />
        </Logo>
      </Flex>
      <Content flex={1} fullWidth align="stretch">
        <Flex flex="0 0 20%">
          <Sidebar loading={loading} data={filtersData} isShared />
        </Flex>
        <Flex flex="1">
          <ResultList loading={loading} data={resultsData} searchQuery={{}} isShared />
        </Flex>
      </Content>
      <InfoModal onClick={() => history.push('/auth')}>
        To continue to use the app please click here to sign in or register an account.
      </InfoModal>
    </Flex>
  )
}

export default withRouter(SharedResult)
