import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import base from './base'

export default createGlobalStyle`
  ${styledNormalize}
  ${base}
  
  .uppercase-input input {
    text-transform: uppercase;
  }
`
