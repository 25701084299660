import React from 'react'
import styled from 'styled-components'
import logoIcon from '../../../assets/img/logo-icon.svg'
import { Flex } from '../../../shared/components/Flex'

const Wrapper = styled(Flex)`
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`

const FormWrapper = styled(Flex)`
  width: 600px;
  border: 1px solid #d8e1e8;
  border-radius: 3px;
  padding: 3rem;
  box-sizing: border-box;
`

type Props = {
  children?: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ children }) => (
  <Wrapper column>
    <Flex marginBottom="3rem">
      <img src={logoIcon} alt="Lookup" width="150" />
    </Flex>
    <FormWrapper column>{children}</FormWrapper>
  </Wrapper>
)

export default AuthLayout
