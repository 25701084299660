import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { SearchQueryInput, SearchTypeEnum } from '../../api'
import { Flex } from '../../shared/components/Flex'
import PageLayout from '../../shared/components/PageLayout'
import { useStateValue } from '../../state/State'
import ResultList from './ResultListContainer'
import Sidebar from './Sidebar'

// prettier-ignore
type Props = {} & RouteComponentProps<
  {},
  {},
  {
    name?: string
    supplier?: string
    uom?: string
    partial?: string
    segment?: string
    subSegment?: string
    gic?: string
    gicType1?: string
    gicType2?: string
    productLine?: string
    productCategory?: string
    clinicalCategory?: string
    description?: string
    gmdn?: string
    gudid?: string
    type?: SearchTypeEnum
    exact?: boolean
  }
>

const SearchResult: React.FC<Props> = ({ location, history }) => {
  const [{ searchTerm }] = useStateValue()
  const [searchQuery, setSearchQuery] = useState<SearchQueryInput>({})
  const [sidebarSearchQuery, setSidebarSearchQuery] = useState<SearchQueryInput>({})

  useEffect(() => {
    setSidebarSearchQuery(searchQuery)
  }, [searchQuery])

  const handleSetSearch = (data: SearchQueryInput) => {
    setSearchQuery(oldSearchQuery => ({
      ...oldSearchQuery,
      ...data,
    }))
  }

  const handleSetSearchCB = useCallback(handleSetSearch, [])

  useEffect(() => {
    if (
      location.state &&
      !(Object.keys(location.state).length === 0 && location.state.constructor === Object)
    ) {
      const {
        name,
        supplier,
        uom,
        partial,
        segment,
        subSegment,
        gic,
        gicType1,
        gicType2,
        clinicalCategory,
        productCategory,
        productLine,
        description,
        gmdn,
        gudid,
        type,
        exact,
      } = location.state
      handleSetSearchCB({
        searchText: name || '',
        supplier,
        uom,
        partialNumber: partial,
        segment,
        subSegment,
        gic,
        gicType1,
        gicType2,
        clinicalCategory,
        productCategory,
        productLine,
        description,
        gmdn,
        gudid,
        type,
        exact,
      })
    } else {
      if (!searchTerm) {
        history.push('/')
        return
      }
      handleSetSearchCB({
        searchText:
          (typeof searchTerm === 'string' && searchTerm) ||
          searchTerm.reduce(
            (acc: string, curr: string) => `${acc && `${acc},`} ${curr}`.trim(),
            '',
          ),
      })
    }
  }, [location.state, searchTerm, history, handleSetSearchCB])

  if (!searchQuery) {
    return null
  }

  return (
    <PageLayout>
      <Flex flex="0 0 20%">
        <Sidebar
          sidebarQuery={sidebarSearchQuery}
          searchQuery={searchQuery}
          searchType={location.state && location.state.type}
          setSearchQuery={handleSetSearch}
        />
      </Flex>
      <Flex flex={1}>
        <ResultList searchQuery={searchQuery} />
      </Flex>
    </PageLayout>
  )
}

export default SearchResult
