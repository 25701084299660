import { gql } from 'apollo-boost/lib/index'
import { mutateFunction, buildQuery } from '../common/Utils'
import {
  Mutation,
  FileUploadInput,
  Query,
  QueryCheckFileInfoArgs,
  MutationProcessUploadedFileArgs,
  MutationDeleteMappingArgs,
  MutationUpdateMappingArgs,
  MutationCreateMappingArgs,
  MutationRemoveRecentFileArgs,
} from '../gen/Models'

export const genUploadUrlMutation = mutateFunction<
  Pick<Mutation, 'generateUploadInfo'>,
  { data: FileUploadInput }
>({
  mutation: gql`
    mutation generateUploadInfo($data: FileUploadInput!) {
      generateUploadInfo(data: $data) {
        fileId
        uploadUrl
      }
    }
  `,
})

export const MappingFragment = gql`
  fragment MappingFragment on Mapping {
    id
    mapping
    name
  }
`

export const searchByFileMutation = mutateFunction<
  Pick<Mutation, 'processUploadedFile'>,
  MutationProcessUploadedFileArgs
>({
  mutation: gql`
    mutation processUploadedFile($input: ProcessFileArgs!) {
      processUploadedFile(input: $input)
    }
  `,
})

export const genDownloadUrlMutation = mutateFunction<
  Pick<Mutation, 'generateDownloadUrl'>,
  { fileId: number }
>({
  mutation: gql`
    mutation generateDownloadUrl($fileId: Int!) {
      generateDownloadUrl(fileId: $fileId) {
        fileId
        downloadUrl
      }
    }
  `,
})

export const checkHeadersMutation = mutateFunction<
  Pick<Mutation, 'checkHeaders'>,
  { fileId: number }
>({
  mutation: gql`
    mutation checkHeaders($fileId: Int!) {
      checkHeaders(fileId: $fileId) {
        headers
        sample
      }
    }
  `,
})

export const CheckFileInfoData = buildQuery<Pick<Query, 'checkFileInfo'>, QueryCheckFileInfoArgs>({
  query: gql`
    query checkFileInfo($fileId: Int!) {
      checkFileInfo(fileId: $fileId) {
        status
        downloadUrl
        numLines
        numMatchedLines
      }
    }
  `,
})

export const MAPPINGS_QUERY = gql`
  query mappings {
    mappings {
      ...MappingFragment
    }
  }
  ${MappingFragment}
`

export const MappingsData = buildQuery<Pick<Query, 'mappings'>>({
  query: MAPPINGS_QUERY,
})

export const createMappingMutation = mutateFunction<
  Pick<Mutation, 'createMapping'>,
  MutationCreateMappingArgs
>({
  mutation: gql`
    mutation createMapping($input: CreateMappingInput!) {
      createMapping(input: $input) {
        ...MappingFragment
      }
    }
    ${MappingFragment}
  `,
})

export const updateMappingMutation = mutateFunction<
  Pick<Mutation, 'updateMapping'>,
  MutationUpdateMappingArgs
>({
  mutation: gql`
    mutation updateMapping($input: UpdateMappingInput!, $id: Int!) {
      updateMapping(id: $id, input: $input) {
        ...MappingFragment
      }
    }
    ${MappingFragment}
  `,
})

export const deleteMappingMutation = mutateFunction<
  Pick<Mutation, 'deleteMapping'>,
  MutationDeleteMappingArgs
>({
  mutation: gql`
    mutation deleteMapping($id: Int!) {
      deleteMapping(id: $id)
    }
  `,
})

export const removeRecentFileMutation = mutateFunction<
  Pick<Mutation, 'removeRecentFile'>,
  MutationRemoveRecentFileArgs
>({
  mutation: gql`
    mutation removeRecentFile($fileId: Int!) {
      removeRecentFile(fileId: $fileId) {
        id
        name
        url
        numLines
        numMatchedLines
      }
    }
  `,
})
