export enum MixPanelEvent {
  searches = 'searches',
  facet = 'facet',
  upload = 'upload',
  itemExpand = 'itemExpand',
  itemSummaryClick = 'itemSummaryClick',
  itemLinkIcon = 'itemLinkIcon',
  itemLink = 'itemLink',
}

enum SearchType {
  single,
  multi,
  advanced,
}

export type MPSearches = {
  searchString: string | object
  type: SearchType
  resultFound: number
  isRecent: boolean
}
