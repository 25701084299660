import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import { FontWeight, Colors } from '../../shared/constants'
import { SearchTypeEnum } from '../../api'
import { Separator } from './shared/Separator'

const Tab = styled(Flex)`
  margin-bottom: 0.75rem;
`
// prettier-ignore
const TabItem = styled('span')<{ active: boolean }>`
  padding: 0px 0.75rem;
  font-size: 1.1rem;
  font-weight: ${FontWeight.bold};
  cursor: pointer;
  ${props =>
    (props.active
      ? css`
          color: ${Colors.primary};
        `
      : css`
          color: #6c8494;
        `)};
`

type Props = {
  searchType: SearchTypeEnum
  setSearchType: (type: SearchTypeEnum) => void
}

const SearchModeTab: React.FC<Props> = ({ searchType, setSearchType }) => (
  <Tab align="center">
    <Link to={{ state: { searchMode: SearchTypeEnum.Part } }}>
      <TabItem
        active={searchType === SearchTypeEnum.Part}
        onClick={() => setSearchType(SearchTypeEnum.Part)}
      >
        Part Search
      </TabItem>
    </Link>
    <Separator />
    <Link to={{ state: { searchMode: SearchTypeEnum.Keyword } }}>
      <TabItem
        active={searchType === SearchTypeEnum.Keyword}
        onClick={() => setSearchType(SearchTypeEnum.Keyword)}
      >
        Keyword Search
      </TabItem>
    </Link>
    <Separator />
    <TabItem
      active={searchType === SearchTypeEnum.Advanced}
      onClick={() => setSearchType(SearchTypeEnum.Advanced)}
    >
      Advanced Search
    </TabItem>
  </Tab>
)

export default SearchModeTab
