import { ShortMonths } from './constants'

export const convertDateTime = (input: Date | string) => {
  const date = new Date(input)
  const dayOfMonth = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${dayOfMonth} ${ShortMonths[month]} ${year}`
}

export function debounce(func: Function, wait: number, immediate = false) {
  let timeout: NodeJS.Timeout | undefined
  return function (context: ThisParameterType<Function>) {
    const args = arguments
    const later = function () {
      timeout = undefined
      if (!immediate) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    if (timeout !== undefined) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

/**
 * There is no valid ENV var for this purpose
 * @TODO: Implement ENV vars for this
 */
export function getEnv() {
  return window.location.hostname === 'lookup.curvolabs.com'
    ? 'production'
    : window.location.hostname === 'lookup-staging.curvolabs.com'
    ? 'staging'
    : 'dev'
}
