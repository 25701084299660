import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import { SearchQueryInput, FilterItem } from '../../api'
import useMixpanel from '../../hooks/useMixpanel'
import checkIcon from '../../assets/img/check.svg'

import { MixPanelEvent } from '../../shared/Mixpanel'
import Env from '../../api/gen/Env'
import { Item } from './SidebarList'

const Name = styled('span')`
  color: #17232c;
  text-transform: capitalize;
  margin-right: 16px;
`

const Checkbox = styled(Flex)`
  flex: 0 0 1rem;
  margin-right: 4px;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 0.75rem;
  }
`

type Props = {
  item: FilterItem
  setSearchQuery?: (input: SearchQueryInput) => void
  selectedItems: SearchQueryInput | undefined
  category: string
  loading?: boolean
  total: number | undefined
}

const SearchFilterItem: React.FC<Props> = ({
  item,
  setSearchQuery,
  selectedItems,
  category,
  loading,
  total,
}) => {
  const mixpanel = useMixpanel()
  const selectedItem = selectedItems && selectedItems[category as keyof SearchQueryInput]

  const handleSetSearchQuery = () => {
    if (!setSearchQuery) {
      return
    }
    if (selectedItem === item.name) {
      setSearchQuery({ [category]: '' })
    } else {
      // Mixpanel track on clicking facet item
      if (Env.useMixpanel) {
        mixpanel.track(MixPanelEvent.facet, {
          facet: category,
          value: item.name,
        })
      }

      setSearchQuery({ [category]: item.name })
    }
  }

  const currentSelection = selectedItem && selectedItem === item.name
  const numberDisplay = !selectedItem
    ? new Intl.NumberFormat('en-US').format(item.total)
    : currentSelection && !loading
    ? total
    : ''

  return (
    <Item fullWidth onClick={handleSetSearchQuery}>
      <Flex align="center" flex={1} marginRight="16px">
        <Checkbox>{currentSelection && <img src={checkIcon} alt="" />}</Checkbox>
        <Name>{item.name.toUpperCase()}</Name>
      </Flex>
      <span style={{ color: '#516A7A', fontSize: '12px' }}>{numberDisplay}</span>
    </Item>
  )
}

export default SearchFilterItem
