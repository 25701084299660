import React from 'react'
import {
  CognitoUser,
  ICognitoUserPoolData,
  CognitoUserPool,
  CognitoUserSession,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'
import Env from '../gen/Env'

const defaultValue = {
  isAuth: false,
}

type ContextType = typeof defaultValue & {
  getToken(): Promise<string>
  logIn(username: string, password: string, newPassword?: string): Promise<void>
  logOut(): Promise<void>
  forgotPassword(email: string): Promise<void>
  changePassword(email: string, oldPassword: string, newPassword: string): Promise<void>
  confirmPassword(email: string, code: string, password: string): Promise<void>
}

export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'

export const CognitoContext = React.createContext<ContextType>({
  ...defaultValue,
  getToken: () => Promise.reject(),
  logIn: () => Promise.reject(),
  logOut: () => Promise.reject(),
  forgotPassword: () => Promise.reject(),
  changePassword: () => Promise.reject(),
  confirmPassword: () => Promise.reject(),
})
CognitoContext.displayName = 'CognitoContext'

const poolConfig: ICognitoUserPoolData = {
  UserPoolId: Env.cognitoPoolId,
  ClientId: Env.cognitoClientId,
}

export const CognitoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = React.useState<CognitoUser | null>(
    new CognitoUserPool(poolConfig).getCurrentUser(),
  )

  const getToken = async () => {
    try {
      if (user) {
        const token = await new Promise<string>((resolve, reject) => {
          user.getSession((err: Error, session: CognitoUserSession) => {
            if (err) {
              reject(err)
            } else if (!session.isValid()) {
              user.refreshSession(
                session.getRefreshToken(),
                (refreshErr: Error, newSession: CognitoUserSession) => {
                  if (refreshErr) {
                    reject(refreshErr)
                  } else {
                    resolve(newSession.getAccessToken().getJwtToken())
                  }
                },
              )
            } else {
              resolve(session.getAccessToken().getJwtToken())
            }
          })
        })
        return token
      }
    } catch (e) {
      console.warn('Session error')
      logOut()
    }
    return ''
  }

  const logIn = async (Username: string, Password: string, newPassword?: string) => {
    const authUser = await new Promise<CognitoUser>((resolve, reject) => {
      if (user) {
        logOut()
      }
      const newUser = new CognitoUser({ Username, Pool: new CognitoUserPool(poolConfig) })
      newUser.authenticateUser(new AuthenticationDetails({ Username, Password }), {
        onSuccess: () => resolve(newUser),
        onFailure: err => reject(err),
        newPasswordRequired() {
          if (!newPassword) {
            reject(new Error(NEW_PASSWORD_REQUIRED))
            return
          }
          newUser.completeNewPasswordChallenge(newPassword, null, this)
        },
      })
    })
    setUser(authUser)
  }

  const logOut = async () => {
    if (user) {
      user.signOut()
    }
    setUser(null)
  }

  const forgotPassword = async (email: string): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const forgotPasswordUser = new CognitoUser({
        Username: email,
        Pool: new CognitoUserPool(poolConfig),
      })
      forgotPasswordUser.forgotPassword({
        onSuccess: _data => {
          resolve()
        },
        onFailure: err => {
          reject(err)
        },
      })
    })

  const changePassword = async (email: string, oldPassword: string, newPassword: string) =>
    new Promise<void>((resolve, reject) => {
      const userPool = new CognitoUserPool(poolConfig)
      const authenDetail = new AuthenticationDetails({
        Username: email,
        Password: oldPassword,
      })
      const changePasswordUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      })
      changePasswordUser.authenticateUser(authenDetail, {
        onSuccess: _session => {
          changePasswordUser.changePassword(oldPassword, newPassword, error => {
            if (error) {
              reject(error)
            }
            resolve()
          })
        },
        onFailure: err => {
          reject(err)
        },
      })
    })

  const confirmPassword = async (email: string, code: string, password: string): Promise<void> => {
    const confirmPasswordUser = new CognitoUser({
      Username: email,
      Pool: new CognitoUserPool(poolConfig),
    })

    return new Promise((resolve, reject) => {
      confirmPasswordUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve()
        },
        onFailure: e => {
          reject(e)
        },
      })
    })
  }

  const value: ContextType = {
    isAuth: !!user,
    getToken,
    logIn,
    logOut,
    forgotPassword,
    changePassword,
    confirmPassword,
  }

  return <CognitoContext.Provider value={value}>{children}</CognitoContext.Provider>
}
