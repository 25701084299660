import React, { createContext, useContext, useReducer, Dispatch } from 'react'
import { Actions } from './Actions'

type Props = {
  reducer: any
  initialState: any
  children: JSX.Element[] | JSX.Element
}

export const StateContext = createContext<[any, Dispatch<{ payload: any; type: Actions }>]>([
  null,
  () => {},
])
export const StateProvider: React.FC<Props> = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)
export const useStateValue = () => useContext(StateContext)
