import { Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import * as Yup from 'yup'
import { CognitoContext } from '../../api'
import { Flex } from '../../shared/components/Flex'
import { Button } from '../../shared/components/Button'
import LoginForm from './LoginForm'
import { NormalText } from './shared/NormalText'
import AuthLayout from './shared/AuthLayout'

const initialValues = {
  email: '',
  password: '',
  newPasswordRequired: false,
  newPassword: '',
  confirmNewPassword: '',
}

export type MessageTypes = 'success' | 'error' | 'info' | 'warning'

export type Values = typeof initialValues & {
  formMessage?: string
  messageType?: MessageTypes
}

export const VERIFIED_MESSAGE = 'Account Verified!'

const validateSchema = () => {
  const shape = {
    password: Yup.string().required('Password is required!'),
    email: Yup.string().required('Email is required!'),
    newPasswordRequired: Yup.boolean(),
    newPassword: Yup.mixed().when('newPasswordRequired', {
      is: true,
      then: Yup.string()
        .min(6, 'Minimum length of 6')
        .max(256, ' Maximum length of 256')
        .required('New Password is required!'),
    }),
    confirmNewPassword: Yup.mixed().when('newPasswordRequired', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], 'Passwords do not match.')
        .min(6, 'Minimum length of 6')
        .max(256, ' Maximum length of 256')
        .required('Confirm Password is required'),
    }),
  }
  return Yup.object().shape(shape)
}

type Props = {} & RouteComponentProps<
  {},
  {},
  {
    email?: string
    message?: string
    type?: MessageTypes
    from?: string
  }
> & {}

const Login: React.FC<Props> = ({ history, location }) => {
  const { logIn } = useContext(CognitoContext)
  const handleSignIn = async (
    values: Values,
    { setSubmitting, setFieldValue }: FormikHelpers<Values>,
  ) => {
    try {
      await logIn(values.email.toLowerCase(), values.password, values.newPassword)
      setSubmitting(false)
      if (location.state && location.state.from) {
        history.push(location.state.from)
      } else {
        history.push('/')
      }
    } catch (error) {
      setSubmitting(false)
      if (error.code === 'UserNotConfirmedException') {
        history.push('/auth/verify-account', { email: values.email })
        return
      }
      if (error.message === 'NEW_PASSWORD_REQUIRED') {
        setFieldValue('newPasswordRequired', true)
      } else {
        setFieldValue('formMessage', error.message)
        setFieldValue('messageType', 'error')
      }
    }
  }
  const formValues =
    location.state && location.state.message
      ? {
          ...initialValues,
          email: location.state.email || '',
          formMessage: location.state.message,
          messageType: location.state.type || 'error',
        }
      : initialValues
  return (
    <AuthLayout>
      <Formik
        initialValues={formValues}
        onSubmit={handleSignIn}
        validationSchema={validateSchema}
        render={props => <LoginForm {...props} />}
      />
      <Flex>
        <NormalText>New to Lookup?</NormalText>{' '}
        <Button btnType="link">
          <Link to="/auth/signup">Create new account</Link>
        </Button>
        <Button btnType="link">
          <Link to="/auth/forgot-password">Forgot password?</Link>
        </Button>
      </Flex>
    </AuthLayout>
  )
}

export default withRouter(Login)
