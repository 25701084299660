import { FormikHandlers, FormikState } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../shared/components/Button'
import TextInput from '../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../shared/components/Spinner'
import { Flex } from '../../shared/components/Flex'
import { Colors } from '../../shared/constants'
import { useDebounce } from '../../hooks/useDebounce'
import { InfoText } from './LoginForm'
import { Values } from './SignUp'
import { PageTitle } from './shared/PageTitle'

const Form = styled('form')`
  width: 100%;
`
const StyledTextInput = styled(TextInput)`
  margin: 20px 0;
  input {
    padding: 1rem 0.75rem;
    height: auto !important;
  }
`

const StyledButton = styled(Button)`
  height: auto;
  padding: 1rem;
`

const PactSafe = styled('div')`
  a {
    color: ${Colors.primary};
  }

  .ps-contract {
    padding-bottom: 0.5rem !important;
  }

  .ps-checkbox-container {
    font-size: 1rem !important;
  }
`

const SignupFormTitle = styled(PageTitle)`
  color: #f17107;
  font-size: 1.75rem;
`

const defaultProps = {}

type Props = Partial<typeof defaultProps> & FormikState<Values> & FormikHandlers

declare var _ps: any

const SignUpForm: React.FC<Props> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const [isAgree, setIsAgree] = useState(false)
  const agreementKey = 'lookup-by-curvo'
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()

  const debouncedFirstName = useDebounce(firstName, 500)
  const debouncedLastName = useDebounce(lastName, 500)

  useEffect(() => {
    _ps('set', 'custom_data', {
      first_name: debouncedFirstName,
      last_name: debouncedLastName,
    })
  }, [debouncedFirstName, debouncedLastName])

  useEffect(() => {
    _ps('create', '721e69ab-720f-4525-af86-304abdd0a676', {
      dynamic: true,
    })

    _ps('load', agreementKey, {
      container_selector: 'pactsafe-contract',
      signer_id_selector: 'email',
    })

    _ps.on('all', () => {
      if (_ps.getByKey(agreementKey) && _ps.getByKey(agreementKey).allChecked()) {
        setIsAgree(true)
      } else {
        setIsAgree(false)
      }
    })

    return () => {
      _ps.off()
      _ps('remove')
    }
  }, [])

  return (
    <Form onSubmit={handleSubmit}>
      <SignupFormTitle>Create your free account now!</SignupFormTitle>
      {values.formMessage ? (
        <InfoText type={values.messageType} marginBottom="1rem">
          {values.formMessage && values.formMessage}
        </InfoText>
      ) : (
        ''
      )}
      <Flex fullWidth>
        <StyledTextInput
          style={{ flex: '1 1 auto', marginRight: '1rem', marginBottom: 0, marginTop: 0 }}
          inputProps={{
            name: 'firstName',
            value: values.firstName,
            placeholder: 'First Name',
            onChange: e => {
              setFirstName(e.target.value)
              handleChange(e)
            },
          }}
          error={touched.firstName ? errors.firstName : undefined}
        />
        <StyledTextInput
          style={{ flex: '1 1 auto', margin: 0 }}
          inputProps={{
            name: 'lastName',
            value: values.lastName,
            onChange: e => {
              setLastName(e.target.value)
              handleChange(e)
            },
            placeholder: 'Last Name',
          }}
          error={touched.lastName ? errors.lastName : undefined}
        />
      </Flex>
      <StyledTextInput
        inputProps={{
          id: 'email',
          name: 'email',
          value: values.email.toLowerCase(),
          onChange: handleChange,
          placeholder: 'Provider Email',
        }}
        error={touched.email ? errors.email : undefined}
      />
      <StyledTextInput
        inputProps={{
          name: 'password',
          value: values.password,
          onChange: handleChange,
          type: 'password',
          placeholder: 'Password',
        }}
        error={touched.password ? errors.password : undefined}
      />
      <StyledTextInput
        inputProps={{
          name: 'confirmPassword',
          value: values.confirmPassword,
          onChange: handleChange,
          type: 'password',
          placeholder: 'Re-enter Password',
        }}
        error={touched.password ? errors.confirmPassword : undefined}
      />
      <StyledTextInput
        inputProps={{
          name: 'organization',
          value: values.organization,
          onChange: handleChange,
          placeholder: 'Organization',
        }}
        error={touched.organization ? errors.organization : undefined}
      />
      <PactSafe id="pactsafe-contract" />
      <StyledButton fullWidth marginBottom="1rem" type="submit" disabled={!isAgree}>
        {isSubmitting ? <LoadingIndicator /> : 'Create Account'}
      </StyledButton>
    </Form>
  )
}

SignUpForm.defaultProps = defaultProps

export default SignUpForm
