import { Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Billing, ApolloClientContext } from '../../../api'
import { Button } from '../../../shared/components/Button'
import { Flex } from '../../../shared/components/Flex'
import TextInput from '../../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../../shared/components/Spinner'
import { PageTitle } from '../../Auth/shared/PageTitle'
import { updateBillingMutation, createBillingMutation } from '../../../api/components/Billing'
import Notify from '../../../shared/components/Notify'

const Wrapper = styled(Flex)`
  padding: 32px;
  background: #fff;
  width: 700px;
  border-radius: 4px;
`

const Form = styled('form')`
  width: 100%;
`

type Props = {
  userId: number
  billingInfo?: Billing
  close: () => void
}

const validateSchema = () => {
  const shape = {
    expireTime: Yup.date().required('Expire time is required!'),
    searchLimit: Yup.number().required('Search limit is require').min(1),
  }

  return Yup.object().shape(shape)
}

type Values = {
  expireTime: Date
  searchLimit: number
}

const BillingInfoModal: React.FC<Props> = ({ userId, billingInfo, close }) => {
  const { client } = useContext(ApolloClientContext)
  const initialValues = {
    expireTime: billingInfo ? billingInfo.expireTime : new Date(),
    searchLimit: billingInfo ? billingInfo.searchLimit : 0,
  }
  const handleSave = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    // call edit user info mutation
    setSubmitting(true)
    if (billingInfo) {
      await updateBillingMutation(client, {
        input: {
          userId,
          expireTime: values.expireTime,
          searchLimit: +values.searchLimit,
        },
      })
      Notify.success('Updated Billing Info successfully!')
    } else {
      await createBillingMutation(client, {
        input: {
          userId,
          expireTime: values.expireTime,
          searchLimit: +values.searchLimit,
        },
      })
      Notify.success('Created Billing successfully!')
    }
    close()
    setSubmitting(false)
  }

  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSave} validationSchema={validateSchema}>
        {({ values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <PageTitle>{billingInfo ? 'Edit Billing Info' : 'Create Billing Info'}</PageTitle>
            <Flex fullWidth marginTop={32}>
              <TextInput
                label="Expire Date"
                inputProps={{
                  placeholder: 'Expire Date',
                  name: 'expireTime',
                  value: values.expireTime ? values.expireTime.toLocaleString() : '',
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                style={{ marginBottom: 24, flex: 1, marginRight: 16 }}
              />
              <TextInput
                label="Search Limit"
                inputProps={{
                  placeholder: 'Search Limit',
                  name: 'searchLimit',
                  value: values.searchLimit,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={(touched.searchLimit && errors.searchLimit) || undefined}
                style={{ marginBottom: 24, flex: 1 }}
              />
            </Flex>
            <Button fullWidth type="submit" disabled={isSubmitting}>
              {isSubmitting ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : 'Save'}
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default BillingInfoModal
