import { css } from 'styled-components'

export default css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 16px;
  }
  body,
  #root {
    width: 100%;
    font-family: 'Neurial Grotesk';
  }
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
`
