import { FormikProps } from 'formik'
import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import { MessageTypes } from '../Auth/Login'
import { PageTitle } from '../Auth/shared/PageTitle'

const defaultProps = {}
type Props = typeof defaultProps & FormikProps<ShareResultValues>

export const initialValues = {
  receiverEmail: '',
  receiverFirstName: '',
  receiverLastName: '',
}

const ShareFormStyle = styled.div`
  background: ${Colors.whiteBackground};
  padding: 40px;
  border-radius: 4px;
`

export type ShareResultValues = typeof initialValues & {
  formMessage?: string
  messageType?: MessageTypes
}

export const InfoText = styled(Flex)<{ type?: MessageTypes }>`
  height: 24px;
  width: 100%;
  justify-content: center;
  ${props => {
    switch (props.type) {
      case 'success':
        return css`
          color: ${Colors.success};
        `
      case 'info':
        return css`
          color: ${Colors.info};
        `
      case 'warning':
        return css`
          color: ${Colors.warning};
        `
      case 'error':
        return css`
          color: ${Colors.danger};
        `
      default:
        return css`
          color: ${Colors.danger};
        `
    }
  }};
`
const StyledTextInput = styled(TextInput)`
  margin-bottom: 32px;
`
const Form = styled('form')`
  width: 100%;
`

const SharedResultForm: React.FC<Props> = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
}) => (
  <ShareFormStyle>
    <Form onSubmit={handleSubmit}>
      <PageTitle>Share Result</PageTitle>
      <InfoText type={values.messageType} marginBottom="1rem">
        {values.formMessage && values.formMessage}
      </InfoText>
      <StyledTextInput
        inputProps={{
          placeholder: 'First Name',
          name: 'receiverFirstName',
          value: values.receiverFirstName,
          onChange: handleChange,
          onBlur: handleBlur,
        }}
        error={(touched.receiverFirstName && errors.receiverFirstName) || undefined}
      />
      <StyledTextInput
        inputProps={{
          placeholder: 'Last Name',
          name: 'receiverLastName',
          value: values.receiverLastName,
          onChange: handleChange,
          onBlur: handleBlur,
        }}
        error={(touched.receiverLastName && errors.receiverLastName) || undefined}
      />
      <StyledTextInput
        inputProps={{
          placeholder: 'Email',
          name: 'receiverEmail',
          value: values.receiverEmail.toLowerCase(),
          onChange: handleChange,
          onBlur: handleBlur,
        }}
        error={(touched.receiverEmail && errors.receiverEmail) || undefined}
      />

      <Button fullWidth type="submit" marginBottom="1rem">
        {isSubmitting ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : 'Share Result'}
      </Button>
    </Form>
  </ShareFormStyle>
)

export default SharedResultForm
