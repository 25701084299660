import { Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import { Link, RouteComponentProps, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Button } from '../../shared/components/Button'
import { signUpMutation } from '../../api/components/Auth'
import { ApolloClientContext } from '../../api'
import SignUpForm from './SignUpForm'
import { NormalText } from './shared/NormalText'
import { MessageTypes } from './Login'
import SignUpLayout from './shared/SignUpLayout'

const defaultProps = {}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  username: '',
  organization: '',
}

const StyledButton = styled(Button)`
  height: auto;
  padding: 0rem 1rem;
`

type Props = typeof defaultProps & RouteComponentProps<{ code?: string }> & {}

export type Values = typeof initialValues & {
  formMessage?: string
  messageType?: MessageTypes
}

const validateSchema = () => {
  const shape = {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string()
      .min(8, 'Minimum length of 8')
      .max(256, ' Maximum length of 256')
      // Code below for check password required punctuation characters. Just for future-proof
      // .matches(
      //   /^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(?=(?:.*[!"#$%&'()*+,.\/:;<=>?@\^_`{|}~\-]))(.{8,256})$/,
      //   'Password too weak!',
      // )
      .matches(
        /^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(.{8,256})$/,
        'Password should have at least one Uppercase letter and one Numeric character',
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords do not match')
      .required('Confirm Password is required'),
    organization: Yup.string().required('Organization is required'),
  }
  return Yup.object().shape(shape)
}

const SignUp: React.FC<Props> = ({ history }) => {
  const query = new URLSearchParams(useLocation().search)
  const { client } = useContext(ApolloClientContext)
  const code = query.get('code')

  const handleSubmit = async (
    values: Values,
    { setSubmitting, setFieldValue }: FormikHelpers<Values>,
  ) => {
    try {
      await signUpMutation(client, {
        model: {
          email: values.email.toLowerCase(),
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          organization: values.organization,
          signupCode: code,
        },
      })
      setSubmitting(false)
      history.push('/auth/verify-account', {
        email: values.email,
        username: values.username,
      })
    } catch (error) {
      setFieldValue('formMessage', error.message.slice(15) || 'Something went wrong!')
      setFieldValue('messageType', 'error')
      setSubmitting(false)
    }
  }

  return (
    <SignUpLayout formEnabled={!!code}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
        render={props => <SignUpForm {...props} />}
      />
      <div>
        <NormalText>
          Already have an account?
          <StyledButton btnType="link">
            <Link to="/auth/login">Log In</Link>
          </StyledButton>
        </NormalText>
      </div>
    </SignUpLayout>
  )
}

SignUp.defaultProps = defaultProps

export default SignUp
