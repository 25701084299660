import { InfoCircleOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import Select from 'react-select'
import styled, { css } from 'styled-components'
import {
  AlternativeHeadersInput,
  ApolloClientContext,
  Mapping,
  MeData,
  requestConstructAccess,
} from '../../../api'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { SaveAsMappingButton, SaveMappingButton } from '../SaveMappingButton'
import Tooltip from '../Tooltip'
import Notify from '../Notify'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { Colors } from '../../constants'
import { MappingsSelector, MappingsSelectorOptionType } from './MappingsSelector'

type Props = {
  headers: string[]
  samples: any[]
  onProcess: (mapping: AlternativeHeadersInput, enableConstructAnalysis?: boolean) => void
}

const DEFAULT_SAMPLE = {
  Supplier: ['ARTHREX', 'ARTHREX', 'MEDTRONIC', 'ZIMMER'],
  CatalogNumber: ['AR-8943', 'AR-6592-08-30', '72204064', '42540000035'],
  Uom: ['EA', 'EA', 'BX', 'CA'],
  Price: ['104.56', '807.32', '900.00', '48.50'],
  Quantity: ['1', '1', '1', '1'],
  PONumber: ['PO-577723', 'PO-577723', '1502543694', 'PO-574739'],
}

export const ColumnMapping: React.FC<Props> = ({ headers, onProcess, samples }) => {
  const [selected, setSelected] = useState<Mapping>()
  const [enableConstructAnalyzer, setEnableConstructAnalyzer] = useState<boolean>(false)
  const [constructTooltipShown, setConstructTooltipShown] = useState<boolean>(false)
  const [currentMapping, setCurrentMapping] = useState<AlternativeHeadersInput>({})
  const { client } = useContext(ApolloClientContext)
  const tooltipRef = useRef(null)
  useOnClickOutside(tooltipRef, () => {
    setConstructTooltipShown(false)
  })

  const getFieldMappingSelectorProps = (field: keyof AlternativeHeadersInput) => ({
    options: headers
      .filter(h => !Object.values(currentMapping).includes(h))
      .map(header => ({ label: header, value: header })),
    isClearable: true,
    value:
      currentMapping && currentMapping[field]
        ? {
            label: currentMapping[field],
            value: currentMapping[field],
          }
        : undefined,
    onChange: (select: any) => {
      setCurrentMapping({
        ...currentMapping!,
        [field]: select && select.value,
      })
    },
  })
  const canProcess =
    !!currentMapping &&
    !!currentMapping.Supplier &&
    !!currentMapping.CatalogNumber &&
    (!enableConstructAnalyzer || !!currentMapping.PONumber)
  const isUpdatedMapping = canProcess && !!selected && selected.mapping !== currentMapping

  const PreviewRow: React.FC<{ data: string[] }> = ({ data }) => (
    <Flex column style={{ width: 200 }}>
      {data.map((row, index) => (
        <span
          key={index}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {row || ''}
        </span>
      ))}
    </Flex>
  )

  const FieldMappingComponent: React.FC<{
    label: string
    field: keyof AlternativeHeadersInput
    description?: string
  }> = ({ label, field, description }) => (
    <MappingRow height={84} style={{}}>
      <LabelSpan width={200}>
        {description ? (
          <Flex column>
            <span>{label}</span>
            <span style={{ fontSize: '12px', paddingTop: '5px' }}>{description}</span>
          </Flex>
        ) : (
          label
        )}
      </LabelSpan>
      <Col width={264} style={{ alignItems: 'center', display: 'flex' }}>
        <FieldSelect {...getFieldMappingSelectorProps(field)} />
      </Col>
      <PreviewRow data={DEFAULT_SAMPLE[field]} />
      <PreviewRow
        data={
          currentMapping && currentMapping[field]
            ? samples.map(sample => sample[currentMapping[field]!])
            : []
        }
      />
    </MappingRow>
  )

  return (
    <MeData>
      {({ data }) => (
        <React.Fragment>
          <ColumnMappingWrapper>
            <Row>
              <LabelSpan width={200}>Load Previous Mappings</LabelSpan>
              <StyledMappingsSelector
                value={selected && { label: selected.name, value: selected }}
                onChange={(v: MappingsSelectorOptionType) => {
                  setSelected(v.value)
                  setCurrentMapping(v.value.mapping)
                }}
              />
            </Row>
            <Row>
              <Checkbox
                disabled={!data?.me.bulkAccess || !data?.me.constructAccess}
                onChange={e => setEnableConstructAnalyzer(e.target.checked)}
              >
                Enable Construct Analysis
              </Checkbox>
              {(!data?.me.bulkAccess || !data?.me.constructAccess) && (
                <Tooltip
                  placement="top"
                  trigger="click"
                  onVisibilityChange={shown => setConstructTooltipShown(shown)}
                  tooltipShown={constructTooltipShown}
                  getTooltipRef={tooltipRef}
                  tooltip={
                    <Flex column style={{ padding: '4px' }}>
                      <p>Construct Analysis is a premium feature.</p>
                      <p>
                        Click the button below to contact our team to upgrade your subscription.
                      </p>
                      <Button
                        style={{ height: '32px', marginTop: '8px' }}
                        onClick={() => {
                          requestConstructAccess(client, {})
                            .then(() => {
                              Notify.success('Construct Analysis access requested!')
                            })
                            .catch(() => {
                              Notify.warning('Failed to send Construct access request!')
                            })
                          setConstructTooltipShown(false)
                        }}
                      >
                        Request Construct Access
                      </Button>
                    </Flex>
                  }
                  hideArrow="test"
                >
                  <HyperLink>
                    Learn More <InfoCircleOutlined />
                  </HyperLink>
                </Tooltip>
              )}
            </Row>

            <TitleRow>
              <LabelSpan width={200}>REQUIRED FIELD</LabelSpan>
              <LabelSpan width={264}>FIELD FROM YOUR FILE</LabelSpan>
              <LabelSpan width={200}>SAMPLE</LabelSpan>
              <LabelSpan width={200}>PREVIEW FROM YOUR FILE</LabelSpan>
            </TitleRow>

            <FieldMappingComponent label="SUPPLIER" field="Supplier" />
            <FieldMappingComponent label="PART / CATALOG NUMBER" field="CatalogNumber" />
            <FieldMappingComponent label="UNIT OF MEASURE (UOM)" field="Uom" />
            <FieldMappingComponent label="PRICE" field="Price" />
            <FieldMappingComponent label="QUANTITY" field="Quantity" />
            {enableConstructAnalyzer && (
              <FieldMappingComponent
                label="PO Number"
                field="PONumber"
                description="* Mapping this field is required to get Constructs data"
              />
            )}
          </ColumnMappingWrapper>
          <ColumnMappingActionsWrapper>
            <Row width="464px">
              <LabelSpan width={200}>Mapping Actions</LabelSpan>
              <Flex>
                <SaveMappingButton
                  id={selected ? selected.id : 0}
                  disabled={!isUpdatedMapping}
                  mapping={currentMapping}
                  marginRight="16px"
                />
                <SaveAsMappingButton mapping={currentMapping} disabled={!canProcess} />
              </Flex>
            </Row>
            <Button
              disabled={!canProcess}
              onClick={() => onProcess(currentMapping, enableConstructAnalyzer)}
            >
              Process
            </Button>
          </ColumnMappingActionsWrapper>
        </React.Fragment>
      )}
    </MeData>
  )
}

// const HorizontalDivider = styled.div`
//   width: 90%;
//   border-bottom: lightgrey 1px solid;
// `

const ColumnMappingWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  margin-bottom: 32px;
  flex: 1;
`

const ColumnMappingActionsWrapper = styled(Flex)`
  height: 48px;
  width: 95%;
  justify-content: space-between;
`

const LabelSpan = styled.div<{ width?: number }>`
  text-align: left;

  ${props =>
    props.width
      ? css`
          width: ${props.width}px;
        `
      : ''}
`

const FieldSelect = styled(Select)`
  width: 248px;
`

const StyledMappingsSelector = styled(MappingsSelector)`
  width: 248px;
  margin-bottom: 16px;
`

const Row = styled(Flex)<{ height?: number; width?: string }>`
  align-items: center;
  width: ${props => props.width || '100%'};
  padding: 8px;
  ${props =>
    props.height
      ? css`
          height: ${props.height}px;
        `
      : ''}
`

const MappingRow = styled(Row)`
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  color: #416a7a;
  font-weight: 500;
  gap: 8px;
`

const TitleRow = styled(Row)`
  color: #416a7a;
  font-weight: 500;
  border: 1px solid #416a7a;
  margin-bottom: 16px;
  background: aliceblue;
`

const Col = styled.div<{ width?: number }>`
  height: 100%;
  ${props =>
    props.width
      ? css`
          width: ${props.width}px;
        `
      : ''}
`
const HyperLink = styled('a')`
  color: ${Colors.primary};

  &:hover {
    text-decoration: underline;
  }
`
