import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import numeral from 'numeral'
import { SearchItem, ApolloClientContext, SearchQueryInput } from '../../api'
import { Flex } from '../../shared/components/Flex'
import { Separator } from '../Home/shared/Separator'
import { FontWeight, Colors } from '../../shared/constants'
import Notify from '../../shared/components/Notify'
import reportIcon from '../../assets/img/report.svg'
import linkIcon from '../../assets/img/link.svg'
import useMixpanel from '../../hooks/useMixpanel'
import Env from '../../api/gen/Env'
import { MixPanelEvent } from '../../shared/Mixpanel'
import { Modal } from '../../shared/components/Modal'
import { Button } from '../../shared/components/Button'
import { sendBadDataReportMutation } from '../../api/components/Search'
import ExpandedDetails from './ExpandedDetails'

const Wrapper = styled(Flex)`
  border-radius: 2px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 32px;
  }
  border-bottom: 3px solid transparent;
  transition: border 0.1s ease-in-out;
  &:hover {
    border-color: ${Colors.primary};
  }
`

const FloatingTooltipWrapper = styled.div`
  position: relative;
`

const FloatingTooltip = styled(Flex)`
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 8px 12px;
  width: 120px;
  span {
    font-weight: 600;
  }
  div {
    margin: 4px;
  }
  display: none;
  position: absolute;
  left: -60px;
  top: 12px;
  font-size: 12px;
  font-weight: ${FontWeight.medium};
  text-transform: none;
`

const SkuTooltip = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 8px 12px;
  width: max-content;
  span {
    font-weight: 600;
  }
  div {
    margin: 4px;
  }
  display: none;
`

const BadDataIcon = styled(Flex)`
  padding: 0 10px;
  display: none;
  position: absolute;
  &:hover {
    ${SkuTooltip} {
      left: 10px;
      bottom: 100%;
      width: fit-content;
      display: block;
      font-size: 12px;
      font-weight: ${FontWeight.medium};
      padding: 5px;
    }
  }
`
const BadDataReport = styled(BadDataIcon)`
  top: 4px;
`
const BadDataWrapper = styled.div`
  padding: 0 10px;
  display: block;
  height: 100%;
  margin-left: 10px;
`
const BadDataLink = styled(BadDataIcon)<{ selected?: boolean }>`
  display: ${props => (props.selected ? 'block' : 'none')};
  bottom: 4px;
`
const ItemDetails = styled(Flex)`
  cursor: pointer;
  width: 100%;
  padding: 12px 20px;
  background: #fff;
  position: relative;

  &:hover {
    ${BadDataReport} {
      display: block;
    }
    ${BadDataLink} {
      display: block;
    }
  }
`

const ProductNo = styled(Flex)`
  font-weight: ${FontWeight.bold};
  font-size: 0.95vw;
  color: #17232c;
  span {
    color: ${Colors.primary};
    font-weight: ${FontWeight.medium};
    font-size: 0.75vw;
    margin-bottom: 5px;
  }
`
const BenchMark = styled(Flex)`
  min-width: 60px;
  height: 44px;
`
const BenchMarkTitle = styled(Flex)`
  font-size: 0.75vw;
  font-weight: ${FontWeight.medium};
  text-transform: uppercase;
  color: #6c8494;
  margin-bottom: 5px;
  height: 13px;
  &:hover {
    ${FloatingTooltip} {
      display: inline-block;
      font-size: 12px !important;
      font-weight: ${FontWeight.medium};
    }
  }
`
const BenchMarkValue = styled(Flex)`
  font-weight: ${FontWeight.bold};
  text-transform: uppercase;
  color: #17232c;
  span {
    font-size: 0.7vw;
    font-weight: ${FontWeight.medium};
    margin-top: 2px;
  }
  font-size: 0.85vw;
`
const WrappedProductNo = styled(ProductNo)`
  position: relative;
  &:hover {
    ${SkuTooltip} {
      display: block;
    }
  }
`
const Description = styled(Flex)`
  padding-left: 20px;
  color: #6c8494;
  font-size: 0.95vw;

  @media (max-width: 1024px) {
    display: none;
  }
`
const ProductWrapper = styled(Flex)`
  flex: 0 0 20%;

  @media (max-width: 1024px) {
    flex-basis: 30%;
  }
`
const BenchmarkWrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  flex: 0 0 50%;
  justify-content: space-around;

  @media (max-width: 1024px) {
    flex-basis: 70%;
  }
`

const Textarea = styled('textarea')`
  outline: none;
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  height: 300px;
  padding: 8px;
  color: ${props => (props.disabled ? Colors.textSecondary : 'inherit')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`

const ModalContent = styled('div')`
  width: 500px;
  height: 400px;
  background: #fff;
  padding: 16px;
  align-items: center;
  justify-content: center;
`

const ReportButton = styled(Button)`
  margin-top: 16px;
  width: 20%;
  margin-left: 40%;
`

const formatMoney = (amount: number, isVolume: boolean): string =>
  numeral(amount).format(isVolume ? '0,0' : '0,0.00')

const diplayMoney = (amount: number | undefined | null, isVolume = false) => {
  if (!amount) {
    return ''
  }
  return (
    <>
      <span>$</span> {formatMoney(amount, isVolume)}
    </>
  )
}

type Props = {
  data: SearchItem
  isExpanded: boolean
  setExpandedIndex: (index: number | undefined) => void
  index: number
  linked?: boolean
  onToggleLinked?: () => void
  searchQuery: SearchQueryInput
}

const ResultItem: React.FC<Props> = ({
  data,
  isExpanded,
  setExpandedIndex,
  index,
  linked,
  onToggleLinked,
  searchQuery,
}) => {
  const { showPriceSpray } = useFlags()
  const { client } = useContext(ApolloClientContext)

  const showSkuTooltip = data.product.number && data.product.number.length > 11
  const isHospitalCountGTE = (amount: number): boolean => data!.benchmark!.hospitalCount! >= amount
  const mixpanel = useMixpanel()
  const [isShowReportModal, setIsShowReportModal] = useState(false)
  const [reportContent, setReportContent] = useState('')

  const graphData =
    data.benchmark.priceSpray &&
    data.benchmark.priceSpray.reduce(
      (acc, curr) => {
        const prices: number[] = []
        const qtys: number[] = []
        if (!curr || !curr.priceSpray) {
          return acc
        }
        curr.priceSpray.forEach(item => {
          // const existingPriceIndex = acc.y.indexOf(item.unitprice);
          // if (existingPriceIndex !== -1) {
          //   acc.x[existingPriceIndex] += item.total_qty_purchase;
          // } else {
          if (
            !item ||
            !item.unitprice ||
            !item.totalQtyPurchase ||
            (data.benchmark.iqo90 && item.unitprice < data.benchmark.iqo90)
          ) {
            return
          }
          prices.push(item.unitprice)
          qtys.push(item.totalQtyPurchase)
          // }
        })
        return { x: [...acc.x, ...prices], y: [...acc.y, ...qtys] }
      },
      { x: [], y: [] } as { x: number[]; y: number[] },
    )

  const sendReport = () => {
    sendBadDataReportMutation(client, {
      data: {
        partNumber: data.product.stripped,
        supplier: data.supplier.normalized,
        uom: data.product.uom,
        content: reportContent,
      },
    })
    setIsShowReportModal(false)
    Notify.success('Your report has been sent to the support team')
  }

  return (
    <Wrapper fullWidth column align="center">
      <ItemDetails
        onClick={() => {
          if (Env.useMixpanel) {
            mixpanel.track(MixPanelEvent.itemExpand, {
              supplier: data.supplier.normalized,
              sku: data.product.number,
              uom: data.product.uom,
              searchQuery,
            })
          }
          setExpandedIndex(!isExpanded ? index : undefined)
        }}
      >
        <ProductWrapper>
          <WrappedProductNo column flex={2}>
            <span>{data.supplier.normalized}</span>
            {showSkuTooltip ? (
              <>
                {data.product.number && data.product.number}
                <SkuTooltip>{data.product.number}</SkuTooltip>
              </>
            ) : (
              <>{data.product.number || 'N/A'}</>
            )}
          </WrappedProductNo>
          <Flex flex={1} paddingLeft={20} justify="space-around">
            <BenchMark column>
              <BenchMarkTitle>UOM</BenchMarkTitle>
              <BenchMarkValue>
                {data.product.uom ? data.product.uom : 'N/A'}
                {data.conversion && data.product.uom && data.product.uom.toLowerCase() !== 'ea'
                  ? ` / ${data.conversion}`
                  : ''}
              </BenchMarkValue>
            </BenchMark>
          </Flex>
          <Separator type="vertical" size={32} color="#E0E7ED" />
        </ProductWrapper>
        <Description flex="0 0 25%" justify="space-between">
          {data.description || 'N/A'}
          <Separator type="vertical" size={32} color="#E0E7ED" />
        </Description>
        {!data.benchmark.hospitalCount || data.benchmark.hospitalCount <= 1 ? (
          <BenchmarkWrapper>
            <Description>
              <strong>Low Volume</strong> : No pricing information available
            </Description>
          </BenchmarkWrapper>
        ) : (
          <BenchmarkWrapper>
            <BenchMark flex="0 0 15%" column align="flex-end">
              <BenchMarkTitle>Volume</BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.totalSpend, true)}</BenchMarkValue>
            </BenchMark>
            <BenchMark column align="flex-end">
              <BenchMarkTitle>Facilities</BenchMarkTitle>
              <BenchMarkValue>{data.benchmark.hospitalCount}</BenchMarkValue>
            </BenchMark>
            <Separator type="vertical" size={32} color="#E0E7ED" />
            <BenchMark column align="flex-end">
              <BenchMarkTitle>25th</BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.iqo25)}</BenchMarkValue>
            </BenchMark>
            <BenchMark column align="flex-end">
              <BenchMarkTitle>50th</BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.iqo50)}</BenchMarkValue>
            </BenchMark>
            <BenchMark column align="flex-end">
              <BenchMarkTitle>75th</BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.iqo75)}</BenchMarkValue>
            </BenchMark>
            <BenchMark column align="flex-end">
              <BenchMarkTitle>90th</BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.iqo90)}</BenchMarkValue>
            </BenchMark>
            <Separator type="vertical" size={32} color="#E0E7ED" />
            <BenchMark column align="flex-end">
              <BenchMarkTitle>
                ASP
                <FloatingTooltipWrapper>
                  <FloatingTooltip>
                    <span>
                      Average Selling Price is the weighted average of purchases across the market
                      for the last 18 months
                    </span>
                  </FloatingTooltip>
                </FloatingTooltipWrapper>
              </BenchMarkTitle>
              <BenchMarkValue>{diplayMoney(data.benchmark.averagePrice)}</BenchMarkValue>
            </BenchMark>
          </BenchmarkWrapper>
        )}
        <BadDataWrapper>
          <BadDataReport>
            <img
              src={reportIcon}
              alt=""
              width="20px"
              onClick={e => {
                e.stopPropagation()
                setIsShowReportModal(true)
              }}
            />
            <SkuTooltip>Report</SkuTooltip>
          </BadDataReport>
          <BadDataLink selected={linked}>
            <img
              src={linkIcon}
              alt="link icon"
              width="20px"
              onClick={e => {
                if (Env.useMixpanel) {
                  mixpanel.track(MixPanelEvent.itemLinkIcon, {
                    linked,
                    supplier: data.supplier.normalized,
                    sku: data.product.number,
                    uom: data.product.uom,
                    searchQuery,
                  })
                }

                e.stopPropagation()
                if (onToggleLinked) {
                  onToggleLinked()
                }
              }}
            />
            <SkuTooltip>Link identical items for correction</SkuTooltip>
          </BadDataLink>
        </BadDataWrapper>
      </ItemDetails>
      {showPriceSpray && isExpanded && graphData && (
        <ExpandedDetails
          graphData={graphData}
          isHospitalCountGTE={isHospitalCountGTE}
          gudid={data.gudid}
          data={data}
          searchQuery={searchQuery}
          benchmark={data.benchmark}
          /*transactionsList={data.transactionsList || []}*/
        />
      )}
      <Modal
        blackOverlay
        shouldCloseOnEsc
        shouldCloseOnClickOutside
        isOpen={isShowReportModal}
        onRequestClose={() => setIsShowReportModal(false)}
      >
        <ModalContent>
          <Textarea
            placeholder="Write you report..."
            onChange={e => setReportContent(e.target.value)}
          />
          <ReportButton onClick={sendReport}>Send</ReportButton>
        </ModalContent>
      </Modal>
    </Wrapper>
  )
}

export default ResultItem
