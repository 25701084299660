import React, { useContext, useState } from 'react'
import { AlternativeHeadersInput, ApolloClientContext } from '../../api'
import {
  updateMappingMutation,
  createMappingMutation,
  MAPPINGS_QUERY,
  deleteMappingMutation,
} from '../../api/components/File'
import { useToggle } from '../../hooks/useToggle'
import LoadingIndicator from './Spinner'
import { Button, BtnProps } from './Button'
import { Modal } from './Modal'
import TextInput from './Inputs/TextInput'
import { Flex } from './Flex'

type Props = {
  id: number
  name?: string
  mapping?: AlternativeHeadersInput
} & BtnProps
const SaveButton: React.FC<
  { loading?: boolean; text: string } & React.ButtonHTMLAttributes<{}>
> = ({ loading, text, ...props }) => (
  <Button style={{ flex: 1 }} {...props}>
    {loading ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : text}
  </Button>
)
export const SaveMappingButton: React.FC<Props> = ({ id, name, mapping, ...props }) => {
  const { client } = useContext(ApolloClientContext)
  const [loading, setIsLoading] = useState(false)
  const onSaveMapping = () => {
    setIsLoading(true)
    updateMappingMutation(client, {
      id,
      input: {
        name,
        ...mapping,
      },
    }).finally(() => setIsLoading(false))
  }
  return <SaveButton text="Save" loading={loading} onClick={onSaveMapping} {...props} />
}

type SaveAsProps = {
  mapping?: AlternativeHeadersInput
} & BtnProps
export const SaveAsMappingButton: React.FC<SaveAsProps> = ({ mapping, ...props }) => {
  const { client } = useContext(ApolloClientContext)
  const [loading, setIsLoading] = useState(false)
  const { on, off, toggle } = useToggle(false)
  const [name, setName] = useState<string>('')
  const onSaveAsMapping = () => {
    off()
    setIsLoading(true)
    createMappingMutation(
      client,
      {
        input: {
          name,
          ...mapping,
        },
      },
      {
        refetchQueries: [{ query: MAPPINGS_QUERY }],
      },
    ).finally(() => setIsLoading(false))
  }

  return (
    <React.Fragment>
      <Modal
        onRequestClose={() => {
          off()
        }}
        isOpen={on}
        shouldCloseOnEsc
        shouldCloseOnClickOutside
        blackOverlay
      >
        <Flex style={{ padding: '16px', background: '#fff' }} column>
          <TextInput
            inputProps={{
              placeholder: 'New mapping name',
              value: name,
              onChange: v => setName(v.target.value),
              autoFocus: true,
            }}
            style={{ marginBottom: '16px' }}
          />
          <Button fullWidth onClick={onSaveAsMapping}>
            Save
          </Button>
        </Flex>
      </Modal>

      <SaveButton text="Save As" loading={loading} onClick={toggle} {...props} />
    </React.Fragment>
  )
}
export const DeleteMappingButton: React.FC<{ id?: number } & BtnProps> = ({ id, ...props }) => {
  const { client } = useContext(ApolloClientContext)
  const [loading, setLoading] = useState(false)
  const onDelete = () => {
    if (id === null || id === undefined) {
      return
    }
    setLoading(true)
    deleteMappingMutation(client, { id }, { refetchQueries: [{ query: MAPPINGS_QUERY }] }).finally(
      () => setLoading(false),
    )
  }
  return <SaveButton text="Delete" {...props} onClick={onDelete} loading={loading} />
}
