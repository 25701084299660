import React, { useState } from 'react'
import { SearchCollection, SearchQueryInput, SearchTypeEnum } from '../../api'
import { SearchData } from '../../api/components/Search'
import useMixpanel from '../../hooks/useMixpanel'
import { MixPanelEvent } from '../../shared/Mixpanel'
import Env from '../../api/gen/Env'
import ResultList from './ResultList'

type Props = {
  searchQuery: SearchQueryInput
}

export const NUMBER_PER_PAGE = 12

const ResultListContainer: React.FC<Props> = ({ searchQuery }) => {
  const [offset, setOffset] = useState(0)
  const [sort, setSort] = useState<any>({ field: 'curvo_score', asc: false })
  // const [sort, setSort] = useState()
  const mixpanel = useMixpanel()

  const cleanSearchQuery: SearchQueryInput = Object.keys(searchQuery).reduce((previous, key) => {
    if (searchQuery[key as keyof SearchQueryInput] !== undefined) {
      return { ...previous, ...{ [key]: searchQuery[key as keyof SearchQueryInput] } }
    }

    return { ...previous }
  }, searchQuery)

  const trackMixPanel = (data: SearchCollection) => {
    if (!searchQuery) {
      return
    }

    let type = ''
    if (searchQuery.type) {
      type = searchQuery.type.toLowerCase()
    }

    let searchText = ''
    let multi = false
    if (searchQuery.searchText) {
      searchText = searchQuery.searchText
      if (searchQuery.searchText.includes(' ')) {
        multi = true
      }
    }

    let suggestion = false
    if (searchQuery.type === SearchTypeEnum.Part && searchQuery.uom) {
      suggestion = true
    }

    const mixpanelData = {
      searchText,
      type,
      multi,
      suggestion,
      resultsNumber: data.info.total,
      searchQuery: cleanSearchQuery,
    }

    if (Env.useMixpanel) {
      mixpanel.track(MixPanelEvent.searches, mixpanelData)
    }
  }

  return Object.entries(searchQuery).length > 0 ? (
    <SearchData
      variables={{ query: searchQuery, pagination: { offset, limit: NUMBER_PER_PAGE }, sort: sort }}
    >
      {({ data, loading }) => {
        // Mixpanel for searches
        if (data && data.search) {
          trackMixPanel(data.search)
        }
        return (
          <ResultList
            offset={offset}
            setOffset={setOffset}
            data={data && data.search}
            loading={loading}
            setSortOption={setSort}
            searchQuery={cleanSearchQuery}
          />
        )
      }}
    </SearchData>
  ) : (
    <></>
  )
}

export default ResultListContainer
