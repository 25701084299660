import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { SearchQueryInput, SearchFilter, FilterItem } from '../../api'
import { Flex } from '../../shared/components/Flex'
import { FontWeight, Colors } from '../../shared/constants'
import LoadingIndicator from '../../shared/components/Spinner'
import { SideBarItem } from './Sidebar'
import SearchFilterItem from './SearchFilterItem'

// amount of items to show in sidebar
const ITEM_COUNT = 3

export const Item = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  user-select: none;
  width: 100%;
  cursor: pointer;
  padding: 8px 8px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background: #f2f4f7;
  }
`

const Wrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  min-height: 68px;
  max-height: 464px;
  overflow-y: auto;
`

const Title = styled('div')`
  margin-bottom: 16px;
  color: #344755;
  padding-left: 8px;
  font-size: 16px;
  font-weight: ${FontWeight.bold};
`

const StyledLoadingIndicator = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#fff', 0.5)};
  justify-content: center;
  align-items: center;
`

const ShowMoreWrapper = styled(Item)`
  color: #f86b4f;
  position: sticky;
  bottom: 0;
  background: #fafafc;
  margin-top: 8px;
  padding-left: 8px;
  justify-content: flex-start;

  &:hover {
    background: #f2f4f7;
  }
`

type Props = {
  data: SearchFilter | undefined
  item: SideBarItem
  selectedItems: SearchQueryInput | undefined
  setSearchQuery?: (input: SearchQueryInput) => void
  loading?: boolean
}

const SidebarList: React.FC<Props> = ({ data, item, selectedItems, setSearchQuery, loading }) => {
  const [shouldShowMore, setShouldShowMore] = useState(false)

  const searchFilterList = useMemo(() => {
    let result
    if (data) {
      result = data[item.category as keyof SearchFilter]
    } else {
      result = []
    }

    return result
  }, [data, item.category])

  const displayedData: FilterItem[] = useMemo(() => {
    if (Array.isArray(searchFilterList)) {
      return shouldShowMore ? searchFilterList : searchFilterList.slice(0, ITEM_COUNT)
    } else {
      return []
    }
  }, [searchFilterList, shouldShowMore])

  return (
    <Wrapper column>
      <>
        <Title>{item.title}</Title>
        {loading && (
          <StyledLoadingIndicator>
            <LoadingIndicator center color={Colors.primary} />
          </StyledLoadingIndicator>
        )}
        {data &&
          (!displayedData.length ? (
            <span style={{ color: '#516A7A', paddingLeft: '8px' }}>No data</span>
          ) : (
            displayedData.map((searchFilterItem, index: number) => {
              return (
                <SearchFilterItem
                  key={index}
                  item={searchFilterItem}
                  setSearchQuery={setSearchQuery}
                  selectedItems={selectedItems}
                  category={item.category}
                  loading={loading}
                  total={data.totalResults}
                />
              )
            })
          ))}
        {searchFilterList &&
          Array.isArray(searchFilterList) &&
          searchFilterList.length > ITEM_COUNT && (
            <ShowMoreWrapper onClick={() => setShouldShowMore(!shouldShowMore)} fullWidth>
              Show {shouldShowMore ? 'less' : 'more'}
            </ShowMoreWrapper>
          )}
      </>
    </Wrapper>
  )
}

export default SidebarList
