import React, { useContext } from 'react'
import * as Yup from 'yup'
import { RouteComponentProps, Link } from 'react-router-dom'
import { Formik, FormikHelpers } from 'formik'
import { CognitoContext } from '../../api'
import { Button } from '../../shared/components/Button'
import { NormalText } from './shared/NormalText'
import ForgotPasswordForm from './ForgotPasswordForm'
import { MessageTypes } from './Login'
import AuthLayout from './shared/AuthLayout'

const defaultProps = {}

const initialValues = {
  email: '',
  sentCode: false,
  code: '',
  password: '',
  confirmPassword: '',
}

type Props = Partial<typeof defaultProps> & RouteComponentProps & {}

export type Values = typeof initialValues & {
  formMessage?: string
  messageType?: MessageTypes
}

const validationSchema = () => {
  const shape = {
    email: Yup.string().email('Email is not valid!').required('Email is required!'),
    sentCode: Yup.bool(),
    code: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string().required('Code is required!'),
    }),
    password: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string()
        .min(8, 'Minimum length of 8')
        .max(256, ' Maximum length of 256')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password is too weak! Your password is required to contain Uppercase & Lowercase, a Numeric character & a Special character.',
        )
        .required('Password is required'),
    }),
    confirmPassword: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords do not match.')
        .required('Confirm Password is required'),
    }),
  }
  return Yup.object().shape(shape)
}

const ForgotPassword: React.FC<Props> = ({ history }) => {
  const { forgotPassword, confirmPassword } = useContext(CognitoContext)
  const handleSubmit = async (
    values: Values,
    { setSubmitting, setFieldValue }: FormikHelpers<Values>,
  ) => {
    try {
      if (!values.sentCode) {
        await forgotPassword(values.email.toLowerCase())
        setFieldValue('sentCode', true)
        setSubmitting(false)
        return
      }
      await confirmPassword(values.email.toLowerCase(), values.code, values.password)
      setFieldValue('messageType', 'success')
      setSubmitting(false)
      history.push('auth/login', {
        message: 'Your password has been changed successfully!',
        type: 'success',
        email: values.email,
      })
    } catch (error) {
      console.error(error)
      setFieldValue('formMessage', error ? error.message.slice(15) : 'Something went wrong!')
      setFieldValue('messageType', 'error')
      setSubmitting(false)
    }
  }

  return (
    <AuthLayout>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        render={props => <ForgotPasswordForm {...props} />}
      />
      <NormalText size="0.75rem">
        Already have account?{' '}
        <Button btnType="link">
          <Link to="/auth/signin">Sign In</Link>
        </Button>{' '}
        <Button btnType="link">
          <Link to="/auth/signup">Create new account</Link>
        </Button>
      </NormalText>
    </AuthLayout>
  )
}

ForgotPassword.defaultProps = defaultProps

export default ForgotPassword
