import styled, { css } from 'styled-components'
import { Rotate360 } from '../Spinner'

export const StyledUploadIcon = styled('img')<{ uploading?: boolean }>`
  ${props =>
    props.uploading &&
    css`
      animation: ${Rotate360} 1s infinite linear;
    `};
`
