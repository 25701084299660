import React from 'react'
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { MeData, RoleEnum } from '../../api'
import AdminLayout from './AdminLayout'
import User from './User'
import InviteAdmin from './Invite/InviteAdmin'

type Props = {} & RouteComponentProps

const Admin: React.FC<Props> = ({ history }) => (
  <MeData>
    {({ loading, data }) => {
      if (loading) {
        return null
      }
      if (!data) {
        return null
      }
      const { me } = data
      if (me.role !== RoleEnum.Admin) {
        history.push('/')
        return null
      }
      return (
        <AdminLayout>
          <Switch>
            <Route path="/admin/user" component={User} />
            <Route path="/admin/invite" component={InviteAdmin} />
            <Redirect to="/admin/user" />
          </Switch>
        </AdminLayout>
      )
    }}
  </MeData>
)

export default withRouter(Admin)
