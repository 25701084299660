import React, { ReactElement } from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { Ref, TooltipTriggerProps } from 'react-popper-tooltip/dist/types'

type Props = {
  children: ReactElement
  tooltip: string | ReactElement
  hideArrow: string
  placement: TooltipTriggerProps['placement']
  trigger: TooltipTriggerProps['trigger']
  tooltipShown?: boolean
  onVisibilityChange?: (tooltipShown: boolean) => void
  getTooltipRef?: Ref
}

const Tooltip: React.FC<Props> = ({ children, tooltip, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container',
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement,
            })}
          />
        )}
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: 'trigger',
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
)
export default Tooltip
