import React, { RefObject, useRef, useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { useToggle } from '../../../hooks/useToggle'
import { BorderRadius, Colors, zIndex, FontWeight } from '../../constants'
import { Flex } from '../Flex'
import { User, CognitoContext, RoleEnum } from '../../../api'

const StyledProfile = styled(Flex)`
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #919da9;
  font-size: 16px;
  font-weight: ${FontWeight.medium};
`

const Avatar = styled(Flex)`
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #7d8eb3;
  margin-right: 8px;
  color: #ffffff;
`

const StyledProfileMenu = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: #2b3240;
  z-index: ${zIndex.dropdown};
  min-width: 120px;
  border-radius: ${BorderRadius.normal};
`

const Item = styled(Flex)`
  user-select: none;
  flex: 1;
  cursor: pointer;
  padding: 8px 20px;

  &:hover {
    color: ${Colors.primary};
  }
`
export const initials = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
): string => {
  const firstInitial = firstName ? firstName[0] : ''
  const lastInitial = lastName ? lastName[0] : ''
  return (firstInitial + lastInitial).toUpperCase()
}

const ProfileMenu: React.FC<
  {
    isAdmin: boolean
    on: boolean
    off: () => void
    parentRef: RefObject<HTMLDivElement>
  } & Partial<RouteComponentProps>
> = ({ history, on, off, parentRef, isAdmin }) => {
  const ref = useRef(null)

  const { logOut } = useContext(CognitoContext)

  useOnClickOutside(ref, e => {
    if (
      e.target &&
      parentRef.current &&
      !parentRef.current.contains(e.target as HTMLElement) &&
      on
    ) {
      off()
    }
  })

  return (
    <StyledProfileMenu ref={ref}>
      {isAdmin && <Item onClick={() => history && history.push('/admin')}>Admin</Item>}
      <Item onClick={() => history && history.push('/profile')}>Profile</Item>
      <Item onClick={() => history && history.push('/manage-mappings')}>Manage Mappings</Item>
      <Item onClick={() => logOut()}>Logout</Item>
    </StyledProfileMenu>
  )
}

type Props = RouteComponentProps & {
  data: User
}

const Profile: React.FC<Props> = ({ history, data }) => {
  const parentRef = useRef(null)
  const { on, off, toggle } = useToggle(false)

  return (
    <StyledProfile ref={parentRef} onClick={() => toggle()}>
      <Avatar align="center" justify="center">
        {initials(data.firstName, data.lastName)}
      </Avatar>
      <span>
        {data.firstName} {data.lastName}
      </span>
      {on && (
        <ProfileMenu
          history={history}
          parentRef={parentRef}
          on={on}
          off={off}
          isAdmin={data.role === RoleEnum.Admin}
        />
      )}
    </StyledProfile>
  )
}

export default withRouter(Profile)
