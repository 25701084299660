import { Formik, FormikHelpers } from 'formik'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from '../../../shared/components/Button'
import { Flex } from '../../../shared/components/Flex'
import TextInput from '../../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../../shared/components/Spinner'
import { PageTitle } from '../../Auth/shared/PageTitle'
import { User, ApolloClientContext, manageUserInfo } from '../../../api'
import Notify from '../../../shared/components/Notify'

const Wrapper = styled(Flex)`
  padding: 32px;
  background: #fff;
  width: 700px;
  border-radius: 4px;
`

const Form = styled('form')`
  width: 100%;
`

type Props = {
  user: User
  close: () => void
}

const UserInfoModal: React.FC<Props> = ({ user, close }) => {
  const { client } = useContext(ApolloClientContext)
  const initialValues = {
    email: user.email,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
  }

  const handleSave = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>,
  ) => {
    setSubmitting(true)
    await manageUserInfo(client, {
      userId: user.id,
      model: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    })
    Notify.success('Updated User Info successfully!')
    close()
    setSubmitting(false)
  }
  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSave}>
        {({ values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <PageTitle>Edit Info</PageTitle>
            <Flex fullWidth column marginTop={32}>
              <TextInput
                inputProps={{
                  placeholder: 'Email',
                  name: 'email',
                  value: values.email,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  disabled: true,
                }}
                error={(touched.email && errors.email) || undefined}
                style={{ marginBottom: 24 }}
              />
              <Flex fullWidth>
                <TextInput
                  inputProps={{
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: values.firstName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={(touched.firstName && errors.firstName) || undefined}
                  style={{ marginBottom: 24, flex: 1, marginRight: 16 }}
                />
                <TextInput
                  inputProps={{
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: values.lastName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={(touched.lastName && errors.lastName) || undefined}
                  style={{ marginBottom: 24, flex: 1 }}
                />
              </Flex>
              <Button fullWidth type="submit" disabled={isSubmitting}>
                {isSubmitting ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : 'Save'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default UserInfoModal
