import { gql } from 'apollo-boost'
import { mutateFunction } from '../common/Utils'
import { CreateUserInput, Mutation, VerifyAccountInput } from '../gen/Models'

export const signUpMutation = mutateFunction<Pick<Mutation, 'signUp'>, { model: CreateUserInput }>({
  mutation: gql`
    mutation Signup($model: CreateUserInput!) {
      signUp(model: $model) {
        id
      }
    }
  `,
})

export const verifyEmailMutation = mutateFunction<
  Pick<Mutation, 'verifyAccount'>,
  { args: VerifyAccountInput }
>({
  mutation: gql`
    mutation VerifyAccount($args: VerifyAccountInput!) {
      verifyAccount(args: $args) {
        id
      }
    }
  `,
})
