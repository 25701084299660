import styled from 'styled-components'
import { FontSize, FontWeight, Colors } from '../../../shared/constants'

export const RecentTitle = styled('span')`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.medium};
  text-transform: uppercase;
  color: ${Colors.textSecondary};
  margin-bottom: 8px;
  padding-left: 12px;
`
