import { ApolloError } from 'apollo-boost/lib/index'
import { GraphQLError } from 'graphql'

export class ApiError extends Error {
  readonly cause: Error

  readonly data: any

  constructor(message: string, src: Error, data?: any) {
    super(message)
    this.cause = src
    this.data = data
  }
}

export type ErrorCallback = (e: ApiError) => void

export function wrapError(error: Error, data?: any): ApiError {
  const e = error as ApiError
  if (e.cause) {
    return e
  }
  return new ApiError(error.message, error, data)
}

export function parseGQLErrors(
  errors: readonly GraphQLError[],
  callback?: ErrorCallback,
): ApiError {
  const err = errors[0]
  const parse = wrapError(err.originalError || err, err.extensions && err.extensions.exception)
  if (callback) {
    callback(parse)
    return parse
  }
  throw parse
}

export function parseError(error: Error): ApiError {
  const graphQl = error as ApolloError
  if (graphQl.graphQLErrors) {
    return parseGQLErrors(graphQl.graphQLErrors, () => {})
  }
  return wrapError(error)
}
