export const FontSize = {
  xxSmall: '9px',
  xSmall: '10px',
  small: '12px',
  medium: '14px',
  large: '16px',
  xLarge: '18px',
  xxLarge: '24px',
  xxxLarge: '32px',
}

export const FontWeight = {
  ultraLight: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  heavy: 800,
}

export const Colors = {
  whiteBackground: '#fff',
  textPrimary: '#FFFFFF',
  textSecondary: '#6c8494',
  textBlue: '#1E93F5',
  /* Brand */
  primary: '#F86B4F',
  secondary: '#6C8494',

  danger: '#EB4E61',
  warning: '#f08a24',
  success: '#3FA645',
  info: '#a0d3e8',
}

export const BorderRadius = {
  normal: '4px',
  large: '8px',
}

export const zIndex = {
  back: -1,
  normal: 0,
  front: 1,
  dropdown: 10,
  navigation: 20,
  fullscreen: 30,
  specialCase: 40,
  modal: 50,
  notification: 60,
}

export const ShortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
