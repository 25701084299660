import styled, { css } from 'styled-components'
import { Flex } from '../../../shared/components/Flex'

export const Separator = styled(Flex)<{
  size?: number
  type?: 'horizontal' | 'vertical'
  color?: string
}>`
  ${props => {
    if (props.type === 'horizontal') {
      return css`
        width: ${props.size ? `${props.size}px` : '100%'};
        height: 1px;
      `
    }
    return css`
      height: ${props.size ? `${props.size}px` : '16px'};
      width: 1px;
    `
  }};
  background: ${props => props.color || '#c6d6e1'};
`
