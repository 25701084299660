// @ts-ignore
import mixpanel from 'mixpanel-browser'
import React from 'react'
import ReactGA from 'react-ga'
// @ts-ignore
import { MixpanelProvider } from 'react-mixpanel'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { ApolloClientProvider, CognitoProvider } from './api'
import Admin from './routes/Admin'
import Auth from './routes/Auth'
import AuthorizedRoute from './routes/Auth/AuthorizedRoute'
import Home from './routes/Home'
import UserProfile from './routes/UserProfile'
import GlobalStyles from './shared/styles/globals'
import { Actions } from './state/Actions'
import { StateProvider } from './state/State'
import SharedResult from './routes/SharedResult'
import Env from './api/gen/Env'
import SearchResult from './routes/SearchResult'
import { ManageMapping } from './routes/ManageMapping/ManageMapping'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

// const MIXPANEL_STAGING_TOKEN = '36a614ab44c2c688998dc0ae482e054c'
// const MIXPANEL_DEV_TOKEN = '31d71fcae191b782ae6a9e337db073fd'

if (Env.useMixpanel) {
  mixpanel.init(Env.useMixpanel)
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-38860106-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const App: React.FC = () => {
  const initialState = {
    searchTerm: '',
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case Actions.ChangeSearchTerm:
        return { searchTerm: action.payload }
      default:
        return state
    }
  }

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <CognitoProvider>
        <StateProvider initialState={initialState} reducer={reducer}>
          <Router>
            <Switch>
              <ApolloClientProvider>
                <Route path="/shared/:id" exact component={SharedResult} />
                <Route path="/auth" component={Auth} />
                <Route path="/search" render={() => <AuthorizedRoute component={SearchResult} />} />
                <Route path="/admin" render={() => <AuthorizedRoute component={Admin} />} />
                <Route path="/" exact render={() => <AuthorizedRoute component={Home} />} />
                <Route path="/profile" render={() => <AuthorizedRoute component={UserProfile} />} />
                <Route
                  path="/manage-mappings"
                  render={() => <AuthorizedRoute component={ManageMapping} />}
                />
              </ApolloClientProvider>
            </Switch>
            <ToastContainer hideProgressBar newestOnTop draggable={false} autoClose={4000} />
          </Router>

          <GlobalStyles />
        </StateProvider>
      </CognitoProvider>
    </MixpanelProvider>
  )
}

export default withLDProvider({
  clientSideID: Env.launchDarklyClientId,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App)
