import { lighten } from 'polished'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import logoIcon from '../../../assets/img/logo-horizontal-icon.svg'
import uploadIcon from '../../../assets/img/upload-icon.svg'
import { Colors, FontSize, FontWeight } from '../../constants'
import { Flex } from '../Flex'
import { MeData, SearchTypeEnum } from '../../../api'
import LoadingIndicator from '../Spinner'
import SearchGroup from '../../../routes/Home/SearchGroup'
import useMixpanel from '../../../hooks/useMixpanel'
import Env from '../../../api/gen/Env'
import Profile from './Profile'

const Wrapper = styled(Flex)<{ isHomePage?: boolean }>`
  height: 64px;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem;
  ${props =>
    props.isHomePage &&
    css`
      background: #fafafc;
    `}
`

const UploadButton = styled('button')`
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  color: ${Colors.primary};
  font-size: ${FontSize.small};
  text-transform: uppercase;
  font-weight: ${FontWeight.bold};
  margin-right: 2rem;
  img {
    margin-right: 0.5rem;
  }

  &:hover {
    color: ${lighten(0.1, Colors.primary)};
  }
`
const Logo = styled('div')`
  cursor: pointer;
`
const Left = styled(Flex)``
const Right = styled(Flex)``

type Props = {
  isHomePage?: boolean
  onOpenUpload?: () => void
} & RouteComponentProps<
  {},
  {},
  {
    name?: string
    supplier?: string
    uom?: string
    partial?: string
    segment?: string
    subSegment?: string
    gic?: string
    gicType1?: string
    gicType2?: string
    productLine?: string
    productCategory?: string
    clinicalCategory?: string
    gudid?: string
    gmdn?: string
    type?: SearchTypeEnum
  }
>

const Menu: React.FC<Props> = ({ history, location, isHomePage = false, onOpenUpload }) => {
  const mixpanel = useMixpanel()
  return (
    <Wrapper isHomePage={isHomePage}>
      <Left flex="0 0 20%">
        {!isHomePage && (
          <Logo onClick={() => history.push('/')}>
            <img src={logoIcon} height="40" alt="Lookup" />
          </Logo>
        )}
      </Left>
      <Flex flex={1} style={{ maxWidth: '55%' }}>
        {!isHomePage && (
          <SearchGroup
            searchType={(location.state && location.state.type) || SearchTypeEnum.Part}
          />
        )}
      </Flex>
      <Right align="center" flex="0 0 25%" justify="flex-end">
        <UploadButton onClick={onOpenUpload}>
          <img src={uploadIcon} alt="Contract Analysis" />
          Item and Construct Analyzer
        </UploadButton>
        <MeData>
          {({ loading, data }) => {
            if (loading) {
              return <LoadingIndicator color={Colors.primary} />
            }
            if (!data) {
              return null
            }
            if (Env.useMixpanel) {
              mixpanel.identify(data.me.email)
              mixpanel.people.set({
                $name: `${data.me.firstName} ${data.me.lastName}`,
                $email: data.me.email,
                'Credits Remain': data.me.billing
                  ? data.me.billing.searchLimit - data.me.billing.searchCount
                  : 'N/A',
                signupCode: data.me.signupCode,
                organization: data.me.organization,
              })
              mixpanel.register({
                userName: `${data.me.firstName} ${data.me.lastName}`,
                email: data.me.email,
                creditsRemain: data.me.billing
                  ? data.me.billing.searchLimit - data.me.billing.searchCount
                  : 'N/A',
                signupCode: data.me.signupCode,
                organization: data.me.organization,
              })
            }
            return <Profile data={data.me} />
          }}
        </MeData>
      </Right>
    </Wrapper>
  )
}

export default withRouter(Menu)
