import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { zIndex } from '../constants'
import { Flex } from './Flex'

const defaultProps = {
  isOpen: false,
  shouldCloseOnClickOutside: true,
  shouldCloseOnEsc: true,
  blackOverlay: false,
}

type Props = Partial<typeof defaultProps> & {
  onRequestClose: () => void
  children?: React.ReactNode
}

const Container = styled(Flex)<{ blackOverlay?: boolean }>`
  position: fixed;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.blackOverlay ? 'rgba(108, 132, 148, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.modal};
`

const ModalContent = styled(Flex)``

const Modal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  blackOverlay,
  children,
  shouldCloseOnEsc,
  shouldCloseOnClickOutside,
}) => {
  const modalRef = useRef(null)
  const outsideRef = useRef(null)

  useEffect(() => {
    // * LOC bellow will prevent body scrolling
    // * when modal openned
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
  }, [isOpen])

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (shouldCloseOnEsc && e.keyCode === 27) {
        onRequestClose()
      }
    }
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onRequestClose, shouldCloseOnEsc])

  if (!isOpen) {
    return null
  }
  return (
    <Container
      ref={outsideRef}
      blackOverlay={blackOverlay}
      onClick={() => shouldCloseOnClickOutside && isOpen && onRequestClose()}
    >
      <ModalContent ref={modalRef} onClick={e => e.stopPropagation()}>
        {children}
      </ModalContent>
    </Container>
  )
}

Modal.defaultProps = defaultProps

export { Modal }
