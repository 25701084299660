import React from 'react'
import styled from 'styled-components'
import { MarginsProps } from '../helpers/styleHelpers'
import { Colors, FontWeight } from '../constants'
import { Flex } from './Flex'

const defaultProps = {}

type Props = Partial<typeof defaultProps> &
  MarginsProps & {
    total: number
    page: number
    show?: number
    onGoTo: (page: number) => void
    onNext?: () => void
    onPrevious?: () => void
  }

const Container = styled(Flex)``

const Page = styled(Flex)<{ isSelected?: boolean }>`
  padding: 0.5rem;
  cursor: pointer;
  font-weight: ${props => (props.isSelected ? FontWeight.bold : FontWeight.medium)};
  color: ${props => (props.isSelected ? '#000' : '#8D99A6')};
  &:hover {
    color: ${Colors.primary};
  }
`

const Pagination: React.FC<Props> = ({ total, page, onGoTo, ...restProps }) => (
  <Container {...restProps}>
    {page - 1 > 0 && (
      <Page
        onClick={() => {
          onGoTo(page - 1)
        }}
      >
        Previous
      </Page>
    )}
    {Array.from({ length: total }).map((_, index) => {
      const isSelected = page === index + 1
      if (index === 0) {
        return (
          <Page
            key="first-page"
            isSelected={isSelected}
            onClick={() => {
              if (page !== 1) {
                onGoTo(1)
              }
            }}
          >
            1
          </Page>
        )
      }
      if (index + 1 === total) {
        return (
          <Page
            key="last-page"
            isSelected={isSelected}
            onClick={() => {
              if (page !== total) {
                onGoTo(total)
              }
            }}
          >
            {total}
          </Page>
        )
      }
      if (index !== 0 && index + 1 === page - 3) {
        return <Page key="first-middle">...</Page>
      }
      if (index !== 0 && index + 1 === page + 3) {
        return <Page key="last-middle">...</Page>
      }
      if (
        index !== 0 &&
        (isSelected ||
          index + 1 === page - 1 ||
          index + 1 === page + 1 ||
          index + 1 === page - 2 ||
          index + 1 === page + 2)
      ) {
        return (
          <Page
            key={`page-${index}`}
            isSelected={isSelected}
            onClick={() => {
              if (page !== index + 1) {
                onGoTo(index + 1)
              }
            }}
          >
            {index + 1}
          </Page>
        )
      }
      return null
    })}
    {page < total && (
      <Page
        onClick={() => {
          if (page + 1 <= total) {
            onGoTo(page + 1)
          }
        }}
      >
        Next
      </Page>
    )}
  </Container>
)
Pagination.defaultProps = defaultProps

export default Pagination
