import React from 'react'
import { toast, ToastId } from 'react-toastify'

const show = (children: React.ReactNode) => toast(children)
const success = (children: React.ReactNode) => toast.success(children)
const info = (children: React.ReactNode) => toast.info(children)
const warning = (children: React.ReactNode) => toast.warn(children)
const danger = (children: React.ReactNode) => toast.error(children)
const permanentSuccess = (children: React.ReactNode) =>
  toast.success(children, { autoClose: false })
const close = (id: ToastId) => toast.dismiss(id)

const Notify = {
  show,
  success,
  info,
  warning,
  danger,
  permanentSuccess,
  close,
}

export default Notify
