import React, { useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { MeData, User } from '../../api'
import Env from '../../api/gen/Env'

function usePageViews(location: any, me?: User) {
  useEffect(() => {
    const rawWindow: any = window
    const _hsq = (rawWindow._hsq = rawWindow._hsq || [])
    if (!Env.useHubspot) {
      _hsq.push(['doNotTrack'])
      return
    }

    if (me) {
      const identificationData = {
        email: me.email,
        lookup_account_create_date: me.createdAt && new Date(me.createdAt).getTime(),
        lookup_expiration: me.billing && new Date(me.billing.expireTime).getTime(),
        last_lookup_login_date: me.lastLogin && new Date(me.lastLogin).getTime(),
        lookup_search_count: me.billing && me.billing.searchCount,
        lookup_search_limit: me.billing && me.billing.searchLimit,
        lookup_subscription_bulk_team: me.bulkAccess,
      }
      _hsq.push(['identify', identificationData])
    }
    _hsq.push(['setPath', location.pathname])
    _hsq.push(['trackPageView'])
  }, [location.state, location.pathname, me])
}

const Tracking: React.FC = () => {
  return <MeData>{({ data }) => <MeTracking me={data && data.me} />}</MeData>
}

const MeTracking: React.FC<{ me?: User }> = ({ me }) => {
  const location = useLocation()
  const ldClient = useLDClient()
  usePageViews(location, me)

  useEffect(() => {
    if (!ldClient || !me?.email || !me?.id) {
      return
    }

    ldClient.identify({ key: `lookup-${me.id}`, email: me.email })
  }, [ldClient, me?.email, me?.id])

  const HEAP_APP_ID = '841856571' // Lookup Production
  if (me?.id) {
    try {
      if (Env.useHeap) {
        heap.load(HEAP_APP_ID) // load function defined in public index.html header
        heap.identify(me.id.toString())
        heap.addUserProperties({
          Name: `${me.firstName} ${me.lastName}`,
          Email: me.email,
        })
      }
    } catch (e) {
      console.info(`Error while trying to load Heap, with user ${JSON.stringify(e)}`)
    }
  } else {
    console.log('User undefined, cannot load Heap')
  }
  return <></>
}

export const WithRouterTracking = withRouter(Tracking)
