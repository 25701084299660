import { gql } from 'apollo-boost/lib/index'
import { mutateFunction } from '../common/Utils'
import { Mutation, CreateBillingInput, UpdateBillingInput } from '../gen/Models'

// prettier-ignore
export const createBillingMutation = mutateFunction<Pick<Mutation, 'createBilling'>,
{ input: CreateBillingInput }
>({
  mutation: gql`
    mutation createBilling($input: CreateBillingInput!) {
      createBilling(input: $input) {
        id
        expireTime
        searchLimit
        searchCount
      }
    }
  `,
  refetchQueries: ['GetAllUsers'],
})

// prettier-ignore
export const updateBillingMutation = mutateFunction<Pick<Mutation, 'updateBilling'>,
{ input: UpdateBillingInput }
>({
  mutation: gql`
    mutation updateBilling($input: UpdateBillingInput!) {
      updateBilling(input: $input) {
        id
        expireTime
        searchLimit
        searchCount
      }
    }
  `,
})

// prettier-ignore
export const deleteBillingMutation = mutateFunction<Pick<Mutation, 'deleteBilling'>,
{ userId: number }
>({
  mutation: gql`
    mutation deleteBilling($userId: Int!) {
      deleteBilling(userId: $userId) 
    }
  `,
})

// prettier-ignore
export const userRequestIncreaseBillingMutation = mutateFunction<Pick<Mutation, 'userRequestIncreaseBilling'>>({
  mutation: gql`
    mutation userRequestIncreaseBilling {
      userRequestIncreaseBilling {
        id
        expireTime
        searchLimit
        searchCount
      }
    }
  `,
})
