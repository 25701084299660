import { rgba } from 'polished'
import React, { CSSProperties, useContext, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUpAlt, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import {
  ApolloClientContext,
  FalseNegativeInput,
  SearchCollection,
  SearchItem,
  SearchQueryInput,
  MeData,
} from '../../api'
import { Flex } from '../../shared/components/Flex'
import Pagination from '../../shared/components/Pagination'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import Notify from '../../shared/components/Notify'
import { createFalseNegativeReportMutation } from '../../api/components/Search'
import { addRating } from '../../api/components/User'
import Env from '../../api/gen/Env'
import { MixPanelEvent } from '../../shared/Mixpanel'
import useMixpanel from '../../hooks/useMixpanel'
import EmojiRater from '../../shared/components/EmojiRater'
import ResultItem from './ResultItem'
import { NUMBER_PER_PAGE } from './ResultListContainer'
import ShareButton from './ShareButton'

const Wrapper = styled(Flex)`
  width: 90%;
  height: 100%;
  padding: 32px 0;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 10px;
  }
`

const MaskedLoading = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#fff', 0.5)};
  justify-content: center;
  align-items: center;
  z-index: 9;
`

const SortIcon = styled(Flex)`
  margin-right: 10px;
`

const sortContainerStyles = (base: CSSProperties): CSSProperties => ({
  ...base,
  width: '160px',
  marginRight: '15px',
})

type Props = {
  data?: SearchCollection
  loading: boolean
  offset?: number
  setOffset?: (offset: number) => void
  isShared?: boolean
  setSortOption?: (val: any) => void
  searchQuery: SearchQueryInput
}

type SortOption = {
  value: {
    field: string
  }
  label: string
}

const ResultList: React.FC<Props> = ({
  data,
  loading,
  offset,
  setOffset,
  isShared,
  setSortOption,
  searchQuery,
}) => {
  const sortingOptions: SortOption[] = [
    { field: 'curvo_score', label: 'Relevance' },
    { field: 'benchmark.hospital_count', label: 'Facilities' },
    { field: 'benchmark.total_spend', label: 'Volume' },
    { field: 'product.number.keyword', label: 'Part Number' },
    { field: 'product.uom', label: 'UoM' },
  ].map(item => ({ value: { field: item.field }, label: `${item.label}` }))

  const [linkedItems, setLinkedItems] = useState<SearchItem[]>([])
  const [expandedIndex, setExpandedIndex] = useState<number>()
  const { client } = useContext(ApolloClientContext)
  const [selectedSort, setSelectedSort] = useState<any>(sortingOptions[0])
  const [selectedSortDir, setSelectedSortDir] = useState('desc')
  const [rating, setRating] = useState(0)
  const mixpanel = useMixpanel()

  const plainSearchQuery = JSON.stringify(searchQuery)

  return (
    <Wrapper column>
      <Flex fullWidth column style={{ position: 'relative' }}>
        {loading && (
          <MaskedLoading>
            <LoadingIndicator color={Colors.primary} />
          </MaskedLoading>
        )}
        {data && data.data.length > 0 && !loading && !isShared && (
          <>
            <Flex fullWidth>
              <Flex flex={1}>
                {Math.floor(Math.random() * 20) ? (
                  ''
                ) : (
                  <MeData>
                    {({ data: meData, loading: meLoading }) => {
                      if (meLoading) {
                        return <LoadingIndicator />
                      }

                      setRating(0)

                      return (
                        <>
                          <EmojiRater
                            onRate={(newRating: number) => {
                              Notify.success('Thank you for your feedback!')
                              setRating(newRating)
                              addRating(client, {
                                model: {
                                  userId: meData && meData.me ? meData.me.id : 0,
                                  rating: newRating,
                                  type: 'search',
                                  context: plainSearchQuery,
                                },
                              })
                            }}
                          />
                          {rating > 0 ? 'Thanks!' : 'Please rate the current search results'}
                        </>
                      )
                    }}
                  </MeData>
                )}
              </Flex>
              <Flex flex={1} justify="flex-end">
                {setSortOption ? (
                  <>
                    <Select
                      auto
                      placeholder="Sort By"
                      options={sortingOptions}
                      styles={{ container: sortContainerStyles }}
                      onChange={selection => {
                        setSortOption({ ...selection?.value, asc: selectedSortDir === 'asc' })
                        setSelectedSort(selection)
                      }}
                      value={selectedSort}
                    />
                    <SortIcon>
                      <FontAwesomeIcon
                        onClick={() => {
                          setSortOption({ ...selectedSort.value, asc: false })
                          setSelectedSortDir('desc')
                        }}
                        icon={faSortAmountDown}
                        size="2x"
                        color={selectedSortDir === 'desc' ? '' : '#ccc'}
                      />
                    </SortIcon>
                    <SortIcon>
                      <FontAwesomeIcon
                        onClick={() => {
                          setSortOption({ ...selectedSort.value, asc: true })
                          setSelectedSortDir('asc')
                        }}
                        icon={faSortAmountUpAlt}
                        size="2x"
                        color={selectedSortDir === 'asc' ? '' : '#ccc'}
                      />
                    </SortIcon>
                  </>
                ) : (
                  ''
                )}
                <ShareButton
                  searchData={data}
                  hasLinkedItems={!!linkedItems.length}
                  onClickLinkItems={() => {
                    const linkedItemsMixpanelData = linkedItems.map(item => ({
                      supplier: item.supplier.normalized,
                      sku: item.product.number,
                      uom: item.product.uom,
                    }))

                    createFalseNegativeReportMutation(client, {
                      data: linkedItems.map((item): FalseNegativeInput => {
                        const trx =
                          item.transactionsList && item.transactionsList.find(t => !!t.tsId)
                        return {
                          match_id: item.matchId!,
                          uom: item.product.uom!,
                          ts_id: trx ? trx.tsId : undefined,
                          search_string: searchQuery.searchText,
                        }
                      }),
                    })

                    if (Env.useMixpanel) {
                      mixpanel.track(MixPanelEvent.itemLink, {
                        linkedItems: linkedItemsMixpanelData,
                        searchQuery,
                      })
                    }

                    Notify.success('Thank you for your help! We will fix it very fast!')
                    setLinkedItems([])
                  }}
                />
              </Flex>
            </Flex>
          </>
        )}
        {data &&
          data.data.map((item, index) => {
            if (!item) {
              return null
            }
            const selected = !!linkedItems.find(v => v.id === item.id)
            return (
              <ResultItem
                key={`${item.product.stripped}-${index}`}
                data={item}
                setExpandedIndex={setExpandedIndex}
                isExpanded={expandedIndex === index || data.data.length === 1}
                index={index}
                linked={selected}
                searchQuery={searchQuery}
                onToggleLinked={() => {
                  setLinkedItems(
                    selected ? linkedItems.filter(v => v.id !== item.id) : [...linkedItems, item],
                  )
                }}
              />
            )
          })}
        {data && (
          <Flex fullWidth column align="center" justify="center">
            <Pagination
              total={Math.ceil(data.info.total / NUMBER_PER_PAGE)}
              page={offset ? offset / NUMBER_PER_PAGE + 1 : 1}
              onGoTo={page => setOffset && setOffset((page - 1) * NUMBER_PER_PAGE)}
            />
            <span style={{ color: '#8D99A6', opacity: 0.5 }}>{data.info.total} results</span>
          </Flex>
        )}
      </Flex>
    </Wrapper>
  )
}

export default ResultList
