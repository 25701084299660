import React, { useContext } from 'react'
import styled from 'styled-components'
import { Formik, FormikHelpers } from 'formik'
import { addDays } from 'date-fns'
import { Flex } from '../../../shared/components/Flex'
import LoadingIndicator from '../../../shared/components/Spinner'
import TextInput from '../../../shared/components/Inputs/TextInput'
import { PageTitle } from '../../Auth/shared/PageTitle'
import { Button } from '../../../shared/components/Button'
import { ApolloClientContext, inviteUser } from '../../../api'
import Notify from '../../../shared/components/Notify'

const Wrapper = styled(Flex)`
  margin: 0 auto;
  justify-content: center;
`

const Form = styled('form')`
  width: 100%;
`

type Props = {}

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  organization: '',
  bulkAccess: false,
  constructAccess: false,
  searchLimit: 20,
  // Expire in 14 days
  expireTime: addDays(new Date(), 14).toISOString(),
}

const InviteAdmin: React.FC<Props> = () => {
  const { client } = useContext(ApolloClientContext)
  const handleSave = async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>,
  ) => {
    setSubmitting(true)
    try {
      await inviteUser(client, {
        model: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          organization: values.organization,
          bulkAccess: values.bulkAccess,
          constructAccess: values.constructAccess,
          searchLimit: Number(values.searchLimit),
          expireTime: values.expireTime,
        },
      })
      Notify.success('Successfully invited user!')
      setSubmitting(false)
      resetForm()
    } catch (e) {
      Notify.danger('Unable to invite user: ' + e)
    }
  }
  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSave}>
        {({ values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <PageTitle>Invite User</PageTitle>
            <Flex fullWidth column marginTop={32}>
              <TextInput
                inputProps={{
                  placeholder: 'Email',
                  name: 'email',
                  value: values.email,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  required: true,
                }}
                error={(touched.email && errors.email) || undefined}
                style={{ marginBottom: 24 }}
              />
              <Flex fullWidth>
                <TextInput
                  inputProps={{
                    placeholder: 'First Name',
                    name: 'firstName',
                    value: values.firstName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    required: true,
                  }}
                  error={(touched.firstName && errors.firstName) || undefined}
                  style={{ marginBottom: 24, flex: 1, marginRight: 16 }}
                />
                <TextInput
                  inputProps={{
                    placeholder: 'Last Name',
                    name: 'lastName',
                    value: values.lastName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    required: true,
                  }}
                  error={(touched.lastName && errors.lastName) || undefined}
                  style={{ marginBottom: 24, flex: 1 }}
                />
              </Flex>
              <Flex fullWidth>
                <TextInput
                  label="Organization"
                  inputProps={{
                    placeholder: 'Organization',
                    name: 'organization',
                    value: values.organization,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    required: true,
                  }}
                  error={(touched.organization && errors.organization) || undefined}
                  style={{ marginBottom: 24, flex: 1, marginRight: 16 }}
                />
                <TextInput
                  label="Search Limit"
                  inputProps={{
                    placeholder: 'Search Limit',
                    name: 'searchLimit',
                    value: values.searchLimit,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    required: true,
                  }}
                  error={(touched.searchLimit && errors.searchLimit) || undefined}
                  style={{ marginBottom: 24, flex: 1, marginRight: 16 }}
                />
              </Flex>
              <Flex fullWidth column>
                <TextInput
                  label="Expire Time"
                  inputProps={{
                    placeholder: 'Expire Time',
                    name: 'expireTime',
                    value: values.expireTime,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    required: true,
                  }}
                  error={(touched.expireTime && errors.expireTime) || undefined}
                  style={{ marginBottom: 24, flex: 1 }}
                />
              </Flex>
              <Flex fullWidth>
                <TextInput
                  label="Item Analyzer"
                  inputProps={{
                    name: 'bulkAccess',
                    checked: values.bulkAccess,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    type: 'checkbox',
                  }}
                  error={(touched.bulkAccess && errors.bulkAccess) || undefined}
                  style={{ marginBottom: 24, flex: 1 }}
                />
              </Flex>
              <Flex fullWidth>
                <TextInput
                  label="Construct Analyzer"
                  inputProps={{
                    name: 'constructAccess',
                    checked: values.constructAccess,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    type: 'checkbox',
                  }}
                  error={(touched.constructAccess && errors.constructAccess) || undefined}
                  style={{ marginBottom: 24, flex: 1 }}
                />
              </Flex>
              <Button fullWidth type="submit" disabled={isSubmitting}>
                {isSubmitting ? <LoadingIndicator style={{ justifySelf: 'center' }} /> : 'Save'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export default InviteAdmin
