import ApolloClient, {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-boost/lib/index'
import React from 'react'
import { ApolloProvider } from 'react-apollo'
import introspectionQueryResultData from '../gen/Arguments'
import Env from '../gen/Env'
import { CognitoContext } from './CognitoContext'

type ContextType = {
  client: ApolloClient<NormalizedCacheObject>
}

// @TODO: update react-apollo to fix this
const ApolloProviderWrapper: any = ApolloProvider<NormalizedCacheObject>

// @ts-ignore
export const ApolloClientContext = React.createContext<ContextType>({})
ApolloClientContext.displayName = 'ApolloClientContext'

export const ApolloClientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getToken, logOut } = React.useContext(CognitoContext)

  const getHeader = React.useCallback(async () => {
    const token = await getToken()
    return token ? { authorization: `Bearer ${token}` } : {}
  }, [getToken])

  const client = React.useMemo(
    () =>
      new ApolloClient<NormalizedCacheObject>({
        uri: Env.api,
        fetchOptions: { credentials: 'include' },
        request: async operation => {
          operation.setContext({
            headers: await getHeader(),
          })
        },
        onError: ({ networkError }) => {
          if (networkError) {
            console.info({ networkError })
            if ((networkError as any).statusCode === 401) {
              logOut()
            }
          }
        },
        cache: new InMemoryCache({
          fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
          }),
        }),
      }),
    [getHeader, logOut],
  )
  const value: ContextType = {
    client,
  }
  return (
    <ApolloClientContext.Provider value={value}>
      <ApolloProviderWrapper client={client}>{children}</ApolloProviderWrapper>
    </ApolloClientContext.Provider>
  )
}
