import React from 'react'
import styled from 'styled-components'
import { FormikState, FormikHandlers } from 'formik'
import { Button } from '../../shared/components/Button'
import LoadingIndicator from '../../shared/components/Spinner'
import TextInput from '../../shared/components/Inputs/TextInput'
import { Values } from './ForgotPassword'
import { InfoText } from './LoginForm'
import { PageTitle } from './shared/PageTitle'

const defaultProps = {}

type Props = Partial<typeof defaultProps> &
  FormikState<Values> &
  FormikHandlers & { children?: React.ReactNode }

const Form = styled('form')`
  width: 100%;
`
const StyledTextInput = styled(TextInput)`
  margin-bottom: 32px;
`

const ForgotPasswordForm: React.FC<Props> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => (
  <Form onSubmit={handleSubmit}>
    <PageTitle>Sign Up</PageTitle>
    <InfoText type={values.messageType} marginBottom="1rem">
      {values.formMessage && values.formMessage}
    </InfoText>
    <StyledTextInput
      style={{ flex: '1 1 auto', marginRight: '1rem' }}
      inputProps={{
        name: 'email',
        value: values.email.toLowerCase(),
        placeholder: 'Enter your Email',
        onChange: handleChange,
      }}
      error={touched.email ? errors.email : undefined}
    />
    {values.sentCode && (
      <>
        <InfoText type="info" marginBottom="1rem">
          A confirmation code has been sent to your Email!
        </InfoText>
        <StyledTextInput
          style={{ flex: '1 1 auto', marginRight: '1rem' }}
          inputProps={{
            name: 'code',
            value: values.code,
            placeholder: 'Enter your Code',
            onChange: handleChange,
          }}
          error={touched.code ? errors.code : undefined}
        />
        <StyledTextInput
          style={{ flex: '1 1 auto', marginRight: '1rem' }}
          inputProps={{
            name: 'password',
            type: 'password',
            value: values.password,
            placeholder: 'Enter your New Password',
            onChange: handleChange,
          }}
          error={touched.password ? errors.password : undefined}
        />
        <StyledTextInput
          style={{ flex: '1 1 auto', marginRight: '1rem' }}
          inputProps={{
            name: 'confirmPassword',
            type: 'password',
            value: values.confirmPassword,
            placeholder: 'Confirm your New Password',
            onChange: handleChange,
          }}
          error={touched.confirmPassword ? errors.confirmPassword : undefined}
        />
      </>
    )}
    <Button fullWidth marginBottom="1rem" type="submit">
      {isSubmitting ? <LoadingIndicator /> : 'Reset Password'}
    </Button>
  </Form>
)

ForgotPasswordForm.defaultProps = defaultProps

export default ForgotPasswordForm
