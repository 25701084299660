import React, { useState } from 'react'
import styled from 'styled-components'
import PageLayout from '../../shared/components/PageLayout'
import { MeData, Mapping, AlternativeHeadersInput } from '../../api'
import { Flex } from '../../shared/components/Flex'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'
import TextInput from '../../shared/components/Inputs/TextInput'
import { MappingsSelector } from '../../shared/components/Upload/MappingsSelector'
import {
  SaveMappingButton,
  SaveAsMappingButton,
  DeleteMappingButton,
} from '../../shared/components/SaveMappingButton'

const Title = styled(Flex)`
  margin-top: 32px;
  margin-bottom: 18px;
  margin-left: 24px;

  font-weight: bold;
  font-size: 1.5em;
`

const InfoContainer = styled(Flex)`
  width: 100%;
  padding: 24px;
  border: 1px #d8e1e8 solid;
`

const Container = styled(Flex)`
  flex-direction: column;
  margin: 32px auto 0 auto;
  width: 856px;
`

export const ManageMapping: React.FC = () => {
  const [selected, setSelected] = useState<Mapping>()
  const [currentMapping, setCurrentMapping] = useState<AlternativeHeadersInput>()
  const [name, setName] = useState<string>('')
  const getFieldMappingInputProps = (field: keyof AlternativeHeadersInput) => ({
    value: (currentMapping && currentMapping[field]) || undefined,
    onChange: (e: any) => {
      setCurrentMapping({
        ...currentMapping,
        [field]: e.target.value,
      })
    },
  })
  const canProcess = !!currentMapping && !!currentMapping.Supplier && !!currentMapping.CatalogNumber
  const isUpdatedMapping =
    canProcess && !!selected && (selected.mapping !== currentMapping || name !== selected.name)

  return (
    <PageLayout showSideBar={false}>
      <MeData>
        {({ loading, data }) => {
          if (loading) {
            return (
              <Flex margin="32px auto">
                <LoadingIndicator color={Colors.primary} />
              </Flex>
            )
          }
          if (!data) {
            return null
          }
          return (
            <Container>
              <Title>Manage Mapping</Title>
              <InfoContainer column>
                <Row>
                  <LabelSpan>Your Mappings</LabelSpan>
                  <StyledMappingsSelector
                    placeholder="Fields mapping"
                    value={selected ? { label: selected.name, value: selected } : undefined}
                    onChange={(select: any) => {
                      setSelected(select.value)
                      setCurrentMapping(select.value.mapping)
                      setName(select.value.name)
                    }}
                  />
                </Row>
                <Row>
                  <StyledTextInput
                    label="Mapping Name"
                    inputProps={{
                      value: name,
                      onChange: e => setName(e.target.value),
                    }}
                  />
                </Row>
                <Row>
                  <StyledTextInput
                    label="Supplier"
                    inputProps={getFieldMappingInputProps('Supplier')}
                  />
                </Row>
                <Row>
                  <StyledTextInput
                    label="Catalog Number"
                    inputProps={getFieldMappingInputProps('CatalogNumber')}
                  />
                </Row>
                <Row>
                  <StyledTextInput label="UOM" inputProps={getFieldMappingInputProps('Uom')} />
                </Row>
                <Row>
                  <StyledTextInput label="Price" inputProps={getFieldMappingInputProps('Price')} />
                </Row>
                <Row>
                  <StyledTextInput
                    label="Quantity"
                    inputProps={getFieldMappingInputProps('Quantity')}
                  />
                </Row>
              </InfoContainer>
              <Flex fullWidth style={{ marginTop: 16 }}>
                <SaveMappingButton
                  id={selected ? selected.id : 0}
                  disabled={!isUpdatedMapping}
                  mapping={currentMapping}
                  marginRight="16px"
                  name={name}
                />
                <SaveAsMappingButton
                  mapping={currentMapping}
                  disabled={!canProcess}
                  marginRight="16px"
                />
                <DeleteMappingButton id={selected && selected.id} disabled={!selected} />
              </Flex>
            </Container>
          )
        }}
      </MeData>
    </PageLayout>
  )
}

const LabelSpan = styled.span`
  text-align: right;
  margin-right: 8px;
`

const Row = styled(Flex)`
  margin-bottom: 16px;
  align-items: center;
  justify-content: right;
  width: 100%;
`
const StyledTextInput = styled(TextInput)``

const StyledMappingsSelector = styled(MappingsSelector)`
  width: 256px;
`
