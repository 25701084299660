import { Formik, FormikHelpers } from 'formik'
import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import * as xlsx from 'xlsx'
import * as Yup from 'yup'
import {
  ApolloClientContext,
  SearchCollection,
  SearchHistoriesData,
  shareResultMutation,
} from '../../api'
import Env from '../../api/gen/Env'
import Notify from '../../shared/components/Notify'
import useMixpanel from '../../hooks/useMixpanel'
import { useToggle } from '../../hooks/useToggle'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import { Modal } from '../../shared/components/Modal'
import { Colors } from '../../shared/constants'
import SharedResultForm, { ShareResultValues, initialValues } from './ShareResultForm'

const validateSchema = () => {
  const shape = {
    receiverEmail: Yup.string().email('Should be an email.').required('Email is required!'),
  }
  return Yup.object().shape(shape)
}

const Wrapper = styled(Flex)`
  position: relative;
`

const ShareModal = styled(Flex)`
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: ${Colors.textSecondary};
  width: max-content;
  align-items: center;
  padding: 10px 20px;
  z-index: 9;
`

const exportPage = (searchData: SearchCollection): void => {
  const exportData = [
    [
      'Vendor Name',
      'Vendor Item',
      'UoM',
      'Conversion',
      'Description',
      'Facilities',
      'Volume',
      '25th',
      '50th',
      '75th',
      '90th',
      'ASP',
    ],
    ...searchData.data.map(val => {
      if (!val) {
        return []
      }

      return [
        val.supplier.normalized,
        val.product.number,
        val.product.uom,
        val.conversion ? parseInt(val.conversion, 10) : 1,
        val.description,
        val.benchmark.hospitalCount,
        val.benchmark.totalSpend,
        val.benchmark.iqo25,
        val.benchmark.iqo50,
        val.benchmark.iqo75,
        val.benchmark.iqo90,
        val.benchmark.averagePrice,
      ]
    }),
  ]

  const ws = xlsx.utils.aoa_to_sheet(exportData)
  const colNums = [6, 7, 8, 9, 10, 11]
  const fmt = '$#,##0.00'

  colNums.map(colNum => {
    if (!ws['!ref']) {
      return colNum
    }
    const range = xlsx.utils.decode_range(ws['!ref'])
    for (let i = range.s.r + 1; i <= range.e.r; ++i) {
      const ref = xlsx.utils.encode_cell({ r: i, c: colNum })
      if (!ws[ref]) {
        continue
      }
      if (ws[ref].t !== 'n') {
        continue
      }
      ws[ref].z = fmt
    }

    return colNum
  })

  const wb = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(wb, ws, 'Lookup Export')
  xlsx.writeFile(wb, 'lookup_export.xlsx')
}

type Props = {
  hasLinkedItems?: boolean
  onClickLinkItems: () => void
  searchData: SearchCollection
} & RouteComponentProps

const ShareButton: React.FC<Props> = ({ hasLinkedItems, onClickLinkItems, searchData }) => {
  const { on } = useToggle(false)
  const mixpanel = useMixpanel()
  const [showModal, setShowModal] = useState({ isOpen: false })
  const { client } = useContext(ApolloClientContext)

  const handleSubmit = async (
    values: ShareResultValues,
    { setSubmitting, setFieldValue }: FormikHelpers<ShareResultValues>,
    searchId?: string | null,
  ) => {
    if (!searchId) {
      Notify.warning('No Search ID to share.')
      return
    }
    try {
      console.info('inside try/catch')
      await shareResultMutation(client, {
        data: { ...values, searchLink: `${window.location.hostname}/shared/${searchId}` },
      })
      setSubmitting(false)
      setShowModal({ isOpen: false })
      Notify.success('You results were shared successfully.')
    } catch (e) {
      setSubmitting(false)
      setFieldValue('formMessage', e.message)
      setFieldValue('messageType', 'error')
      Notify.danger('Error occured while sending search results.')
    }
  }

  return (
    <SearchHistoriesData fetchPolicy="cache-and-network">
      {({ data, loading }) => {
        const searchHistories = data && data.me && data.me.searchHistories.data[0]
        const searchId = searchHistories && searchHistories.id

        return (
          <Wrapper justify="flex-end" marginBottom="1rem">
            {hasLinkedItems && (
              <Button
                style={{ height: 36, padding: '0 0.75rem', marginRight: '15px' }}
                onClick={onClickLinkItems}
                disabled={!data || loading}
              >
                Link Items
              </Button>
            )}
            <Button
              style={{ height: 36, padding: '0 0.75rem', marginRight: '15px' }}
              onClick={() => {
                // Notify.info('Coming soon!')
                if (Env.useMixpanel) {
                  mixpanel.track('Export')
                }

                exportPage(searchData)
              }}
              disabled={!data || loading}
            >
              Export Page
            </Button>
            <Button
              style={{ height: 36, padding: '0 0.75rem' }}
              onClick={() => {
                setShowModal({ isOpen: true })
                // if (!on && Env.useMixpanel) {
                // mixpanel.track('Share results')
                // }
              }}
              disabled={!data || loading}
            >
              Share Result
            </Button>
            <Modal
              blackOverlay
              isOpen={showModal.isOpen}
              onRequestClose={() => setShowModal({ isOpen: false })}
              shouldCloseOnEsc
              shouldCloseOnClickOutside
            >
              <Formik
                initialValues={initialValues}
                onSubmit={(args, action) => handleSubmit(args, action, searchId)}
                validationSchema={validateSchema}
                render={props => <SharedResultForm {...props} />}
              />
            </Modal>

            {on && (
              <ShareModal>
                {window.location.hostname}/shared/{searchId}
              </ShareModal>
            )}
          </Wrapper>
        )
      }}
    </SearchHistoriesData>
  )
}

export default withRouter(ShareButton)
