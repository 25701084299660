import { darken } from 'polished'
import styled, { css } from 'styled-components'
import { FontWeight } from '../constants'
import { getMarginPadding, MarginsProps, PaddingsProps } from '../helpers/styleHelpers'

export type BtnType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'

export type BtnProps = MarginsProps &
  PaddingsProps & {
    fullWidth?: boolean
    btnType?: BtnType
    disabled?: boolean
  }

export const Button = styled('button')<BtnProps>`
  ${getMarginPadding};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: 0px 1rem;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: ${FontWeight.bold};
  border-radius: 2px;

  ${props =>
    props.btnType === 'primary' &&
    css`
      background: #e67f45;
      color: #fff;
      height: 48px;
      &:hover {
        background-color: ${darken(0.05, '#e67f45')};
      }
    `}
  ${props =>
    props.btnType === 'link' &&
    css`
      background: transparent;
      color: #e67f45;
      font-weight: ${FontWeight.medium};
      &:hover {
        color: ${darken(0.05, '#e67f45')};
      }
    `}
`

Button.defaultProps = {
  btnType: 'primary',
}
