import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from '../Flex'
import { Colors, FontWeight } from '../../constants'

const Wrapper = styled(Flex)`
  position: relative;
`
const Label = styled('label')`
  margin-bottom: 8px;
`
const InputWrapper = styled(Flex)<{ error?: boolean; naked?: boolean }>`
  border-radius: 3px;
  background: #f2f4f7;
  font-size: 1rem;
  border-bottom: 3px solid transparent;
  width: 100%;
  input {
    ::placeholder {
      color: #c9d4dd;
      font-weight: ${FontWeight.bold};
    }
  }

  ${props =>
    !props.naked &&
    props.error &&
    css`
      border-color: ${Colors.danger};
    `}

  &:focus-within {
    ${props => !props.naked && `border-color: ${Colors.primary}`};
  }
`

const Input = styled('input')`
  outline: none;
  display: block;
  height: 48px;
  background: transparent;
  width: 100%;
  line-height: 1.5;
  border: none;
  padding: 0 20px;
  color: ${props => (props.disabled ? Colors.textSecondary : 'inherit')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
`
const ErrorText = styled('span')`
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  font-size: 12px;
  color: ${Colors.danger};
`

type Props = {
  label?: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  error?: string | undefined
  naked?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const TextInput: React.FC<Props> = ({ label, inputProps, error, naked, ...rest }) => (
  <Wrapper fullWidth column {...rest}>
    {label && <Label>{label}</Label>}
    <InputWrapper error={!!error} naked={naked}>
      <Input {...inputProps} />
    </InputWrapper>
    <ErrorText>{error || ''}</ErrorText>
  </Wrapper>
)

export default TextInput
