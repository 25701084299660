import styled from 'styled-components'
import { darken } from 'polished'
import { FontSize, FontWeight, Colors } from '../../../shared/constants'
import { Flex } from '../../../shared/components/Flex'

export const RecentItem = styled(Flex)`
  padding: 8px 12px;
  flex-direction: column;
  border: 1px solid transparent;
  border-color: #d8e1e8;
  border-radius: 2px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  transition: border-color 0.1s ease-in-out;

  max-width: 180px;
  min-width: 100px;
  white-space: nowrap;
  display: inline-block;

  &:hover {
    border-color: ${darken(0.1, '#D8E1E8')};
  }
  &:active {
    border-color: ${darken(0.2, '#D8E1E8')};
  }
`
export const ItemTitle = styled('div')`
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.bold};
  color: #416a7a;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ItemDesc = styled('div')`
  margin-top: 5px;
  font-size: ${FontSize.small};
  color: ${Colors.textSecondary};
`
