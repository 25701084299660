import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from 'polished'
import { SearchFilterData } from '../../api/components/Search'
import { SearchQueryInput, SearchFilter, SearchTypeEnum } from '../../api'
import { Flex } from '../../shared/components/Flex'
import { Separator } from '../Home/shared/Separator'
import SidebarList from './SidebarList'

const Wrapper = styled(Flex)`
  width: 100%;
  align-self: stretch;
  padding: 28px 16px 28px 32px;

  @media (max-width: 1024px) {
    padding-left: 12px;
  }
`

const StyledLink = styled(Link)`
  color: #f86b4f;
  padding-left: 8px;
  padding-bottom: 1rem;
  font-weight: 500;
  &:hover {
    color: ${darken(0.1, '#f86b4f')};
    text-decoration: underline;
  }
`

type Props = {
  searchQuery: SearchQueryInput
  sidebarQuery: SearchQueryInput
  setSearchQuery: (input: SearchQueryInput) => void
  searchType?: SearchTypeEnum
}

const SidebarContainer: React.FC<Props> = ({
  searchQuery,
  sidebarQuery,
  setSearchQuery,
  searchType,
}) => {
  const [sidebarData, setSidebarData] = useState<SearchFilter>()

  return (
    <SearchFilterData variables={{ query: sidebarQuery, strict: !!sidebarData }}>
      {({ data, loading }) => {
        if (data) {
          if (sidebarData) {
            const newSidebarData: SearchFilter = Object.keys(sidebarData).reduce(
              (previous, key) => {
                if (searchQuery[key as keyof SearchQueryInput]) {
                  return { ...previous }
                }

                return { ...previous, ...{ [key]: data.searchFilter[key as keyof SearchFilter] } }
              },
              sidebarData,
            )

            if (JSON.stringify(sidebarData) !== JSON.stringify(newSidebarData)) {
              setSidebarData(newSidebarData)
            }
          } else {
            setSidebarData(data.searchFilter)
          }
        }

        return (
          <Sidebar
            data={sidebarData}
            setSelectedItem={setSearchQuery}
            loading={loading}
            selectedItems={searchQuery}
            searchType={searchType}
          />
        )
      }}
    </SearchFilterData>
  )
}

type SidebarProps = {
  data?: SearchFilter
  loading: boolean
  selectedItems?: SearchQueryInput
  setSelectedItem?: (input: SearchQueryInput) => void
  isShared?: boolean
  searchType?: SearchTypeEnum
}

export type SideBarItem = {
  title: string
  category: string
}

const sidebarItems: SideBarItem[] = [
  {
    title: 'Supplier',
    category: 'supplier',
  },
  {
    title: 'GMDN',
    category: 'gmdn',
  },
  {
    title: 'GIC',
    category: 'gic',
  },
  {
    title: 'Segment',
    category: 'segment',
  },
  {
    title: 'Product Line',
    category: 'productLine',
  },
  {
    title: 'UoM',
    category: 'uom',
  },
]

export const Sidebar: React.FC<SidebarProps> = ({
  data,
  selectedItems,
  setSelectedItem,
  loading,
  isShared,
  searchType,
}) => (
  <Wrapper fullWidth column>
    {!isShared && (
      <>
        {searchType !== SearchTypeEnum.Part && (
          <StyledLink to={{ pathname: '/', state: { searchMode: SearchTypeEnum.Part } }}>
            Go to Part search
          </StyledLink>
        )}
        {searchType !== SearchTypeEnum.Keyword && (
          <StyledLink to={{ pathname: '/', state: { searchMode: SearchTypeEnum.Keyword } }}>
            Go to Keyword search
          </StyledLink>
        )}
        {searchType !== SearchTypeEnum.Advanced && (
          <StyledLink to={{ pathname: '/', state: { searchMode: SearchTypeEnum.Advanced } }}>
            Go to Advanced search
          </StyledLink>
        )}
      </>
    )}
    {sidebarItems.map((item, index) => (
      <Fragment key={index}>
        {index !== 0 && (
          <Separator
            type="horizontal"
            marginTop="16px"
            marginBottom="18px"
            padding="0 8px"
            color="#E0E7ED"
          />
        )}
        <SidebarList
          data={data}
          item={item}
          selectedItems={selectedItems}
          setSearchQuery={setSelectedItem}
          loading={loading}
        />
      </Fragment>
    ))}
  </Wrapper>
)
export default SidebarContainer
