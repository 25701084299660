import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../shared/components/Button'
import { Flex, FlexProps } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import LoadingIndicator from '../../shared/components/Spinner'
import { Colors } from '../../shared/constants'

const InfoTitle = styled(Flex)`
  color: ${Colors.secondary};
`
const InfoValueText = styled(Flex)`
  font-size: 1.2em;
`
const InfoEditButton = styled(Flex)`
  color: ${Colors.textBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

type InfoFieldProps = {
  title?: string
  value?: string | number
  editable?: boolean
  editText?: string
  onSubmit?: (value?: string | number) => Promise<void>
  onOpenEdit?: () => void
} & FlexProps

const InfoField: React.FC<InfoFieldProps> = ({
  title,
  value,
  editable,
  editText,
  onSubmit,
  onOpenEdit,
  ...flexProps
}) => {
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editValue, setEditValue] = useState(value)

  useEffect(() => {
    setEditValue(value)
  }, [value, editMode])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value)
  }

  const handleSubmit = async (newValue?: string | number) => {
    setLoading(true)
    setEditMode(false)
    if (onSubmit && value !== newValue) {
      await onSubmit(newValue)
    }
    setLoading(false)
  }

  return (
    <Flex column {...flexProps}>
      <InfoTitle marginBottom={2}>{title}</InfoTitle>
      {loading ? (
        <LoadingIndicator color={Colors.primary} />
      ) : (
        <Flex align="center">
          {editMode ? (
            <TextInput
              inputProps={{
                value: editValue,
                onChange: handleChange,
              }}
            />
          ) : (
            <InfoValueText>{value}</InfoValueText>
          )}
          {editable &&
            (editMode && !onOpenEdit ? (
              <Flex align="stretch">
                <Button marginLeft={4} onClick={() => handleSubmit(editValue)}>
                  Update
                </Button>
                <Button btnType="secondary" marginLeft={4} onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
              </Flex>
            ) : (
              <InfoEditButton
                marginLeft={4}
                onClick={() => (onOpenEdit ? onOpenEdit() : setEditMode(true))}
              >
                {editText || 'edit'}
              </InfoEditButton>
            ))}
        </Flex>
      )}
    </Flex>
  )
}

export { InfoField }
