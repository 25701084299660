import React, { useState, useRef, useEffect } from 'react'
// import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom'
import ToggleButton from '../../shared/components/ToggleButton'
import { FontWeight } from '../../shared/constants'
import { Actions } from '../../state/Actions'
import { useStateValue } from '../../state/State'
import { Button } from '../../shared/components/Button'
import { Flex } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import { useToggle } from '../../hooks/useToggle'
import { SuggestionItem, SearchTypeEnum } from '../../api'
import Notify from '../../shared/components/Notify'
import { useDebounce } from '../../hooks/useDebounce'
import TagInput from './TagInput'
import SuggestionDropdown from './SuggestionDropdown'
import { Separator } from './shared/Separator'

const Wrapper = styled(Flex)``

const MultiSKUWrapper = styled(Flex)`
  background: #f2f4f7;
  min-height: 48px;
  padding: 12px;
  align-items: center;
  color: #6c8494;
  align-self: stretch;
  font-size: 13px;
  font-weight: ${FontWeight.bold};

  span {
    margin-left: 16px;
  }
`
const StyledButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 1rem;
  &:disabled {
    background: #f2f4f7;
    color: #6c8494;
    cursor: not-allowed;
  }
`

const SearchInput = styled(TextInput)`
  input {
    ::placeholder {
      color: #6c8494;
    }
  }
`

type Props = { searchType: SearchTypeEnum; isHomePage?: boolean } & RouteComponentProps

const SearchGroup: React.FC<Props> = ({ history, searchType, isHomePage }) => {
  const [isMulti, setIsMulti] = useState(false)
  const [{ searchTerm }, dispatch] = useStateValue()
  const [searchTextLocal, setSearchText] = useState('')

  const debouncedSearchTerm = useDebounce(searchTextLocal, 500)

  const { on, off, toggle } = useToggle(false)
  const parentRef = useRef(null)

  const location = useLocation<{ name?: string; gmdn?: string }>()

  useEffect(() => {
    if (location.state && !location.state.name && location.state.gmdn) {
      setSearchText(location.state.gmdn)
    }
  }, [location.state])

  useEffect(() => {
    // reset search input when back to home
    if (isHomePage) {
      dispatch({ payload: '', type: Actions.ChangeSearchTerm })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSearchText(searchTerm)
    if (/*searchType === SearchTypeEnum.Part && */ searchTerm.indexOf(' ') !== -1) {
      setIsMulti(true)
    }
  }, [setSearchText, searchTerm, searchType])

  const handleSubmit = () => {
    if (searchTextLocal === '') {
      return
    }
    off()
    dispatch({ payload: searchTextLocal, type: Actions.ChangeSearchTerm })
    history.push('/search', { name: searchTextLocal, type: searchType })
  }
  const handleItemClick = (item: SuggestionItem) => {
    off()
    dispatch({ payload: item.name, type: Actions.ChangeSearchTerm })
    history.push('/search', {
      ...item,
      uom: undefined,
      name: item.name.replace(' ', ''),
      type: SearchTypeEnum.Part,
      exact: true,
    })
  }
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (!on) {
      toggle()
    }
    switch (e.key) {
      case 'Enter': // enter
        handleSubmit()
        break
      default:
    }
  }

  return (
    <Wrapper fullWidth align="center" alignSelf="stretch">
      <Flex flex={1} style={{ position: 'relative' }} alignSelf="stretch" ref={parentRef}>
        {!isMulti && (
          <SearchInput
            inputProps={{
              value: searchTextLocal,
              onChange: e => {
                const rawValue = e.target.value.replace('\\', '')

                if (e.target.value.includes('\\')) {
                  Notify.info('Character "\\" is not allowed')
                }
                if (rawValue === '') {
                  dispatch({ payload: '', type: Actions.ChangeSearchTerm })
                }
                setSearchText(rawValue)
              },
              placeholder:
                searchType === SearchTypeEnum.Keyword ? 'Enter Keywords' : 'Enter SKU to search',
              autoFocus: true,
            }}
            onKeyDown={handleKeyPress}
          />
        )}

        {isMulti && (
          <TagInput
            value={debouncedSearchTerm}
            onChange={value => setSearchText(value)}
            onFocusInput={() => {}}
            onSubmit={handleSubmit}
          />
        )}
        {on && !isMulti && searchTextLocal && (
          <SuggestionDropdown
            searchText={debouncedSearchTerm}
            searchType={searchType}
            on={on}
            off={off}
            parentRef={parentRef}
            onItemClick={handleItemClick}
          />
        )}
      </Flex>
      <Separator size={24} />
      <MultiSKUWrapper>
        <ToggleButton isToggle={isMulti} onToggle={() => setIsMulti(!isMulti)}>
          {' '}
          <span>multi-{searchType === SearchTypeEnum.Keyword ? 'Keywords' : 'SKUs'}</span>
        </ToggleButton>
      </MultiSKUWrapper>
      <StyledButton disabled={searchTextLocal === ''} onClick={handleSubmit}>
        Look up
      </StyledButton>
    </Wrapper>
  )
}

export default withRouter(SearchGroup)
