import { gql } from 'apollo-boost'
import { mutateFunction } from '../common/Utils'
import { Mutation, ShareSearchInput } from '..'

// prettier-ignore
export const shareResultMutation = mutateFunction<
Pick<Mutation, 'shareSearch'>,
{ data: ShareSearchInput }
>({
  mutation: gql`
    mutation shareSearch($data: ShareSearchInput!) {
      shareSearch(data: $data) 
    }
  `,
})

export const SharedResultQuery = gql`
  query searchBySharedSearch($searchId: String) {
    searchBySharedSearch(searchHistoryId: $searchId) {
      filters {
        supplier {
          name
          total
        }
        uom {
          name
          total
        }
        segment {
          name
          total
        }
        subSegment {
          name
          total
        }
        gic {
          name
          total
        }
        gicType1 {
          name
          total
        }
        gicType2 {
          name
          total
        }
        productLine {
          name
          total
        }
        productCategory {
          name
          total
        }
        clinicalCategory {
          name
          total
        }
      }
      results {
        data {
          id
          conversion
          description
          supplier {
            name
          }
          product {
            number
            stripped
            uom
          }
          benchmark {
            iqo25
            iqo50
            iqo75
            iqo90
            averagePrice
            totalQuantity
            hospitalCount
            totalSpend
          }
        }
        info {
          offset
          total
        }
      }
    }
  }
`
