import React, { useContext, useState } from 'react'
import styled from 'styled-components'
// import { useStateValue } from '../../state/State'
import { useToggle } from '../../hooks/useToggle'
// import { Actions } from '../../state/Actions'
import IconCheck from '../../assets/img/uploading.svg'
import { CheckFileInfoData } from '../../api/components/File'
import { addRating, ApolloClientContext, FileStatusEnum, MeData } from '../../api'
import Env from '../../api/gen/Env'
import useMixpanel from '../../hooks/useMixpanel'
import { MixPanelEvent } from '../Mixpanel'
import Notify from './Notify'
import { Flex } from './Flex'
import Menu from './Menu'
import { Modal } from './Modal'
import Upload from './Upload'
import { StyledUploadIcon } from './Upload/common'
import { Button } from './Button'
import EmojiRater from './EmojiRater'

const Wrapper = styled(Flex)`
  width: 100%;
  min-height: 100vh;
`
const Content = styled(Flex)`
  width: 100%;
  background: #fafafc;
  padding-bottom: 5vh;
`

type Props = {
  isHomePage?: boolean
  showSideBar?: boolean
  children?: React.ReactNode
}

const UploadBar = styled(Flex)`
  width: 70vw;
  position: fixed;
  bottom: 20px;
  height: 50px;
  align-self: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  align-items: center;
  padding: 0 12px;
  background: #fff;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  height: 36px;
`

const PageLayout: React.FC<Props> = ({ isHomePage = false, children }) => {
  const { on, off, toggle } = useToggle(false)
  const [processing, setProcessing] = useState(false)
  const [fileInfo, setFileInfo] = useState<{ fileId?: number; fileName?: string }>({})
  const mixpanel = useMixpanel()
  const { client } = useContext(ApolloClientContext)

  return (
    <Wrapper
      column
      // onPaste={e => {
      //   e.clipboardData.items[0].getAsString(test =>
      //     dispatch({ payload: test, type: Actions.ChangeSearchTerm }),
      //   )
      // }}
    >
      <Menu
        isHomePage={isHomePage}
        onOpenUpload={() => {
          if (!on) {
            toggle()
          }
        }}
      />
      <Content flex={1} fullWidth align="stretch">
        {children}
      </Content>
      <Modal
        onRequestClose={() => {
          off()
        }}
        isOpen={on}
        shouldCloseOnEsc
        shouldCloseOnClickOutside
        blackOverlay
      >
        <Upload
          onClose={() => {
            off()
          }}
          onProcessing={(fileId, fileName) => {
            setFileInfo({ fileId, fileName })
            setProcessing(true)
            off()
          }}
        />
      </Modal>
      {processing && (
        <MeData>
          {({ loading, data: meData }) => {
            if (loading || !meData) {
              return null
            }
            return (
              <CheckFileInfoData
                variables={{ fileId: fileInfo.fileId! }}
                fetchPolicy="network-only"
                pollInterval={500}
              >
                {({ data }) => {
                  if (!meData.me.bulkAccess) {
                    setProcessing(false)
                    Notify.info(
                      'File upload processing is a premium feature. Our team will contact you to upgrade your account and access your processed file',
                    )
                  }

                  const status = data && data.checkFileInfo && data.checkFileInfo.status
                  if (status === FileStatusEnum.Ready) {
                    if (Env.useMixpanel) {
                      mixpanel.track(MixPanelEvent.upload, {
                        correctFormat: true,
                        totalRows: data ? data.checkFileInfo.numLines : 0,
                        totalMatches: data ? data.checkFileInfo.numMatchedLines : 0,
                      })
                    }
                    setTimeout(() => {
                      window.open(data!.checkFileInfo.downloadUrl)
                      setProcessing(false)
                      client.reFetchObservableQueries().then(() => {
                        // Refreshing the file list
                      })
                      // Randomly ask for feedback
                      if (Math.floor(Math.random() * 5)) {
                        Notify.success('File is successfully downloaded!')
                      } else {
                        const notifyElement = Notify.permanentSuccess(
                          <>
                            File is successfully downloaded! Please give us some feedback about the
                            results
                            <EmojiRater
                              onRate={(rating: number) => {
                                Notify.close(notifyElement)
                                addRating(client, {
                                  model: {
                                    userId: meData && meData.me ? meData.me.id : 0,
                                    rating,
                                    type: 'upload',
                                    context: JSON.stringify({ fileId: fileInfo.fileId! }),
                                  },
                                })
                              }}
                            />
                          </>,
                        )
                      }
                    }, 100)
                  }
                  if (status === FileStatusEnum.Failed) {
                    if (Env.useMixpanel) {
                      mixpanel.track(MixPanelEvent.upload, {
                        correctFormat: false,
                      })
                    }
                    Notify.danger(
                      "Something went wrong. Your file has been sent to the support team for reviewing. We'll be in touch!",
                    )
                    setProcessing(false)
                  }
                  return (
                    <UploadBar>
                      <Flex>
                        <StyledUploadIcon src={IconCheck} uploading />
                        <Flex style={{ fontWeight: 'bold', marginRight: 8, marginLeft: 8 }}>
                          {fileInfo.fileName}
                        </Flex>
                        Processing...
                      </Flex>
                      <StyledButton
                        onClick={() => {
                          setProcessing(false)
                          Notify.info(
                            "You can safely close this window and your file will appear in recent files after it's completed",
                          )
                        }}
                      >
                        Close
                      </StyledButton>
                    </UploadBar>
                  )
                }}
              </CheckFileInfoData>
            )
          }}
        </MeData>
      )}
    </Wrapper>
  )
}

export default PageLayout
