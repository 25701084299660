import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import { FontWeight, Colors } from '../../shared/constants'
import useMixpanel from '../../hooks/useMixpanel'
import Env from '../../api/gen/Env'
import { MixPanelEvent } from '../../shared/Mixpanel'
import { SearchItem, SearchQueryInput } from '../../api'
import Tooltip from '../../shared/components/Tooltip'

export const SummaryWrapperItem = styled(Flex)`
  flex: 1 50%;
  flex-direction: column;
  padding: 4px;
`

export const SummaryTitle = styled(Flex)`
  font-size: 0.75vw;
  font-weight: ${FontWeight.regular};
  text-transform: uppercase;
  color: #6c8494;
  margin-bottom: 5px;
  height: 13px;
`

export const ItemDetails = styled(Flex)`
  font-weight: ${FontWeight.bold};
  text-transform: uppercase;
  color: #17232c;
  font-size: 0.85vw;
`

const HyperLink = styled('a')`
  text-decoration: underline;

  &:hover {
    color: ${Colors.primary};
  }
`

type Props = {
  title: string
  value?: number | string | null
  extraValue?: string | null
  link?: string
  data?: SearchItem
  searchQuery?: SearchQueryInput
  tooltip?: string
  children?: React.ReactNode
}

const SummaryItem: React.FC<Props> = ({
  title,
  value,
  extraValue,
  link,
  children,
  data,
  searchQuery,
  tooltip,
}) => {
  const mixpanel = useMixpanel()
  return value ? (
    <SummaryWrapperItem>
      {tooltip ? (
        <SummaryTitle>
          <Tooltip placement="top" trigger="hover" tooltip={tooltip} hideArrow="test">
            <>{title}</>
          </Tooltip>
        </SummaryTitle>
      ) : (
        <SummaryTitle>{title}</SummaryTitle>
      )}
      {value && (
        <>
          {link ? (
            <HyperLink
              href={link}
              target="_blank"
              onClick={() => {
                if (data && Env.useMixpanel) {
                  mixpanel.track(MixPanelEvent.itemSummaryClick, {
                    title: title,
                    supplier: data.supplier.normalized,
                    sku: data.product.number,
                    uom: data.product.uom,
                    searchQuery,
                  })
                }
              }}
            >
              <ItemDetails>{value}</ItemDetails>
            </HyperLink>
          ) : (
            <ItemDetails>{value}</ItemDetails>
          )}
        </>
      )}

      {extraValue && <ItemDetails>{extraValue}</ItemDetails>}
      {children && <ItemDetails>{children}</ItemDetails>}
    </SummaryWrapperItem>
  ) : null
}
export default SummaryItem
