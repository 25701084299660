import { Dropdown, MenuProps } from 'antd'
import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  ApolloClientContext,
  toggleBulkAccess,
  toggleConstructAccess,
  unblockUserFiles,
  User,
} from '../../../api'
import { Flex } from '../../../shared/components/Flex'
import { Modal } from '../../../shared/components/Modal'
import Notify from '../../../shared/components/Notify'
import { Colors } from '../../../shared/constants'
import BillingInfoModal from './BillingInfoModal'
import UserInfoModal from './UserInfoModal'

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr;

  transition: border-color 0.1s ease-in-out;

  &:hover {
    border-color: ${Colors.primary};
  }
`

const Title = styled('span')`
  color: #5e6c84;
  text-transform: lowercase;
  margin-bottom: 4px;
`
const Value = styled('span')`
  font-size: 16px;
  font-weight: 500;
`

const InfoGroup = styled(Flex)`
  flex-direction: column;
  flex: 1 1 16.6%;
`

type Props = {
  user: User
}

enum ModalType {
  'userInfo' = 'userInfo',
  'billingInfo' = 'billingInfo',
}

const UserRow: React.FC<Props> = ({ user }) => {
  const [showModal, setShowModal] = useState({ isOpen: false, type: ModalType.userInfo })
  const { client } = useContext(ApolloClientContext)
  const items = useMemo(() => {
    return [
      {
        key: 'bulkAccess',
        label: user.bulkAccess ? 'Remove Bulk Access' : 'Grant Bulk Access',
      },
      {
        key: 'constructAccess',
        label: user.constructAccess ? 'Remove Construct Access' : 'Grant Construct Access',
        disabled: !user.bulkAccess,
      },
      {
        key: 'billing',
        label: user.billing ? 'Edit Billing' : 'Create Billing',
      },
      {
        key: 'unlockFiles',
        label: 'Unblock User Files',
      },
    ]
  }, [user])

  const handleMenuItemClick: MenuProps['onClick'] = async e => {
    switch (e.key) {
      case 'bulkAccess':
        await toggleBulkAccess(client, { userId: user.id })
        Notify.success('Bulk Access privileges updated!')
        return
      case 'constructAccess':
        if (!user.bulkAccess) {
          Notify.warning('User need to be granted Bulk Access!')
        } else {
          await toggleConstructAccess(client, { userId: user.id })
          Notify.success('Construct Access privileges updated!')
        }
        return
      case 'billing':
        setShowModal({ isOpen: true, type: ModalType.billingInfo })
        return
      case 'unlockFiles':
        await unblockUserFiles(client, { userId: user.id })
        Notify.success('User Files were unblocked.')
        return
    }
  }

  return (
    <>
      <Wrapper>
        <InfoGroup>
          <Title>email</Title>
          <Value>{user.email}</Value>
        </InfoGroup>
        <InfoGroup>
          <Title>name</Title>
          <Value>
            {user.firstName} {user.lastName}
          </Value>
        </InfoGroup>
        <InfoGroup>
          <Title>Expired time</Title>
          <Value>{user.billing ? new Date(user.billing.expireTime).toLocaleString() : 'N/A'}</Value>
        </InfoGroup>
        <InfoGroup>
          <Title>Search Count</Title>
          <Value>{user.billing ? user.billing.searchCount : 'N/A'}</Value>
        </InfoGroup>
        <InfoGroup>
          <Title>Search Limit</Title>
          <Value>{user.billing ? user.billing.searchLimit : 'N/A'}</Value>
        </InfoGroup>
        <Dropdown.Button
          menu={{ items, onClick: handleMenuItemClick }}
          onClick={() => setShowModal({ isOpen: true, type: ModalType.userInfo })}
        >
          Edit Info
        </Dropdown.Button>
      </Wrapper>
      <Modal
        blackOverlay
        isOpen={showModal.isOpen}
        onRequestClose={() => setShowModal({ isOpen: false, type: ModalType.userInfo })}
      >
        {showModal.type === ModalType.userInfo && (
          <UserInfoModal
            close={() => setShowModal({ isOpen: false, type: ModalType.userInfo })}
            user={user}
          />
        )}
        {showModal.type === ModalType.billingInfo && (
          <BillingInfoModal
            userId={user.id}
            billingInfo={user.billing || undefined}
            close={() => setShowModal({ isOpen: false, type: ModalType.userInfo })}
          />
        )}
      </Modal>
    </>
  )
}

export default UserRow
