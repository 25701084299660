import React, { RefObject, useRef } from 'react'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import { ReactComponent as IconRemove } from '../../assets/img/remove-icon.svg'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const Wrapper = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  max-height: 50vh;
  overflow-y: auto;
`

const Item = styled(Flex)`
  border: 1px solid #d8e1e8;
  border-radius: 2px;
  padding: 4px 8px;
  color: #17232c;
  margin-right: 8px;
  margin-bottom: 8px;
  width: max-content;
  font-weight: bold;
  font-size: 16px;
  svg {
    margin-left: 8px;
  }

  &:hover {
    border-color: black;
  }
`

const StyledRemoveItem = styled(IconRemove)`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: rotate(90deg);
  }
`
const SkuLength = styled(Flex)`
  width: 100%;
  font-weight: 500;
  margin-bottom: 16px;
`

type Props = {
  skus: string[]
  onRemoveItem: (item: string) => void
  parentRef: RefObject<HTMLDivElement>
  on: boolean
  off: () => void
}

const MultiSkuDropDown: React.FC<Props> = ({ skus, onRemoveItem, parentRef, on, off }) => {
  const ref = useRef(null)
  useOnClickOutside(ref, e => {
    if (
      e.target &&
      parentRef.current &&
      !parentRef.current.contains(e.target as HTMLElement) &&
      on
    ) {
      off()
    }
  })
  return (
    <Wrapper ref={ref}>
      <SkuLength>{skus.length} sku(s)</SkuLength>
      {skus.map((item, index) => (
        <Item key={`${item}-${index}`} align="center">
          {item}
          <StyledRemoveItem style={{ cursor: 'pointer' }} onClick={() => onRemoveItem(item)} />
        </Item>
      ))}
    </Wrapper>
  )
}

export default MultiSkuDropDown
