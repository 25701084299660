import { css } from 'styled-components'

export const addUnit = (value: string | number) => {
  if (typeof value === 'number') {
    return `${value}px`
  }
  return value
}

export type MarginsProps = {
  margin?: number | string
  marginTop?: number | string
  marginRight?: number | string
  marginBottom?: number | string
  marginLeft?: number | string
  marginVertical?: number | string
  marginHorizontal?: number | string
}

export type PaddingsProps = {
  padding?: number | string
  paddingTop?: number | string
  paddingRight?: number | string
  paddingBottom?: number | string
  paddingLeft?: number | string
  paddingVertical?: number | string
  paddingHorizontal?: number | string
}

export const getMargin = (
  props: MarginsProps,
  {
    top = 0,
    left = 0,
    bottom = 0,
    right = 0,
  }: {
    top?: string | number
    left?: string | number
    right?: string | number
    bottom?: string | number
  } = {},
) =>
  css`
    margin: ${(props.margin && addUnit(props.margin)) ||
    `
        ${addUnit(props.marginTop || props.marginVertical || top)}
        ${addUnit(props.marginRight || props.marginHorizontal || right)}
        ${addUnit(props.marginBottom || props.marginVertical || bottom)}
        ${addUnit(props.marginLeft || props.marginHorizontal || left)}
      `};
  `

export const getPadding = (props: PaddingsProps) => {
  let paddings = ''

  // Note: Css order matters here.
  // For example, PaddingTop > PaddingVertical > Padding
  if (props.padding) {
    paddings += `padding: ${addUnit(props.padding)};`
  }
  if (props.paddingHorizontal) {
    paddings += `
      padding-right: ${addUnit(props.paddingHorizontal)};
      padding-left: ${addUnit(props.paddingHorizontal)}
    `
  }
  if (props.paddingVertical) {
    paddings += `
      padding-top: ${addUnit(props.paddingVertical)};
      padding-bottom  : ${addUnit(props.paddingVertical)}
    `
  }
  if (props.paddingTop) {
    paddings += `padding-top: ${addUnit(props.paddingTop)};`
  }
  if (props.paddingRight) {
    paddings += `padding-right: ${addUnit(props.paddingRight)};`
  }
  if (props.paddingBottom) {
    paddings += `padding-bottom: ${addUnit(props.paddingBottom)};`
  }
  if (props.paddingLeft) {
    paddings += `padding-left: ${addUnit(props.paddingLeft)};`
  }

  return css`
    ${paddings}
  `
}

export const getMarginPadding = (props: MarginsProps & PaddingsProps) => css`
  ${getMargin(props)};
  ${getPadding(props)};
`

export type BorderRadius = {
  borderRadius?: number | string
}
