import React from 'react'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import logoIcon from '../../assets/img/logo-icon.svg'
import { Flex } from '../../shared/components/Flex'
import { Colors } from '../../shared/constants'
import AdminSidebar from './AdminSidebar'

const Wrapper = styled(Flex)`
  min-height: 100vh;
`

const Header = styled(Flex)`
  height: 100px;
  align-items: center;
  padding: 0 32px;
  box-shadow: 0 2px 8px #f0f1f2;
`
const StyledSidebar = styled(Flex)`
  border-right: 1px solid #e8e8e8;
`
const MainWrapper = styled(Flex)`
  padding-top: 40px;
`
const Left = styled(Flex)`
  font-size: 23px;
  font-weight: 500;
  color: ${Colors.primary};
  align-items: center;
`
const StyledLink = styled(Link)`
  border: 1px solid ${Colors.primary};
  color: ${Colors.primary};
  padding: 6px 8px;
  border-radius: 3px;

  transition: all 0.1s ease-in-out;

  &:hover {
    background: ${Colors.primary};
    color: #fff;
  }
`

const Logo = styled('div')`
  cursor: pointer;
  margin-right: 8px;

  img {
    vertical-align: middle;
  }
`

type Props = { children?: React.ReactNode } & RouteComponentProps

const AdminLayout: React.FC<Props> = ({ children, history }) => (
  <Wrapper column>
    <Header fullWidth justify="space-between">
      <Left>
        <Logo onClick={() => history.push('/')}>
          <img src={logoIcon} height="80" alt="Lookup" />
        </Logo>{' '}
        | ADMIN
      </Left>
      <Flex>
        <StyledLink to="/">Go to App</StyledLink>
      </Flex>
    </Header>
    <MainWrapper fullWidth flex={1} align="stretch">
      <StyledSidebar flex="0 0 16%">
        <AdminSidebar />
      </StyledSidebar>
      <Flex flex={1}>{children}</Flex>
    </MainWrapper>
  </Wrapper>
)

export default withRouter(AdminLayout)
