import React, { ErrorInfo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ApolloClientContext } from './api'
import { Button } from './shared/components/Button'
import { Flex } from './shared/components/Flex'
import Notify from './shared/components/Notify'
import { Colors } from './shared/constants'
import { ReactComponent as EmailIcon } from './routes/Auth/assets/email.svg'

const Wrapper = styled(Flex)`
  height: 100vh;
`

const ErrorText = styled(Flex)`
  font-size: 22px;
  margin-bottom: 30px;
`

const Oops = styled(Flex)`
  font-family: 'Montserrat', sans-serif;
  color: #667077;
  position: relative;
  h1 {
    font-size: 236px;
    font-weight: 200;
    text-transform: uppercase;
    margin: 0px;
  }

  h2 {
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: max-content;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    text-align: center;
  }
`

const ContactBtn = styled('button')`
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 22px;

  &:hover {
    color: ${Colors.primary};
  }
`

type Props = { children?: React.ReactNode } & RouteComponentProps
type State = Partial<{
  error: Error
  errorInfo: ErrorInfo
}>

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      error: undefined,
      errorInfo: undefined,
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      error,
      errorInfo: info,
    })
  }

  isBillingError = (error: any): boolean =>
    error.data.name === 'billing_limit' ||
    error.data.name === 'billing_expired' ||
    error.data.name === 'billing_and_limit'

  handleContactUs = async (type: 'billing_limit' | 'billing_expired' | 'billing_and_limit') => {
    const { history } = this.props
    history.push('/')
    if (type === 'billing_limit' || type === 'billing_and_limit' || type === 'billing_expired') {
      Notify.success(
        'Your subscription has expired. Please contact support at lookup@curvolabs.com to update your billing.',
      )
    }
    this.setState({ error: undefined, errorInfo: undefined })
    setTimeout(() => {
      window.location.href = 'mailto:lookup@curvolabs.com'
    }, 3000)
  }

  handleError = (error: any) => {
    if (error.data) {
      // ApiError

      if (this.isBillingError(error)) {
        return (
          <ErrorText>
            {error.message}. Please &nbsp;
            <ContactBtn onClick={() => this.handleContactUs(error.data.name)}>
              contact us
            </ContactBtn>{' '}
            &nbsp; to update your billing.
          </ErrorText>
        )
      }
    }

    return null
  }

  render() {
    const { history, children } = this.props
    return (
      <ApolloClientContext.Consumer>
        {() => {
          const { error, errorInfo } = this.state
          if (errorInfo && error) {
            return (
              <Wrapper fullWidth column justify="center" align="center" alignSelf="stretch">
                {this.isBillingError(error) ? (
                  <EmailIcon style={{ marginBottom: '32px', width: '300px' }} />
                ) : (
                  <Oops>
                    <h1>Oops!</h1>
                    <h2>Something went wrong</h2>
                  </Oops>
                )}
                {this.handleError(error)}
                <Button
                  style={{ textTransform: 'uppercase' }}
                  onClick={() => {
                    this.setState({ error: undefined, errorInfo: undefined })
                    history.push('/')
                  }}
                >
                  Go to homepage
                </Button>
              </Wrapper>
            )
          }
          return children
        }}
      </ApolloClientContext.Consumer>
    )
  }
}

export default withRouter(ErrorBoundary)
