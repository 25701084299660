import React, { useState, useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { useDropzone } from 'react-dropzone'
import Axios from 'axios'
import { Flex } from '../Flex'
import closeIcon from '../../../assets/img/remove-icon.svg'
import { ReactComponent as UploadIcon } from '../../../assets/img/upload-icon-big.svg'
import Notify from '../Notify'
import {
  genUploadUrlMutation,
  checkHeadersMutation,
  searchByFileMutation,
} from '../../../api/components/File'
import { ApolloClientContext, AlternativeHeadersInput, ME_QUERY } from '../../../api'
import { MessageTypes } from '../../../routes/Auth/Login'
import { Colors } from '../../constants'
import IconCheck from '../../../assets/img/uploading.svg'
import { ColumnMapping } from './ColumnMapping'
import { StyledUploadIcon } from './common'

const Wrapper = styled.div`
  width: 70vw;
  min-height: 60vh;
  max-height: 90vh;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`

const Content = styled(Flex)`
  width: fit-content;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  flex: 1;
`

const UploadWrapper = styled(Flex)<{ isDragActive?: boolean; uploading?: boolean }>`
  border: 2px dashed #ccc;
  border-color: ${props => (props.isDragActive ? Colors.success : '#ccc')};
  border-radius: 12px;
  padding: 1px;
  min-width: 50vw;
  min-height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const UploadingWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
`

const TopText = styled('h2')`
  margin: 0 auto;
  margin-top: 24px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`

const BottomText = styled('h4')`
  margin: 0 auto;
  width: 350px;
  padding-bottom: 32px;
  text-align: center;
  font-weight: 14px;
`

const UploadLink = styled('a')`
  color: #f86b4f;
`

const CloseBtn = styled(Flex)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e7ed;
  border-radius: 2px;
  &:hover {
    border-color: ${darken(0.1, '#E0E7ED')};
  }
  position: absolute;
  right: 20px;
  top: 20px;
`

const Steps = styled(Flex)`
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
`

const StepNo = styled(Flex)<{ done?: boolean }>`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => (props.done ? '#3C9259' : '#92abbb')};
  font-size: 12px;
  font-weight: bold;
  color: #fff;

  margin-right: 16px;
`

const Row = styled(Flex)`
  width: 148px;
`

const DescriptionText = styled.div`
  padding: 50px 50px 0px 50px;
  text-align: center;
`

const MessageBox = styled(Flex)<{ type: MessageTypes }>`
  margin-top: 30px;
  padding: 8px 12px;
  border-radius: 3px;
  ${props => {
    switch (props.type) {
      case 'error':
        return css`
          background: ${Colors.danger};
          color: #fff;
        `
      default:
        return css``
    }
  }}
`

const getContentTypeFromName = (filename: string): string =>
  filename.endsWith('xlsx')
    ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    : 'text/csv'

type Props = {
  onClose: () => void
  onProcessing: (fileId: number, fileName: string) => void
}

const UploadModal: React.FC<Props> = ({ onClose, onProcessing }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const { client } = useContext(ApolloClientContext)
  const [fileName, setFileName] = useState<string>()
  const [uploadedFileId, setFileId] = useState<number>()
  const [uploadMessage, setUploadMessage] = useState<{ type: MessageTypes; text: string }>()
  type UploadDetails = {
    uploadUrl: string
    fileId: number
  }
  const [fileHeaders, setFileHeaders] = useState<string[]>([])
  const [uploading, setIsUploading] = useState(false)
  const [fileSampleData, setFileSampleData] = useState<any[]>([])

  const getUploadDetails = useCallback(
    async (file: any): Promise<UploadDetails> => {
      const response = await genUploadUrlMutation(client, {
        data: {
          name: file.name,
          mimetype: file.type || 'text/csv',
        },
      })
      if (!response.data) {
        return { uploadUrl: '', fileId: 0 }
      }
      const { uploadUrl, fileId } = response.data.generateUploadInfo
      return { uploadUrl, fileId }
    },
    [client],
  )
  const onProcess = useCallback(
    (fileId: number, mapping?: AlternativeHeadersInput, enableConstructAnalysis?: boolean) => {
      fileName && onProcessing(fileId, fileName)
      searchByFileMutation(
        client,
        {
          input: { fileId, alternativeHeaders: mapping, enableConstructAnalysis },
        },
        {
          refetchQueries: [{ query: ME_QUERY }],
        },
      )
      setCurrentStep(0)
    },
    [client, fileName, onProcessing],
  )

  const loadFileStats = useCallback(
    async (fileId: number) => {
      setFileId(fileId)
      try {
        const { data } = await checkHeadersMutation(client, { fileId })
        const headers = ((data && data.checkHeaders && data.checkHeaders.headers) || []).map(
          h => `${h}`,
        )
        const samples = (data && data.checkHeaders && JSON.parse(data.checkHeaders.sample)) || []
        if (!headers.length) {
          onProcess(fileId)
        } else {
          setFileHeaders(headers)
          setFileSampleData(samples)
          setCurrentStep(1)
        }
      } catch (e) {
        Notify.danger(e.message.replace('GraphQL error: ', ''))
      }
      setIsUploading(false)
    },
    [client, onProcess],
  )

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      const [file] = acceptedFiles
      const { name, type } = file
      if (!acceptedFiles.length) {
        setUploadMessage({
          type: 'error',
          text: 'Only .csv and .xlsx files are accepted',
        })
        return
      }
      setFileName(name)
      const { uploadUrl, fileId } = await getUploadDetails(file)
      if (!uploadUrl) {
        return
      }
      setIsUploading(true)

      try {
        await Axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': type || getContentTypeFromName(name),
          },
        })
      } catch (err) {
        if (err) {
          const message = err.message
          // catch GraphQL errors and strip
          const colonIndex = message.startsWith('GraphQL') ? message.indexOf(':') + 1 : 0
          Notify.danger(message.slice(colonIndex))
          setCurrentStep(0)
          setIsUploading(false)
        }
        setTimeout(() => {
          setFileName('')
          // setProgress(0)
        }, 500)
      }
      await loadFileStats(fileId)
    },
    [getUploadDetails, loadFileStats],
  )

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'text/x-csv': ['.csv'],
      'application/x-csv': ['.csv'],
      'text/comma-separated-values': ['.csv'],
      'text/x-comma-separated-values': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
    },
    // ', ' at the end allows accepting empty type string: it's a workaround for windows/chrome machines' failing to recognize some csv/xls file types
  })

  return (
    <Wrapper>
      <CloseBtn fullWidth onClick={onClose}>
        <img src={closeIcon} alt="Close" />
      </CloseBtn>
      <Steps fullWidth>
        <Row>
          <StepNo done>1</StepNo>
          <div>Upload</div>
        </Row>
        <Row>
          <StepNo done={currentStep >= 1}>2</StepNo>
          <div>Map fields</div>
        </Row>
        <hr />
        <Row>
          <StepNo done={currentStep === 2}>3</StepNo>
          <div>Download</div>
        </Row>
      </Steps>
      <DescriptionText>
        Please ensure there is only <b>one</b> sheet in the excel book, <b>no</b> hidden sheets, and{' '}
        <b>no</b> additional details <b>above</b> the row headers like images, comments, or contract
        details. If your file fails to process, please contact Lookup Support and we will
        troubleshoot your file.
      </DescriptionText>
      <Content>
        {currentStep === 0 && !uploading && (
          <UploadWrapper {...getRootProps()} isDragActive={isDragActive}>
            <UploadIcon />
            <input {...getInputProps()} />
            <TopText>Drag and drop to Upload Price File</TopText>
            <BottomText>
              {' '}
              or{' '}
              <UploadLink>
                <label style={{ cursor: 'pointer' }}>Browse </label>
              </UploadLink>
              to choose file
            </BottomText>
          </UploadWrapper>
        )}
        {currentStep === 0 && uploading && (
          <UploadingWrapper>
            <StyledUploadIcon src={IconCheck} uploading />
            <span>Uploading...</span>
          </UploadingWrapper>
        )}
        {currentStep === 1 && (
          <ColumnMapping
            headers={fileHeaders}
            samples={fileSampleData}
            onProcess={(mapping, enableConstructAnalyzer) =>
              uploadedFileId && onProcess(uploadedFileId, mapping, enableConstructAnalyzer)
            }
          />
        )}
        {uploadMessage && <MessageBox type={uploadMessage.type}>{uploadMessage.text}</MessageBox>}
      </Content>
    </Wrapper>
  )
}

export default UploadModal
