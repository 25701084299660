import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import logoIcon from '../../assets/img/logo-icon.svg'
import { Flex } from '../../shared/components/Flex'
import PageLayout from '../../shared/components/PageLayout'
import { SearchTypeEnum } from '../../api'
import AdvanceSearch from './AdvanceSearch'
import RecentFiles from './RecentFiles'
import RecentSearch from './RecentSearch'
import SearchGroup from './SearchGroup'
import SearchModeTab from './SearchModeTab'

const Wrapper = styled(Flex)`
  width: 70vw;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: 80vw;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
`
const Logo = styled(Flex)``

type Props = {} & RouteComponentProps<{}, {}, { searchMode: SearchTypeEnum }>

const Home: React.FC<Props> = ({ location }) => {
  const [searchType, setSearchType] = useState<SearchTypeEnum>(
    location.state ? location.state.searchMode : SearchTypeEnum.Part,
  )
  return (
    <PageLayout isHomePage>
      <Wrapper column align="center">
        <Logo marginTop="3rem">
          <img src={logoIcon} alt="Lookup" width="150" />
        </Logo>
        <Flex fullWidth marginTop="2rem" marginBottom="0.75rem">
          <SearchModeTab searchType={searchType} setSearchType={setSearchType} />
        </Flex>
        {searchType !== SearchTypeEnum.Advanced && (
          <>
            <SearchGroup searchType={searchType} isHomePage />
            <Flex fullWidth marginTop="3rem">
              <RecentSearch />
            </Flex>
            <Flex fullWidth marginTop="3rem">
              <RecentFiles />
            </Flex>
          </>
        )}
        {searchType === SearchTypeEnum.Advanced && <AdvanceSearch />}
      </Wrapper>
    </PageLayout>
  )
}

export default withRouter(Home)
