import { FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import TextInput from '../../shared/components/Inputs/TextInput'
import { Button } from '../../shared/components/Button'
import LoadingIndicator from '../../shared/components/Spinner'
import { ChangePasswordValues } from './UserProfile'

const StyledForm = styled.form`
  width: 100%;
`

type ChangePasswordFormProps = { onCancel: () => void } & FormikProps<ChangePasswordValues>
const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onCancel,
  handleSubmit,
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  isSubmitting,
}) => (
  <StyledForm onSubmit={handleSubmit}>
    <TextInput
      inputProps={{
        placeholder: 'Current Password',
        type: 'password',
        name: 'currentPassword',
        value: values.currentPassword,
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      error={(touched.currentPassword && errors.currentPassword) || undefined}
    />
    <Flex marginBottom={24} />
    <TextInput
      inputProps={{
        placeholder: 'New Password',
        type: 'password',
        name: 'newPassword',
        autoComplete: 'off',
        value: values.newPassword,
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      error={(touched.newPassword && errors.newPassword) || undefined}
    />
    <Flex marginBottom={24} />
    <TextInput
      inputProps={{
        placeholder: 'Comfirm New Password',
        type: 'password',
        name: 'confirmNewPassword',
        autoComplete: 'off',
        value: values.confirmNewPassword,
        onChange: handleChange,
        onBlur: handleBlur,
      }}
      error={(touched.confirmNewPassword && errors.confirmNewPassword) || undefined}
    />
    <Flex marginTop={24} align="center">
      <Button style={{ width: 140 }} type="submit">
        {isSubmitting ? <LoadingIndicator /> : 'Save'}
      </Button>
      <Button btnType="secondary" marginLeft={60} onClick={onCancel}>
        Cancel
      </Button>
    </Flex>
  </StyledForm>
)

export { ChangePasswordForm }
