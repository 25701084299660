import { useState } from 'react'

const useToggle = (intialOn = false) => {
  const [on, setOn] = useState(intialOn)
  const toggle = () => setOn(!on)

  const off = () => setOn(false)

  return { toggle, on, off }
}

export { useToggle }
