import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Flex } from '../../shared/components/Flex'
import Notify from '../../shared/components/Notify'
import { Colors, FontWeight } from '../../shared/constants'
import { useToggle } from '../../hooks/useToggle'
import MultiSkuDropDown from './MultiSkuDropdown'

const Wrapper = styled(Flex)`
  flex: 1;
  background: #f2f4f7;
  align-items: center;
  border-bottom: 3px solid transparent;
  min-height: 51px;
  padding: 8px 16px;
  box-sizing: border-box;
  position: relative;
  padding-right: 80px;
  width: 100%;
`

const TagInputText = styled('input')`
  flex: 1;
  min-width: 100px;
  background: transparent;
  padding: 0px 12px;
  padding-left: 0;
  width: 100%;
  outline: none;
  border: none;
  align-self: stretch;

  ::placeholder {
    color: #c9d4dd;
    font-weight: ${FontWeight.bold};
  }
`
const ClearAllBtn = styled(Flex)`
  position: absolute;
  right: 5px;
  border: 1px solid #000;
  align-self: center;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 8px;

  &:hover {
    border-color: ${Colors.primary};
    color: ${Colors.primary};
  }
`
const ShowAllBtn = styled(ClearAllBtn)`
  right: 100px;
  font-weight: 600px;
`

type Props = {
  onFocusInput: () => void
  onChange: (value: string) => void
  value: string
  onSubmit: () => void
}

const TagInput: React.FC<Props> = ({ value, onFocusInput, onChange, onSubmit }) => {
  const [values, setValues] = useState<string[]>([])
  const [textInput, setTextInput] = useState('')
  const { on, off, toggle } = useToggle(false)
  const parentRef = useRef(null)

  // parse value into items
  useEffect(() => {
    if (value) {
      setValues(value.split(' '))
    }
  }, [value])

  const handleRemoveItem = (item: string) => {
    const removed = values.filter(v => v !== item).join(' ')
    onChange(removed)
    if (!removed) {
      setValues([])
    }
  }
  const handleRemoveAll = () => {
    onChange('')
    setValues([])
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      case 32: // space
        if (textInput.length >= 3) {
          onChange([...values, textInput].join(' '))
          setTextInput('')
        } else {
          Notify.warning('At least 3 characters required!')
        }
        e.preventDefault()
        break
      case 8: // backspace
        if (textInput.length || !values.length) {
          return
        }
        handleRemoveItem(values[values.length - 1])
        break
      case 13: // enter
        onSubmit()
        break
      default:
    }
  }

  const handleInputChange = (text: string) => {
    const trimmed = text.trim()
    if (trimmed.trim().includes(' ')) {
      onChange([...values, ...trimmed.split(' ')].join(' '))
      setTextInput(' ')
    } else {
      setTextInput(trimmed.trim())
    }
  }

  return (
    <Wrapper ref={parentRef}>
      <TagInputText
        onFocus={() => {
          onFocusInput()
          if (!on) {
            toggle()
          }
        }}
        autoFocus
        value={textInput}
        onChange={e => handleInputChange(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Enter or paste SKUs splitted by 'space'"
      />
      {values.length > 0 && (
        <>
          {!on && (
            <ShowAllBtn
              onClick={() => {
                if (!on) {
                  toggle()
                }
              }}
            >
              Show {values.length} sku(s)
            </ShowAllBtn>
          )}
          <ClearAllBtn onClick={handleRemoveAll}>Clear All</ClearAllBtn>
        </>
      )}
      {values.length > 0 && on && (
        <MultiSkuDropDown
          skus={values}
          onRemoveItem={handleRemoveItem}
          on={on}
          off={off}
          parentRef={parentRef}
        />
      )}
    </Wrapper>
  )
}

export default TagInput
