import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import LoadingIndicator from '../../shared/components/Spinner'
import { Gudid } from '../../api'
import { convertDateTime } from '../../shared/ultis'
import { Colors } from '../../shared/constants'
import { Flex } from '../../shared/components/Flex'
import { FetchFdaData } from '../../api/components/Search'
import SummaryItem from './SummaryItem'

const LoadingWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#f2f4f7', 0.5)};
  justify-content: center;
  align-items: center;
`

type Props = {
  gudid: Gudid | null | undefined
}

const FdaDetails: React.FC<Props> = ({ gudid }) => {
  const input = {
    catalogNumber: gudid ? gudid.catalogNumber : null,
    submissionNumbers: gudid ? gudid.submissionNumbers : null,
    versionModelNumber: gudid ? gudid.versionModelNumber : null,
    companyName: gudid ? gudid.companyName : null,
  }
  return (
    <FetchFdaData variables={{ input }}>
      {({ data, loading }) => {
        return (
          <>
            {loading && (
              <LoadingWrapper>
                <LoadingIndicator color={Colors.primary} />
              </LoadingWrapper>
            )}

            {data && data.fetchFdaData && (
              <>
                <SummaryItem
                  title="First Approval Date"
                  value={
                    data.fetchFdaData.approvalDate &&
                    convertDateTime(data.fetchFdaData.approvalDate)
                  }
                  tooltip="The date the device was first approved by the FDA"
                />
                <SummaryItem
                  title="Approval Route"
                  value={data.fetchFdaData.approvalRoute}
                  tooltip="Whether a device was approved via a PMA, 510k or both"
                />
                <SummaryItem
                  title="Recall Number"
                  value={data.fetchFdaData.recallNumber}
                  extraValue={data.fetchFdaData.productQuantity}
                  tooltip="Unique identification number for any FDA recall of the device and the number of units involved in the recall"
                />
                <SummaryItem
                  title="Adverse Events"
                  value={data.fetchFdaData.adverseEventCount}
                  tooltip="The number of reported events for this device in the FDA MAUDE database"
                />
              </>
            )}
          </>
        )
      }}
    </FetchFdaData>
  )
}

export default FdaDetails
