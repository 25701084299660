import React, { useContext, useEffect } from 'react'
import { RouteProps, RouteComponentProps, Route, withRouter } from 'react-router-dom'
import { CognitoContext } from '../../api'
import ErrorBoundary from '../../ErrorBoundary'
import { WithRouterTracking } from '../../shared/components/Tracking'

type Props = RouteProps & RouteComponentProps

const AuthorizedRoute: React.FC<Props> = ({ component, history, ...restProps }) => {
  const { isAuth } = useContext(CognitoContext)

  useEffect(() => {
    if (!isAuth) {
      history.push('/auth')
    }
  }, [history, isAuth])
  return isAuth ? (
    <ErrorBoundary>
      <WithRouterTracking />
      <Route {...restProps} component={component} />
    </ErrorBoundary>
  ) : null
}

export default withRouter(AuthorizedRoute)
