import styled, { css } from 'styled-components'
import { Flex } from '../../../shared/components/Flex'

export const NormalText = styled(Flex)<{ center?: boolean; size?: string | number }>`
  ${props =>
    props.center &&
    css`
      justify-content: center;
      text-align: center;
    `}
  ${props =>
    props.size &&
    css`
      font-size: ${typeof props.size === 'string' ? props.size : `${props.size}px`};
    `}
  color: #6c8494;
`
