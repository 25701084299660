import React, { useState } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Flex } from '../../../shared/components/Flex'
import { AllUsersData } from '../../../api'
import LoadingIndicator from '../../../shared/components/Spinner'
import { Colors } from '../../../shared/constants'
import Pagination from '../../../shared/components/Pagination'
import TextInput from '../../../shared/components/Inputs/TextInput'
import { useDebounce } from '../../../hooks/useDebounce'
import UserRow from './UserRow'

type Props = {}

const UserList = styled(Flex)`
  padding: 32px;
  padding-top: 0;
`
const MaskedLoading = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba('#fff', 0.5)};
  justify-content: center;
  align-items: center;
`

const NUMBER_PER_PAGE = 10

const UserAdmin: React.FC<Props> = () => {
  const [offset, setOffset] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  return (
    <UserList column fullWidth>
      <TextInput
        inputProps={{
          placeholder: 'Type to earch user by email or name...',
          value: searchTerm,
          onChange: e => setSearchTerm(e.target.value),
        }}
        style={{ marginBottom: 16 }}
      />
      <AllUsersData
        variables={{
          query: {
            searchTerm: debouncedSearchTerm,
            pagination: { offset, limit: NUMBER_PER_PAGE },
          },
        }}
      >
        {({ data, loading }) => {
          if (!data) {
            return <span>No Data</span>
          }
          return (
            <Flex fullWidth column style={{ position: 'relative' }}>
              {loading && (
                <MaskedLoading>
                  <LoadingIndicator color={Colors.primary} />
                </MaskedLoading>
              )}
              {data.getAllUsers &&
                data.getAllUsers.users.map(user => {
                  if (!user) {
                    return null
                  }
                  return <UserRow key={user.id} user={user} />
                })}
              {data.getAllUsers && (
                <Flex fullWidth column align="center" justify="center">
                  <Pagination
                    total={Math.ceil(data.getAllUsers.info.total / NUMBER_PER_PAGE)}
                    page={offset / NUMBER_PER_PAGE + 1}
                    onGoTo={page => setOffset((page - 1) * NUMBER_PER_PAGE)}
                  />
                  <span style={{ color: '#8D99A6', opacity: 0.5 }}>
                    {data.getAllUsers.info.total} results
                  </span>
                </Flex>
              )}
            </Flex>
          )
        }}
      </AllUsersData>
    </UserList>
  )
}

export default UserAdmin
