import React from 'react'
import Select from 'react-select'
import { Props } from 'react-select/src/Select'
import { MappingsData } from '../../../api/components/File'
import { Mapping } from '../../../api'

class TypedSelect extends Select<MappingsSelectorOptionType> {}

export const MappingsSelector: React.FC<Props<MappingsSelectorOptionType>> = props => {
  return (
    <MappingsData>
      {({ data, loading }) => {
        const mappingsOpt: MappingsSelectorOptionType[] = ((data && data.mappings) || []).map(
          mapping => ({
            label: mapping.name,
            value: mapping,
          }),
        )
        return <TypedSelect options={mappingsOpt} isLoading={loading} {...props} />
      }}
    </MappingsData>
  )
}

export type MappingsSelectorOptionType = { label: string; value: Mapping }
